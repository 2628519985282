export enum IdentifierType {
  EMAIL = "EMAIL",
  SMS = "SMS",
}

export interface IdentifierResponse {
  authInfo: Identifier[];
  contactsInfo: Identifier[];
  pendingConfirmation: PendingConfirmationIdentifier[];
  balance: number;
}

export interface Identifier {
  login: string;
  type: IdentifierType;
  confirmed: boolean;
  id: string;
}

export interface PendingConfirmationIdentifier extends Identifier {
  confirmationExpiresAt: string;
}

export interface DetachedEmail {
  identifierId: string;
  identifierLogin: string;
  identifierType: string;
}

export interface AttachedEmail {
  email: string;
  receiptExpirationDate: string | null;
  extraMeta: string | null;
}

export interface ProfileState {
  emails: Identifier[];
  phones: Identifier[];
  counts: ICounts;
  isConfirmEmailDialog: boolean;
  loading: boolean;
  attachPending: boolean;
  isAddEmailDialog: boolean;
  isDetachEmailDialogOpen: boolean;
}

export interface ICounts {
  unreadLotteryWinCount?: number;
  taxDeductionSum?: number;
  balance?: number;
  unreadNotification?: number;
  isBlocked?: boolean;
}
