import { combine, forward, guard, merge, sample } from "effector";
import {
  authPhone$,
  authPhoneSelected,
  backToPhoneForm,
  challengeTokenExpired,
  challengeTokenTime$,
  codeSent,
  loadSessionUserFx,
  loadSmsChallengeTokenDataFx,
  loginError$,
  loginFx,
  loginRedirectFromPrevPath$,
  logout,
  logoutFx,
  sessionUser$,
  showCodeForm$,
  smsChallengeToken$,
  smsChallengeTokenError$,
  tickChallengeTokenTime,
  userIsSlowpoke$,
  clientFromPartner,
  skipEmail$,
  authCapcha$,
  authCapchaSelected,
} from "./index";
import {
  fetchAuthInfo,
  fetchSessionUser,
  fetchSmsChallengeTokenData,
} from "../../api";
import { TokenStorage } from "../../core/tokenStorage";
import { deviceInfo } from "../../core/appMetaInfo";
import { updateCounts } from "models/settings/profile";

loadSmsChallengeTokenDataFx.use(fetchSmsChallengeTokenData);
loadSessionUserFx.use(fetchSessionUser);
loginFx.use(fetchAuthInfo);
logoutFx.use(TokenStorage.removeTokens);

const authPhoneSelectedHasToken = guard({
  source: authPhoneSelected,
  filter: challengeTokenTime$.map((time) => !!time),
});

const authPhoneSelectedNoToken = guard({
  source: authPhoneSelected,
  filter: challengeTokenTime$.map((time) => !time),
});

showCodeForm$
  .on(
    merge([loadSmsChallengeTokenDataFx.done, authPhoneSelectedHasToken]),
    (state, data) => {
      // @ts-ignore
      document.getElementById("code").value = data?.result?.code;

      return true;
    }
  )
  .on(loadSmsChallengeTokenDataFx.fail, (state, data) => {
    if (data.error.code === "registration.sms.verification.not.expired")
      return true;
  })
  .reset(backToPhoneForm, logoutFx.finally);

smsChallengeToken$
  .on(
    loadSmsChallengeTokenDataFx.doneData,
    (state, { challengeToken }) => challengeToken
  )
  .reset(loginFx.done);

challengeTokenTime$
  .on(
    loadSmsChallengeTokenDataFx.doneData,
    (state, { challengeTokenExpiresInSec }) => challengeTokenExpiresInSec / 1000
  )
  .on(loadSmsChallengeTokenDataFx.fail, (state, data) => {
    if (data.error.code === "registration.sms.verification.not.expired")
      return 100;
  })
  .on(tickChallengeTokenTime, (state) => (state ? state - 1 : null))
  .reset(loginFx.done, authPhone$);

sessionUser$
  .on(loadSessionUserFx.doneData, (state, data) => data)
  .on(loginFx.doneData, (state, { profile }) => profile)
  .reset(logoutFx.finally);

authPhone$
  .on(authPhoneSelected, (state, payload) => payload)
  .reset(loginFx.done);
authCapcha$
  .on(authCapchaSelected, (state, payload) => payload)
  .reset(loginFx.done);

smsChallengeTokenError$
  .on(loadSmsChallengeTokenDataFx.fail, (state, data) => {
    if (data.error.status === 504) {
      return "Сервис временно недоступен. Повторите попытку позже.";
    }
    if (data.error.code === "registration.sms.verification.not.expired") {
      return null;
    }
    return data.error.message;
  })
  .reset(loadSmsChallengeTokenDataFx.done, loginFx.done);

userIsSlowpoke$
  .on(challengeTokenExpired, () => true)
  .reset(loadSmsChallengeTokenDataFx.done, backToPhoneForm);

loginError$
  .on(loginFx.failData, (state, { message }) => message)
  .reset(loginFx.done, backToPhoneForm, loadSmsChallengeTokenDataFx.done);

skipEmail$.on(clientFromPartner, () => {
  sessionStorage.setItem("skipEmail", "true");
  return true;
});

declare global {
  interface Window {
    LOGOUT: {
      postMessage: Function;
    };
  }
}

loginRedirectFromPrevPath$.on(logout, (state, payload) => payload);

logout.watch(() => {
  if (window.LOGOUT) {
    window.LOGOUT.postMessage("Logout action");
  }
});

sample({
  clock: authPhoneSelectedNoToken,
  source: combine({
    phone: authPhone$,
    captchaToken: authCapcha$,
  }),
  fn: ({ phone, captchaToken }) => ({
    phone,
    captchaToken,
    deviceInfo,
  }),
  // @ts-ignore
  target: loadSmsChallengeTokenDataFx,
});

let timer: number;
loadSmsChallengeTokenDataFx.fail.watch(({ error }) => {
  if (error.code === "registration.sms.verification.not.expired") {
    tickChallengeTokenTime();
  }
});

sample({
  clock: [loadSmsChallengeTokenDataFx.done, tickChallengeTokenTime],
  source: challengeTokenTime$,
}).watch((leftTime) => {
  if (leftTime) {
    timer = setTimeout(tickChallengeTokenTime, 1000);
  } else {
    challengeTokenExpired();
    window.ym(85973717, "reachGoal", "send_code_withoutsms");
  }
});
authPhone$.watch(() => clearTimeout(timer));

sample({
  clock: codeSent,
  source: combine({
    phone: authPhone$,
    challengeToken: smsChallengeToken$,
  }),
  fn: ({ challengeToken, phone }, { code, saveCredentials }) => ({
    code,
    phone: phone || "",
    challengeToken: challengeToken || "",
    saveCredentials,
  }),
  // @ts-ignore
  target: loginFx,
});

forward({
  from: loginFx.doneData,
  to: updateCounts,
});

forward({
  from: logout,
  to: logoutFx,
});
