import { sample } from "effector";
import { fetchReceipts } from "../../api";
import { ReceiptSorting } from "../../types/receipt";
import {
  fetchLastReceiptFx,
  HeaderGate,
  lastReceiptData$,
  showNewReceipts,
} from "./index";
import { fetchReceiptsFx } from "../receipt";

fetchLastReceiptFx.use(
  fetchReceipts.bind(null, {
    limit: 1,
    offset: 0,
    dateFrom: null,
    dateTo: null,
    orderBy: ReceiptSorting.CREATED_DATE_DESC,
    inn: null,
    kktOwner: null,
  })
);

// lastReceiptData$
//   .reset(HeaderGate.open)
//   .on(fetchLastReceiptFx.doneData, (state, { items }) => {
//     if (!items.source.length) {
//       return undefined;
//     }

//     const { id } = items.source[0];
//     const date = new Date(Date.parse(items.source[0].createdDate));
//     const lastReceiptLocalStorage = localStorage.getItem("lastReceiptDate");
//     localStorage.setItem("lastReceiptDate", date.getTime().toString());
//     const hasNew = lastReceiptLocalStorage
//       ? date.getTime() > parseInt(lastReceiptLocalStorage, 10)
//       : false;
//     return {
//       id,
//       date,
//       hasNew,
//     };
//   })
//   .on(showNewReceipts, (state) => {
//     fetchLastReceiptFx();
//     return { ...state, hasNew: false };
//   })
//   .on(fetchReceiptsFx.done, (state, { params }) => {
//     const {
//       offset,
//       orderBy,
//       dateFrom: dateFromStr,
//       dateTo: dateToStr,
//       inn,
//     } = params;
//     const { date } = state;
//     const dateFrom = dateFromStr ? new Date(Date.parse(dateFromStr)) : null;
//     const dateTo = dateToStr ? new Date(Date.parse(dateToStr)) : null;
//     if (
//       offset === 0 &&
//       orderBy === ReceiptSorting.CREATED_DATE_DESC &&
//       (!dateFrom || dateFrom <= date) &&
//       (!dateTo || dateTo >= date) &&
//       !inn
//     ) {
//       return { ...state, hasNew: false };
//     }
//   });

sample({
  clock: showNewReceipts,
  source: HeaderGate.state,
}).watch(({ history }) => history.push("/"));
