import styled, {
  css,
  FlattenInterpolation,
  ThemedStyledProps,
} from "styled-components";
import { Spinner } from "../Spinner";
import { border, breakpoint, color, font, transition } from "../../../theme";
import { ButtonTheme } from "./index";

interface StyledProps {
  buttonTheme: ButtonTheme;
  isLoading: boolean;
  disabled: boolean;
  inline: boolean;
  text?: boolean;
  color?: string;
  actionType?: "attention" | "danger";
  size?: "medium";
  reverseColor?: string;
  icon?: boolean;
}

const defaultTheme = css<StyledProps>`
  background-color: ${(p) => {
    if (p.disabled) {
      return color.muted;
    }
    switch (p.actionType) {
      case "attention":
        return color.orange;
      case "danger":
        return color.danger;
      default:
        return color.secondary;
    }
  }};

  color: ${(p) => {
    if (p.color) return p.color;
    if (p.isLoading) {
      return color.transparent;
    }

    return color.white;
  }};

  &:hover {
    ${(p) => {
      if (p.disabled) {
        return;
      }

      return `background-color: ${color.orange}`;
    }};
    box-shadow: 0px 5px 20px -10px rgba(0, 178, 255, 0.5),
      0px 20px 60px -10px rgba(0, 178, 255, 0.5);
  }
`;

const infoTheme = css<StyledProps>`
  background-color: ${(p) => {
    if (p.disabled) {
      return color.muted;
    }

    return color.primary;
  }};

  color: ${(p) => {
    if (p.isLoading) {
      return color.transparent;
    }

    return color.white;
  }};

  &:hover {
    ${(p) => {
      if (p.disabled) {
        return;
      }

      return `background-color: ${color.orange}`;
    }};
  }
`;

const outlineTheme = css<StyledProps>`
  padding: ${(p) =>
    p.icon
      ? "calc(1.05em - 0.3rem)"
      : "calc(1.05em - 0.3rem) calc(1.8em - 0.3rem) calc(0.95em - 0.3rem)"};
  border-width: 0.3rem;
  border-style: solid;
  border-color: ${(p) => {
    if (p.disabled && !p.isLoading) {
      return color.muted;
    }

    if (p.color) return p.color;

    return color.link;
  }};

  background-color: ${color.transparent};

  color: ${(p) => {
    if (p.disabled && !p.isLoading) {
      return color.muted;
    }

    if (p.isLoading) {
      return color.transparent;
    }

    if (p.color) return p.color;

    return color.link;
  }};

  &:hover {
    ${(p) => {
      if (p.disabled) {
        return;
      }
      if (p.color) {
        return `background-color: ${p.color}`;
      }

      return `background-color: ${color.orange}`;
    }};

    ${(p) => {
      if (p.disabled) return;
      if (p.reverseColor) return `color: ${p.reverseColor}`;
    }};
  }
`;

const simpleTheme = css<StyledProps>`
  color: ${(p) => {
    if (p.disabled && !p.isLoading) {
      return color.muted;
    }

    if (p.isLoading) {
      return color.transparent;
    }

    return "#687591";
  }};
  background: #f1f3f9;
`;

const textTheme = css<StyledProps>`
  background: transparent;
  color: ${(p) => p.color || "#687591"};
  :focus,
  :active {
    border: none;
    box-shadow: none;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const themes: Record<
  ButtonTheme,
  FlattenInterpolation<ThemedStyledProps<StyledProps, any>>
> = {
  default: defaultTheme,
  info: infoTheme,
  outline: outlineTheme,
  simple: simpleTheme,
  text: textTheme,
};

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
`;

export const ButtonContainer = styled.button<StyledProps>`
  position: relative;
  box-shadow: none;
  border: none;
  display: flex;
  font-family: ${font.family.primary};
  font-size: ${(p) => (p.size === "medium" ? "1.6rem" : "2.5rem")};
  text-transform: uppercase;
  padding: ${(p) => (p.icon ? "1.05em" : "1.05em 1.8em 0.95em")};
  align-items: center;
  justify-content: center;
  border-radius: ${border.radius};
  transition: all ${transition.duration};
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  outline: none;
  ${(p) => p.icon && "background-color: #fff !important;"}

  @media (min-width: 1055px) {
    font-size: ${(p) => (p.size === "medium" ? "2rem" : "2.5rem")};
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ${(p) =>
    p.inline &&
    `
        @media (min-width: ${breakpoint.tablet}) {
            display: inline-block;
            vertical-align: top;
            width: auto;
        }
    `};

  &:active {
    ${Inner} {
      ${(p) => {
        if (p.disabled) {
          return;
        }

        return "top: 1px";
      }};
    }
  }

  &:focus {
    box-shadow: none;
  }

  ${(p) => themes[p.buttonTheme]};
  &.btn-code {
    width: 100%;
    margin-top: 2rem;
    @media (min-width: ${breakpoint.tablet}) {
      width: auto;
      margin-top: 0;
    }
  }

  svg {
    height: 0.5em;
    width: auto;
  }
`;

export const ButtonSpinner = styled(Spinner)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-size: 11px;
`;

export const ButtonLinkContainer = styled.a<StyledProps>`
  position: relative;
  box-shadow: none;
  border: none;
  display: flex;
  font-family: ${font.family.primary};
  font-size: 2.5rem;
  text-transform: uppercase;
  padding: ${(p) => (p.icon ? "1.05em" : "1.05em 1.8em 0.95em")};
  align-items: center;
  justify-content: center;
  border-radius: ${border.radius};
  transition: all ${transition.duration};
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  outline: none;
  text-decoration: none;
  ${(p) => p.icon && "background-color: #fff !important;"}
  ${(p) => themes[p.buttonTheme]};
  ${(p) =>
    p.inline &&
    `
        @media (min-width: ${breakpoint.tablet}) {
            display: inline-block;
            vertical-align: top;
            width: auto;
        }
    `};

  &:active {
    ${Inner} {
      ${(p) => {
        if (p.disabled) {
          return;
        }

        return "top: 1px";
      }};
    }
  }

  &:focus {
    box-shadow: none;
  }
  &.btn-code {
    width: 100%;
    margin-top: 2rem;
    @media (min-width: ${breakpoint.tablet}) {
      width: auto;
      margin-top: 0;
    }
  }

  svg {
    height: 0.5em;
    width: auto;
  }
`;
