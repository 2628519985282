import React, { useState } from "react";
import { Calendar } from "react-date-range";
import { useTranslation } from "react-i18next";
// @ts-ignore
import * as rdrLocales from "react-date-range/dist/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./style.css";
import { ReactComponent as CloseIcon } from "../../../../../assets/svg/ic-close.svg";
import { Dialog } from "../../../../ui-kit/Dialog";
import { UserInfoModalHeader } from "../../../../common/Header/UserInfoModal/styled";
import {
  CloseDialogIcon,
  DialogHeaderTitle,
} from "../../../../ui-kit/Dialog/styled";
import { Button } from "../../../../ui-kit/Button";
import { formatDate } from "../../../../../utils/date";
import { $appealsFilterStore, $appealsFilterStoreApi } from "../../index";
import { useStore } from "effector-react";

interface Props {
  onSubmit: Function;
  onDismiss: Function;
}

export function AppealsFilterCalendar({ onSubmit, onDismiss }: Props) {
  // @ts-ignore
  const { t, i18n } = useTranslation();
  console.log("i18n", i18n);
  // const [date, setDate] = useState<Date | undefined>(new Date());

  const { filterDate: date } = useStore($appealsFilterStore);
  const { setDate } = $appealsFilterStoreApi;

  const formattedDate = React.useMemo(
    () => formatDate(date || new Date(), "yyyy-MM-dd"),
    [date]
  );

  function onChange(newDate: Date) {
    setDate(new Date(newDate));
  }

  function clearFilter() {
    setDate(undefined);
    onSubmit(undefined);
  }

  function startSearch() {
    onSubmit(formattedDate);
  }

  return (
    <Dialog onDismiss={() => onDismiss()} ariaLabel="exit">
      <UserInfoModalHeader>
        <DialogHeaderTitle>{t("Поиск по дате")}</DialogHeaderTitle>
        <CloseDialogIcon source={CloseIcon} onClick={() => onDismiss()} />
      </UserInfoModalHeader>
      <Calendar
        locale={i18n.language === "en" ? rdrLocales.enUS : rdrLocales.ru}
        editableDateInputs={false}
        onChange={onChange}
        date={date}
      />
      <Button onClick={startSearch}>{t("Искать")}</Button>
      {
        <Button inline onClick={clearFilter} style={{ marginTop: "2rem" }}>
          {t("Сбросить")}
        </Button>
      }
    </Dialog>
  );
}
