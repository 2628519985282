import styled from 'styled-components';
import {border, color, font, textEllipsis, transition} from '../../../theme';

export const InputContainer = styled.span`
  position: relative;
  display: block;
  width: 100%;
`;

export const NativeInput = styled.input<{
  hasLeftIcon: boolean;
  hasRightIcon: boolean;
  hasError: boolean;
}>`
  ${textEllipsis};
  display: block;
  width: 100%;
  border-radius: ${border.radius};
  padding: 1rem ${(p) => (p.hasRightIcon ? '3rem' : '1rem')} 1rem
    ${(p) => (p.hasLeftIcon ? '50px' : '15px')};
  border: 0px solid ${(p) => (p.hasError ? color.borderError : color.border)};
  font-family: ${font.family.primary};
  font-size: 2rem;
  line-height: 2;
  background-color: ${(p) => (p.disabled ? '#f9f9f9' : color.white)};
  color: ${(p) => (p.disabled ? '#757575' : color.text)};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'text')};
  opacity: 1;
  appearance: none;
  transition: border-color ${transition.duration};

  &:not(:disabled) {
    &:hover {
      border-color: ${color.primary};
    }
  }

  &:focus {
    outline: none;
    border-color: ${(p) => (p.hasError ? color.borderError : color.primary)};
  }

  &::-webkit-input-placeholder {
    color: ${color.border};
  }

  &::-moz-placeholder {
    color: ${color.border};
  }

  &:-ms-input-placeholder {
    color: ${color.border};
  }

  &::-ms-input-placeholder {
    color: ${color.border};
  }

  &::placeholder {
    color: ${color.border};
  }

  &::-ms-clear {
    display: none;
  }
`;

export const IconContainer = styled.span<{
  disableIconEvents: boolean;
  disabled: boolean;
  position: 'left' | 'right';
}>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(p) => p.position}: 15px;
  pointer-events: ${(p) => (p.disableIconEvents ? 'none' : 'auto')};
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: ${(p) => (p.disabled ? 0.3 : 1)};
`;
