import React from 'react';
import {Link} from 'react-router-dom';
import {SuccessContainer, SuccessContainerTitle} from '../PrivateQR/styled';
import {Button} from '../../ui-kit/Button';

export function QrSuccess() {
  return (
    <SuccessContainer>
      <SuccessContainerTitle>QR-код успешно отсканирован.</SuccessContainerTitle>
      <p style={{fontSize: '2rem', textAlign: 'center', padding: '0 1em'}}>
        Внимание! Для появления чека в разделе &quot;Чеки&quot; может понадобится до 24 часов
      </p>
      <Button
        style={{marginBottom: '3rem'}}
        onClick={(e) => {
          if (window.qrScanner) {
            e.preventDefault();
            window.qrScanner.postMessage('qrScanner action');
          }
        }}
      >
        Сканировать новый чек
      </Button>
      <Link to="/" style={{textDecoration: 'none'}}>
        <Button inline>К списку чеков</Button>
      </Link>
    </SuccessContainer>
  );
}
