import styled from "styled-components";
import { font } from "../../../theme";

export const QrContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const QrHeader = styled.h1`
  margin: auto;
  font-family: ${font.family.primary};
  font-size: 25px;
  text-align: center;
`;

export const QrDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: clamp(300px, 50%, 600px);

  > div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 2rem;

    > h1 {
      font-family: ${font.family.primary};
      font-size: 20px;
    }

    > div {
      font-family: ${font.family.secondary};
      font-size: 18px;
      text-align: right;
    }
  }
`;
