import styled from "styled-components";
import { Link } from "react-router-dom";
import { border, breakpoint, color, font } from "../../../theme";
import userIcon from "../../../assets/svg/ic-user.svg";
import logoutIcon from "../../../assets/svg/ic-logout.svg";
import calendarIcon from "../../../assets/svg/ic-calendar.svg";

export const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const NotificationIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${color.white};
  color: ${color.primary};
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

// new design
// layout
export const Inner = styled.div<{ isLogin: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  padding-bottom: 10rem;
  @media (min-width: ${breakpoint.tablet}) {
    margin-bottom: ${(p) => (p.isLogin ? "0" : "3rem")};
    padding-bottom: 0;
  }
  @media (min-width: ${breakpoint.desktop}) {
    margin-bottom: ${(p) => (p.isLogin ? "0" : "6rem")};
  }
`;

// logo
export const LogoContainer = styled(Link)<{ $isLogin: boolean }>`
  display: flex;
  align-items: center;
  text-decoration: none;
  @media (min-width: ${breakpoint.desktop}) {
    width: ${(p) => (p.$isLogin ? "auto" : "7.6%")};
  }
`;

export const LogoImage = styled.img<{ isLogin: boolean }>`
  user-select: none;
  margin-right: 2rem;
  width: ${(p) => (p.isLogin ? "6.5rem" : "6rem")};
  @media (min-width: ${breakpoint.desktop}) {
    width: 44px;
    margin-left: 2px;
  }
`;

export const LogoutButton = styled(Link)<{ $isLogin: boolean }>`
  display: flex;
  align-items: center;
  text-decoration: none;
  @media (min-width: ${breakpoint.desktop}) {
    width: ${(p) => (p.$isLogin ? "auto" : "7.6%")};
  }
`;

export const LogoutIcon = styled.img`
  user-select: none;
  width: 6rem;
  @media (min-width: ${breakpoint.desktop}) {
    width: 4rem;
    margin-right: 1rem;
  }
`;

export const LogoTitle = styled.span`
  font-family: ${font.family.primary};
  font-weight: 500;
  text-transform: uppercase;
  color: ${color.link};
  font-size: 2.7rem;
  width: 4em;
  line-height: 1em;
  @media (min-width: ${breakpoint.tablet}) {
    font-size: 2.8rem;
    display: inline;
    width: auto;
  }
`;

// page title
export const PageTitle = styled.span`
  font-size: 3.3rem;
  color: ${color.grey};
  position: absolute;
  left: 0;
  bottom: 2rem;
  @media (min-width: ${breakpoint.tablet}) {
    display: block;
    position: initial;
    font-size: 3rem;
  }
`;

// search
export const HeaderSearch = styled.div`
  position: relative;
  margin: 0 0 0 auto;
  cursor: pointer;
  border-radius: ${border.radius};
  width: 7rem;
  height: 7rem;
  background: #fff;
  @media (min-width: ${breakpoint.tablet}) {
    width: 48.5%;
    background: transparent;
    height: auto;
  }
  @media (min-width: ${breakpoint.desktop}) {
    margin: 0 auto 0 10.8rem;
  }
`;

export const HeaderSearchInput = styled.input`
  width: 100%;
  background: #fff;
  border: none;
  outline: none;
  line-height: 5.8rem;
  box-shadow: -5px 15px 30px -5px rgba(73, 88, 110, 0.2);
  border-radius: ${border.radius};
  padding-left: 2rem;
  font-size: 1.6rem;
  display: none;
  @media (min-width: ${breakpoint.tablet}) {
    display: block;
  }
`;

export const HeaderSearchIcon = styled.img`
  width: 3rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (min-width: ${breakpoint.tablet}) {
    width: 2rem;
    right: 2rem;
    top: 50%;
    left: auto;
    transform: translate(0, -50%);
    opacity: 0.8;
  }
`;

export const HeaderIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  margin-left: auto;
`;

// user
export const HeaderUser = styled.div`
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  background: url(${userIcon}) center center no-repeat, #fff;
  background-size: 2rem auto;
  box-shadow: -5px 15px 30px -5px rgba(255, 255, 255, 0.3);
  border-radius: ${border.radius};
  cursor: pointer;
  display: none;
  margin-left: 2rem;
  @media (min-width: ${breakpoint.desktop}) {
    display: block;
  }
`;

// logout
export const HeaderLogout = styled.div`
  width: 5.8rem;
  height: 5.8rem;
  background: url(${logoutIcon}) center center no-repeat, #731982;
  background-size: 2rem auto;
  box-shadow: -5px 15px 30px -5px rgba(255, 108, 1, 0.3);
  border-radius: ${border.radius};
  cursor: pointer;
  display: block;
  margin-left: 3rem;
  @media (min-width: ${breakpoint.desktop}) {
    display: none;
  }
`;

// calendar
export const HeaderCalendar = styled.div`
  width: 5.8rem;
  height: 5.8rem;
  background: url(${calendarIcon}) center center no-repeat, #fff;
  background-size: 2rem auto;
  border-radius: ${border.radius};
  cursor: pointer;
  /* margin-left: auto; */
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const BalanceContainer = styled.div`
  background: #ffffff;
  height: 48px;
  border-radius: 16px;
  display: flex;
  .main-container {
    min-width: 120px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 6px;
    .title {
      font-family: "Fira Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #999999;
    }
    .balance {
      font-family: "DNC";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
      color: #687591;
      white-space: nowrap;
      cursor: pointer;
    }
  }
  .button-outcome {
    background: #ffffff;
    height: 48px;
    width: 48px;
    cursor: pointer;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.blocked {
      cursor: default;
      opacity: 0.5;
      .icon-outcome * {
        stroke: #333 !important;
      }
      &:hover {
        background: #ffffff !important;
        .icon-outcome * {
          stroke: #333 !important;
        }
      }
    }
    &:hover {
      background: #00b956;
      .icon-outcome * {
        stroke: #ffffff !important;
      }
    }
  }
`;

export const LoteryContainer = styled.div`
  background: #ffffff;
  height: 48px;
  border-radius: 16px;
  display: flex;
  margin-right: 16px;
  cursor: pointer;
  .main-container {
    min-width: 120px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 6px;
    .title {
      font-family: "Fira Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #999999;
      white-space: nowrap;
    }
    .description {
      font-family: "Fira Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #687591;
    }
  }
  .button-win {
    background: #44cc7a;
    height: 48px;
    width: 48px;
    cursor: pointer;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
