export enum ReceiptSorting {
  CREATED_DATE_ASC = "RECEIVE_DATE:ASC",
  // CREATED_DATE_DESC = 'CREATED_DATE:DESC',
  CREATED_DATE_DESC = "RECEIVE_DATE:DESC",
  TOTAL_SUMM_DESC = "TOTAL_SUMM:DESC",
  TOTAL_SUMM_ASC = "TOTAL_SUMM:ASC",
}

export interface ReceiptRequest {
  limit: number;
  offset: number;
  dateFrom: string | null;
  dateTo: string | null;
  orderBy: ReceiptSorting;
  inn: string | null;
  kktOwner: string | null;
}

export interface Receipt {
  id: string;
  dateTime: string;
  totalSum: string;
  orgName: string;
  deductionSum: string;
  isDigital: boolean;
}

export interface Brand {
  description: string;
  id: number;
  image: string;
  name: string;
}

export interface ReceiptResponse {
  items: Receipt[];
  brands?: Brand[];
  lastPage: boolean;
}

export const RECEIPT_SORTING_TEXT: Record<ReceiptSorting, string> = {
  [ReceiptSorting.CREATED_DATE_ASC]: "Дата: сначала старые",
  [ReceiptSorting.CREATED_DATE_DESC]: "Дата: сначала новые",
  [ReceiptSorting.TOTAL_SUMM_ASC]: "Стоимость: по возрастанию",
  [ReceiptSorting.TOTAL_SUMM_DESC]: "Стоимость: по убыванию",
};

export interface ReceiptDetailsResponse {
  items: ReceiptDetailsItem[];
  abtNumber: string | null;
  cashRegisterNumber: string | null;
  cashierName: string | null;
  dateTime: string | null;
  fdNumber: string | null;
  ffdVersion: string | null;
  fn: string | null;
  fp: string | null;
  id: string | null;
  inn: string | null;
  operationType: string | null;
  orgName: string | null;
  receiptNumber: string | null;
  rnKkt: string | null;
  shiftNumber: string | null;
  sno: string | null;
  totalSum: string | null;
  tradePointAddress: string | null;
  paymentType: string | null;
}

export interface ReceiptDetailsItem {
  goodName: string;
  goodPrice: string;
  goodQuantity: string;
}

export interface ReceiptScanData {
  totalSum: string;
  createdDate: string;
  fiscalDriveNumber: string;
  operationType: number;
  fiscalDocumentNumber: string;
  fiscalSign: string;
  scanDate: string;
}

export interface ReceiptExpirationDateErrorAdditionalInfoItem {
  type: "SMS" | "MAIL";
  login: string;
}

export interface ReceiptExpirationDateErrorAdditionalInfo {
  [key: string]: string;
}

export interface ReceiptExpirationDateErrorData {
  additionalInfo: object;
  code: string;
  message: string;
}

export interface ReceiptExpirationDateSetData {
  identifierLogin: string;
  identifierType: string;
  receiptExpirationDate: string | null;
}
