import { forward, guard, merge } from "effector";
import { toast } from "react-toastify";
import {
  addEmailDialogClose,
  addEmailDialogOpen,
  attachedEmail$,
  attachEmail,
  attachEmailFx,
  confirmEmailDialogClose,
  confirmEmailDialogOpen,
  detachEmailDialogClose,
  detachEmailDialogOpen,
  detachEmailFx,
  detachPhoneFx,
  emailErrorMessage$,
  emails$,
  phones$,
  extraMeta$,
  EmailVerifyGate,
  fetchIdentifiersFx,
  fetchAmountFx,
  isAddEmailDialog$,
  isConfirmEmailDialog$,
  ProfileGate,
  CountGate,
  updateCounts,
  verifyEmailFx,
  detachedEmail$,
  AddEmailGate,
  DeleteEmailGate,
  partnerRedirectLink$,
  sendAdditionalPhone,
  sendAdditionalPhoneFx,
  verifyAdditionalPhoneFx,
  verifyAdditionalPhone,
  addPhoneErrorMessage$,
  verifyPhoneErrorMessage$,
  AddPhoneGate,
  isCodeSended$,
  isPhoneAdded$,
  counts$,
} from "./index";
import {
  attachEmailData,
  detachEmailData,
  detachPhoneData,
  fetchIdentifiers,
  fetchAmount,
  verifyEmail,
  addPhone,
  verifyPhone,
} from "../../../api";
import { IdentifierType } from "../../../types/profile";
import { isAuth$ } from "models/auth";

fetchIdentifiersFx.use(fetchIdentifiers);
fetchAmountFx.use(fetchAmount);
attachEmailFx.use(attachEmailData);
detachEmailFx.use(detachEmailData);
detachPhoneFx.use(detachPhoneData);
verifyEmailFx.use(verifyEmail);
sendAdditionalPhoneFx.use(addPhone);
verifyAdditionalPhoneFx.use(verifyPhone);

export const verifyEmailError = guard({
  source: verifyEmailFx.failData,
  filter: (error) => error.code !== "email.confirmation.another.user",
});

isAddEmailDialog$
  .on(addEmailDialogOpen, () => true)
  .reset([addEmailDialogClose, attachEmailFx.doneData, AddEmailGate.close]);

isConfirmEmailDialog$
  .on([confirmEmailDialogOpen, attachEmailFx.doneData], () => true)
  .reset(confirmEmailDialogClose);

emails$
  .on(fetchIdentifiersFx.doneData, (state, emails) =>
    emails.filter((email) => email.type === IdentifierType.EMAIL)
  )
  .on(detachEmailFx.done, (state, { params }) =>
    state.filter((i) => i.login !== params.identifierLogin)
  )
  .on(attachEmailFx.done, (state, { params }) => {
    const existingEmailRecord = state.find((e) => e.login === params.email);
    return !existingEmailRecord
      ? [
          ...state,
          {
            login: params.email,
            type: IdentifierType.EMAIL,
            confirmed: false,
            id: "",
          },
        ]
      : undefined;
  });

phones$
  .on(fetchIdentifiersFx.doneData, (state, emails) =>
    emails.filter((email) => email.type === IdentifierType.SMS)
  )
  .on(detachPhoneFx.done, (state, { params }) =>
    state.filter((i) => i.login !== params.identifierLogin)
  );

counts$.on(fetchAmountFx.doneData, (state, counts) => ({
  ...counts,
}));

extraMeta$.on(verifyEmailFx.doneData, (state, data) => data.extraMeta);
partnerRedirectLink$.on(verifyEmailFx.doneData, (state, data) =>
  data.externalSystemType === 1 ? data.redirectOnConfirmationLink : null
);

attachedEmail$.on(attachEmail, (state, event) => event);
detachedEmail$
  .on(detachEmailDialogOpen, (state, payload) => payload)
  .reset([
    detachEmailDialogClose,
    detachEmailFx.doneData,
    detachPhoneFx.doneData,
    DeleteEmailGate.close,
  ]);

emailErrorMessage$.on(verifyEmailError, (state, { message }) => message);

forward({
  from: ProfileGate.open,
  to: fetchIdentifiersFx,
});

guard({
  clock: merge([CountGate.open, updateCounts]),
  source: isAuth$,
  filter: (isAuth) => isAuth,
  target: fetchAmountFx,
});

forward({
  from: verifyAdditionalPhoneFx.done,
  to: fetchIdentifiersFx,
});

forward({
  from: EmailVerifyGate.open,
  to: verifyEmailFx,
});

verifyEmailFx.failData.watch((err) => {
  if (
    err.code !== "email.confirmation.another.user" &&
    err.code !== "email.confirmation.another.user"
  ) {
    toast.error(`${err.message}`);
  }
});
verifyEmailFx.doneData.watch(() => fetchIdentifiersFx());
fetchIdentifiersFx.failData.watch((err) => toast.error(`${err.message}`));
attachEmailFx.failData.watch((err) => toast.error(`${err.message}`));
detachEmailFx.failData.watch((err) => toast.error(`${err.message}`));
detachPhoneFx.failData.watch((err) => toast.error(`${err.message}`));

addPhoneErrorMessage$
  .on(sendAdditionalPhoneFx.failData, (state, data) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data.status === 400
      ? "Данный номер уже используется в системе"
      : "Ошибка добавления номера";
  })
  .reset(sendAdditionalPhoneFx.done, AddPhoneGate.close);
verifyPhoneErrorMessage$
  .on(verifyAdditionalPhoneFx.failData, (state, { message }) => message)
  .reset(verifyAdditionalPhoneFx.done, AddPhoneGate.close);
isCodeSended$
  .on(sendAdditionalPhoneFx.fail, () => false)
  .on(sendAdditionalPhoneFx.done, (state, data) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // if (data) alert(data?.result?.code);
    return true;
  })
  .reset(AddPhoneGate.close);
isPhoneAdded$
  .on(verifyAdditionalPhoneFx.fail, () => false)
  .on(verifyAdditionalPhoneFx.done, () => true)
  .reset(AddPhoneGate.close);

forward({
  from: sendAdditionalPhone,
  to: sendAdditionalPhoneFx,
});
forward({
  from: verifyAdditionalPhone,
  to: verifyAdditionalPhoneFx,
});
