import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "normalize.css/normalize.css";
import * as serviceWorker from "./serviceWorker";
import { App } from "./components/common/App";
import "./models/init";
import { loadSessionUserFx } from "./models/auth";
import "./i18n";

Promise.all([loadSessionUserFx()]).finally(initReact);

function initReact() {
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById("root")
  );
}

serviceWorker.unregister();
