import styled from "styled-components";
import { border, breakpoint, color, font } from "../../../theme";
import receiptIcon from "../../../assets/svg/receipt.svg";
import deductionIcon from "../../../assets/svg/deduction.svg";
import calendar from "../../../assets/svg/control-calendar.svg";
import filters from "../../../assets/svg/control-filters.svg";
import new_request from "../../../assets/svg/new_request.svg";

export const NotificationsList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @media (min-width: ${breakpoint.desktop}) {
    width: 92.4%;
  }
`;

export const NotificationsSwitcher = styled.div`
  position: absolute;
  top: -8rem;
  margin: 0 auto;
  font-size: 1.6rem;
  color: #999999;
  display: flex;
  & > * {
    padding: 1.7rem 2.7rem;
    background: #fff;
    border: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  & > *:first-child {
    border-radius: 1.6rem 0 0 1.6rem;
  }
  & > *:last-child {
    border-radius: 0 1.6rem 1.6rem 0;
  }
  & > .active {
    color: #fff;
    background: #00b956;
  }
`;

export const NotificationsItem = styled.div<{
  isNew: boolean;
}>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #fff;
  padding: 3rem 2rem;
  margin-bottom: 1rem;
  border-radius: ${border.radius};
  font-size: 1.8rem;
  /* cursor: pointer; */
  /* :hover {
    box-shadow: 0 10px 50px rgba(0, 15, 95, 0.1), -5px 40px 70px -15px rgba(17, 45, 98, 0.15);
  } */
  @media (min-width: ${breakpoint.desktop}) {
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
  }
  ${({ isNew }) => `
    ${isNew && `background: rgba(0, 160, 227, .3);`}
  `}
`;

export const NotificationsItemParam = styled.span`
  color: ${color.liteGrey};
  font-family: ${font.family.secondary};
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  :nth-child(2) {
    width: 100%;
    margin-top: 1rem;
    @media (min-width: ${breakpoint.desktop}) {
      flex-grow: 3;
      margin-top: 0;
    }
  }
  @media (min-width: ${breakpoint.desktop}) {
    flex: 1 0 0;
    padding-right: 3rem;
  }
`;

export const NotificationsItemParamMessage = styled(NotificationsItemParam)`
  position: relative;
  width: 100%;
  font-weight: 500;
  color: #222;
  max-height: 6.4rem;
  margin-top: 2rem;
  :after {
    content: "...Подробнее";
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 43%
    );
    padding-left: 20rem;
    color: ${color.link};
  }
  * {
    font-size: inherit;
    text-overflow: ellipsis;
    display: inline;
    margin-right: 1rem;
    line-height: 1.2em;
  }
  @media (min-width: ${breakpoint.desktop}) {
    flex: 6 1 0;
    margin-top: 0;
    :after {
      display: none;
    }
  }
`;

export const NotificationsItemCheckbox = styled.div`
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 0.4rem solid #e4e7eb;
  flex: 0 0 2rem;
  top: 1rem;
  right: 1rem;
  &.checked:after {
    content: "";
    position: absolute;
    display: block;
    width: 50%;
    height: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: ${color.orange};
    border-radius: 50%;
  }
  @media (min-width: ${breakpoint.desktop}) {
    position: relative;
    top: auto;
    right: auto;
  }
`;

export const ReceiptIcon = styled.div`
  width: 5.8rem;
  height: 5.8rem;
  background: url(${receiptIcon}) center center no-repeat, #00b956;
  background-size: 2.4rem auto;
  box-shadow: -5px 15px 30px -5px rgba(255, 255, 255, 0.3);
  border-radius: ${border.radius};
`;

export const DeductionIcon = styled.div`
  width: 5.8rem;
  height: 5.8rem;
  background: url(${deductionIcon}) center center no-repeat, #00b956;
  background-size: 2rem auto;
  box-shadow: -5px 15px 30px -5px rgba(255, 255, 255, 0.3);
  border-radius: ${border.radius};
`;

export const NotificationsControlsContainer = styled.div`
  position: absolute;
  top: 18rem;
  right: 5rem;
  display: flex;
  gap: 1.6rem;
  & > * {
    padding: 1.7rem 2.7rem;
    border: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  & > .active {
    color: #fff;
    background: #00b956;
  }
`;

export const ControlIcon = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  background-size: 2.4rem auto;
  box-shadow: -5px 15px 30px -5px rgba(255, 255, 255, 0.3);
  border-radius: ${border.radius};
`;

export const ControlCalendarIcon = styled(ControlIcon)`
  background: url(${calendar}) center center no-repeat, #fff;
`;

export const ControlFilterIcon = styled(ControlIcon)`
  background: url(${filters}) center center no-repeat, #fff;
`;

export const ControlRequestIcon = styled(ControlFilterIcon)`
  background: url(${new_request}) center center no-repeat, #fff;
`;
