import styled from 'styled-components';
import {breakpoint, color, font, transition} from '../../../theme';

export const CheckboxRoot = styled.label<{disabled: boolean}>`
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  margin: 0;
`;

export const Label = styled.span<{
  disabled: boolean;
}>`
  font-family: ${font.family.primary};
  font-size: 2.4rem;
  color: ${color.grey};
  margin-left: 10px;
  font-weight: 500;
  opacity: ${(p) => (p.disabled ? 0.85 : 1)};
  transition: color ${transition.duration};
  font-family: ${font.family.secondary};
  @media (min-width: ${breakpoint.phone}) {
    font-size: 1.8rem;
  }
`;

export const Indicator = styled.span<{
  checked: boolean;
  disabled: boolean;
  round: boolean;
}>`
  position: relative;
  width: 2.8rem;
  height: 2.8rem;
  border: ${(p) =>
    p.round ? `0.3rem solid ${color.liteGrey}` : `0.4rem solid ${color.secondary}`};
  background-color: ${color.white};
  border-radius: ${(p) => (p.round ? '50%' : '0.2rem')};
  flex-shrink: 0;
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
  transition: border ${transition.duration};
  @media (min-width: ${breakpoint.phone}) {
    width: 2.4rem;
    height: 2.4rem;
  }

  &::after {
    transform: translate(50%, -50%)
      ${(p) => {
        if (p.round) return p.checked ? 'scale(1)' : 'scale(0)';
        return p.checked ? 'rotate(-50deg) scale(1)' : 'rotate(-20deg) scale(0)';
      }};
    background: ${(p) => (p.round ? color.orange : 'transparent')};
    opacity: ${(p) => (p.checked ? 1 : 0)};
    content: '';
    transition: all ${transition.duration};
    position: absolute;
    right: 50%;
    top: ${(p) => (p.round ? '50%' : '45%')};
    width: ${(p) => (p.round ? '70%' : '1.1rem')};
    height: ${(p) => (p.round ? '70%' : '0.6rem')};
    border-bottom: ${(p) => (p.round ? 'none' : `0.4rem solid ${color.secondary}`)};
    border-left: ${(p) => (p.round ? 'none' : `0.4rem solid ${color.secondary}`)};
    border-radius: ${(p) => (p.round ? '50%' : '0')};
    @media (min-width: ${breakpoint.phone}) {
      width: ${(p) => (p.round ? '70%' : '0.8rem')};
      height: ${(p) => (p.round ? '70%' : '0.4rem')};
    }
  }
`;

export const Control = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
`;
