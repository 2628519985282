import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import {
  PartnerLogo,
  Body,
  Tabs,
  Tab,
  BodyTitle,
  PartnerFullImage,
  LotteryImg,
} from "./styled";
import { Dialog } from "../../../../ui-kit/Dialog";
import { CloseDialogIcon } from "../../../../ui-kit/Dialog/styled";
import { ReactComponent as CloseIcon } from "../../../../../assets/svg/ic-close.svg";
import { Header } from "../../../Notifications/NotificationModal/styled";
import { Partner } from "../../../../../types/partners";
import "react-custom-scroll/dist/customScroll.css";
import { config } from "../../../../../config";
import { Button } from "../../../../ui-kit/Button";
import {
  confirmPartner,
  rejectPartner,
  unbindPartner,
} from "../../../../../api";

interface Props {
  onDismiss: Function;
  partner: Partner;
  getParams: Function;
  onResultRedirectSuccessLink?: string | null;
  onResultRedirectDenyLink?: string | null;
  onResultRedirect?: string | null;
}

export function PartnerModal({
  onDismiss,
  partner,
  getParams,
  onResultRedirectSuccessLink,
  onResultRedirectDenyLink,
  onResultRedirect,
}: Props) {
  const [currentTab, setCurrentTab] = useState("about");
  const [fullImgLoaded, setFullImgLoaded] = useState(false);
  const [logoLoaded, setLogoLoaded] = useState(false);

  return (
    <Dialog onDismiss={onDismiss} ariaLabel="notification-modal">
      <Header>
        <span style={{ fontSize: 20, lineHeight: "30px" }}>
          {partner.isWinner && "Вы выиграли!"}
        </span>
        <CloseDialogIcon source={CloseIcon} onClick={() => onDismiss()} />
      </Header>
      <LotteryImg />
      {/* <PartnerFullImage
        loaded={fullImgLoaded}
        onLoad={() => {
          setFullImgLoaded(true);
        }}
        src={`${config.baseURL}/api/v1/partner/${partner?.id}/image/full`}
      /> */}
      <h1
        style={{
          fontSize: 40,
          fontWeight: 400,
          margin: "20px 0 -15px 0",
          color: "#222",
        }}
      >
        {partner.name}
      </h1>
      <Body>
        <div style={{ padding: "20px 0" }}>
          <ReactMarkdown
            renderers={{
              link: ({
                href,
                children,
              }: {
                href: string;
                children: string;
              }) => (
                <a href={href} target="_blank" rel="noreferrer">
                  {children}
                </a>
              ),
            }}
          >
            {partner.description}
          </ReactMarkdown>
        </div>
        {/* {partner.isWinner && (
          <Button theme="simple" onClick={() => {}}>
            Получить приз
          </Button>
        )} */}
      </Body>
    </Dialog>
  );
}

PartnerModal.defaultProps = {
  onResultRedirectSuccessLink: null,
  onResultRedirectDenyLink: null,
  onResultRedirect: null,
};
