import styled from 'styled-components';
import {color, font} from '../../../../theme';

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
export const Time = styled.div`
  text-align: left;
  color: ${color.liteGrey};
  font-family: ${font.family.secondary};
  font-size: 1.8rem;
`;
export const Message = styled.div`
  margin: 3rem 0 0 0;
  font-size: 1.8rem;
  font-family: ${font.family.secondary};
  text-align: left;
  line-height: 1.4em;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }
`;
