import styled from 'styled-components';
import {color} from '../../../../../../../theme';

export const SellerPublicName = styled.div``;

export const SellerOfficialName = styled.div`
  font-size: 1.2rem;
  margin-top: 0.6em;
  color: ${color.liteGrey};
`;

export const SellerCellContainer = styled.div`
  div:first-child {
    font-weight: 500;
    font-size: 1.8rem;
    color: inherit;
  }
`;
