import * as React from 'react';
import {ReactNode} from 'react';
import {HintText, Root} from './styled';

interface Props {
  text?: string | null | ReactNode;
  children: ReactNode;
  isError?: boolean;
}

export function Hint({children, isError = true, text}: Props) {
  return (
    <Root>
      {text && <HintText isError={isError}>{text}</HintText>}
      {children}
    </Root>
  );
}

Hint.defaultProps = {
  text: '',
  isError: true,
};
