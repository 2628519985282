import React, {ReactNode} from 'react';
import {LabelText, LabelContainer} from './styled';

interface Props {
  text?: string;
  children: ReactNode;
}

export function Label({text, children}: Props) {
  return (
    <>
      {text ? (
        <LabelContainer>
          <LabelText>{text}</LabelText>
          {children}
        </LabelContainer>
      ) : (
        children
      )}
    </>
  );
}

Label.defaultProps = {
  text: '',
};
