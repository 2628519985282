import React from "react";
import { ReactComponent as SuccessPaymentIcon } from "assets/svg/success-payment.svg";
import { counts$ } from "models/settings/profile";
import { useStore, useGate } from "effector-react";
import WithdrawalModal from "components/pages/Dashboard/components/WithdrawalModal";
import { tableState$, TableGate } from "models/outcome";
import { Outcome } from "types/outcome";
import moment from "moment";
import { formatDate } from "utils/date";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Balance() {
  useGate(TableGate);
  const counts = useStore(counts$);
  // const { data } = useStore(tableState$);
  const history = useHistory();
  const [isWithdrawalModalOpen, setIsWithdrawalModalOpen] =
    React.useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="rounded-3xl bg-white">
        <div className="w-full rounded-3xl bg-gradient-to-br from-[#731982] to-[#00b956] px-12 py-8">
          <div className="flex justify-between">
            <div className="font-['Fira_Sans'] text-[24px] font-bold text-white">
              {t("Баланс")}
            </div>
            {/* <div className="text-[24px] font-light text-white">Настройки</div> */}
          </div>
          <div className="flex justify-between">
            <div
              className="text-[56px] text-white font-bold py-16 cursor-pointer hover:text-blue-50"
              // onClick={() => {
              //   history.push("/payments");
              // }}
            >
              {/*{counts?.balance*/}
              {/*  ? Math.floor(counts?.balance / 100).toLocaleString("ru-RU", {*/}
              {/*      maximumFractionDigits: 0,*/}
              {/*    })*/}
              {/*  : "0"}{" "}*/}
              41
            </div>
            {!counts?.isBlocked && (
              <div className="pt-20">
                <div
                  onClick={() => setIsWithdrawalModalOpen(true)}
                  className="text-white rounded-2xl text-[18px] font-bold py-6 px-12 border-2 border-white cursor-pointer transition hover:bg-[#731982]"
                >
                  {t("ВЫВЕСТИ")}
                </div>
              </div>
            )}
          </div>
        </div>
        {/*<div className="p-8 px-12">*/}
        {/*  <div className="text-[24px]">{t("История списания")}</div>*/}
        {/*  <div className="leading-[36px] font-['Fira_sans']">*/}
        {/*    {!!data?.items?.length &&*/}
        {/*      data?.items?.length > 0 &&*/}
        {/*      data?.items*/}
        {/*        ?.slice(0, 4)*/}
        {/*        .map((item: Outcome, i) => <HistoryItem key={i} item={item} />)}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      {isWithdrawalModalOpen && (
        <WithdrawalModal onDismiss={() => setIsWithdrawalModalOpen(false)} />
      )}
    </>
  );
}

function HistoryItem({ item }: { item: Outcome }) {
  return (
    <div className="flex gap-4 text-[14px] py-4 border-b">
      <div className="w-1/6">
        <div className="w-[32px] h-[32px] bg-green-400 grid place-content-center rounded-xl">
          <SuccessPaymentIcon />
        </div>
      </div>
      <div className="w-3/6 text-slate-400">
        {formatDate(moment(item.createdAt).toDate(), "dd.MM.yyyy HH:mm")}
      </div>
      <div className="w-2/6">
        –{" "}
        {item.amount
          ? Math.floor(item.amount / 100).toLocaleString("ru-RU")
          : "0"}{" "}
      </div>
    </div>
  );
}

export default Balance;
