import styled from "styled-components";
import "@reach/dialog/styles.css";
import { breakpoint, color, font } from "../../../../../../theme";
import { Icon } from "../../../../../ui-kit/Icon";

export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0.3rem 0;
  margin: 0 -10px;
`;

export const ConfirmDialogContent = styled.div`
  font-family: ${font.family.secondary};
  flex: 1 0 auto;
  overflow: auto;
  padding: 0 2rem 2rem;
  margin: 0 -2rem;
`;

export const DialogHeaderTitle = styled.span`
  font-size: 4rem;
`;

export const DialogHeaderSubTitle = styled.span`
  font-size: 2.2rem;
  color: ${color.liteGrey};
  text-transform: uppercase;
  text-align: center;
  display: block;
`;

export const Table = styled.div`
  margin-top: 3rem;
`;

export const TableHeader = styled.div`
  display: flex;
  font-size: 1.3rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #f1f3f9;
  text-transform: uppercase;
  color: ${color.liteGrey};
`;

export const CheckContent = styled.div`
  padding: 2rem 0;
  border-bottom: 1px solid #f1f3f9;
`;

export const CheckContentItem = styled.div`
  display: flex;
  font-size: 1.5rem;
  width: 100%;
  :not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const PaymentType = styled.div`
  font-size: 14px;
`;

export const Total = styled.div`
  flex: 1;
  margin-right: 5px;
`;

export const Sum = styled.div`
  flex: 1;
  text-align: right;
`;

export const Index = styled.div`
  margin-right: 5px;
`;

export const Subject = styled.div`
  display: flex;
  flex: 3;
  margin-right: 5px;
`;

export const SubjectValue = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const DownloadCheckIcon = styled(Icon)`
  color: ${color.orange};
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  svg {
    width: 100%;
    height: auto;
  }
`;

export const CloseDialogIcon = styled(Icon)`
  color: ${color.primary};
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  svg {
    width: 100%;
    height: auto;
  }
`;

export const ReceiptInfoContainer = styled.div`
  padding: 2rem 0;
  border-bottom: 1px solid #f1f3f9;
`;

export const ReceiptColumnContainer = styled(ReceiptInfoContainer)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoint.tablet}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ReceiptContainer = styled.div`
  padding: 2rem 4rem;
  background: #fff;
  @media (min-width: ${breakpoint.desktop}) {
    padding: 4rem 6rem;
  }
`;

export const ReceiptRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  color: ${color.liteGrey};
  :not(:last-child) {
    margin-bottom: 0.6em;
  }
`;

export const ReceiptTotalRow = styled(ReceiptRow)`
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 700;
`;

export const ReceiptColumn = styled.div`
  width: 100%;

  :not(:last-child) {
    margin-right: 10px;
  }

  @media (min-width: ${breakpoint.tablet}) {
    width: 45%;
  }
`;

export const Label = styled.div`
  margin-right: 2rem;
`;
export const BuyerAddress = styled.div`
  word-break: break-word;
  display: flex;
  justify-content: flex-end;
  flex-basis: 175px;
`;

export const Value = styled.div`
  text-align: right;
`;

export const QRCodeContainer = styled.div`
  width: 40%;
  margin: 3rem auto 0;
  svg {
    width: 100%;
    height: auto;
  }
`;
