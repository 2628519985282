import React from 'react';
import NoPhotoIcon from '../../../../../../../assets/svg/ic-no-photo-partner.svg';
import {PartnerIcon} from './styled';

export function PartnerIconCell({value}: {value: string}) {
  const base64regex = /^([0-9a-zA-Z+\\/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  const isBase64 = value ? base64regex.test(value) : false;
  return (
    <div>
      {value === 'none' ? null : (
        <PartnerIcon
          src={isBase64 ? `data:image/jpeg;base64,${value}` : value || NoPhotoIcon}
          alt="icon"
        />
      )}
    </div>
  );
}
