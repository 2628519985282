import React from 'react';
import {Dialog} from '../../../../../../ui-kit/Dialog';
import {
  CloseDialogIcon,
  DialogButtonsRow,
  DialogHeaderTitle,
  DialogMessage,
} from '../../../../../../ui-kit/Dialog/styled';
import {ReactComponent as CloseIcon} from '../../../../../../../assets/svg/ic-close.svg';
import {UserInfoModalHeader} from '../../../../../../common/Header/UserInfoModal/styled';
import {Button} from '../../../../../../ui-kit/Button';
import { useTranslation } from "react-i18next";

export function DeleteDialog({
  onDismiss,
  onSubmit,
  pending,
}: {
  onDismiss: Function;
  onSubmit: Function;
  pending: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Dialog onDismiss={() => onDismiss()} ariaLabel="delete-receipt" center>
      <UserInfoModalHeader>
        <DialogHeaderTitle>{t("Удаление чеков")}</DialogHeaderTitle>
        <CloseDialogIcon source={CloseIcon} onClick={() => onDismiss()} />
      </UserInfoModalHeader>
      <DialogMessage>{t("Вы действительно хотите удалить чеки?")}</DialogMessage>
      <DialogButtonsRow>
        <Button onClick={() => onSubmit()} actionType="attention" isLoading={pending}>
          {t("Удалить")}
        </Button>
        <Button onClick={() => onDismiss()} theme="outline">
          {t("Отмена")}
        </Button>
      </DialogButtonsRow>
    </Dialog>
  );
}
