import React, {useEffect, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {
  emailErrorMessage$,
  EmailVerifyGate,
  extraMeta$,
  partnerRedirectLink$,
  profileState$,
} from '../../../models/settings/profile';
import {Background} from '../../common/App/styled';
import {AuthContent, AuthInfo, AuthTitle, AuthBtn} from '../LoginPage/styled';
import {Button} from '../../ui-kit/Button';
import {useQuery} from '../../../hooks/useQuery';

export function VerifyEmail() {
  const {search} = useLocation().search;
  const history = useHistory();
  const query = useQuery();
  const {emails} = useStore(profileState$);
  const extraMeta = useStore(extraMeta$);

  useGate(EmailVerifyGate, {query: query.get('email-token') || ''});

  const partnerCode = query.get('partnerCode');
  const queryPhone = query.get('phone');

  // оптимистично рассчитываем на этот параметр, чтобы не менять динамически при долгом REST ответе
  const fromPartnerOptimistic = query.get('validate_email_from_partner');

  const errorMessage = useStore(emailErrorMessage$);
  const partnerRedirectLink = useStore(partnerRedirectLink$);
  const isEmailBounded = useMemo(() => emails.find((_email) => _email.confirmed), [emails]);

  useEffect(() => {
    if (isEmailBounded && partnerCode && queryPhone) {
      history.push(`/partners${search}`);
    }
  }, [history, isEmailBounded, partnerCode, queryPhone, search]);

  return (
    <>
      <Background />
      <AuthContent>
        <AuthTitle>
          {errorMessage ? 'Ошибка подтверждения почты' : 'Вы подтвердили свою электронную почту'}
        </AuthTitle>
        <AuthInfo>
          {errorMessage
            ? `${errorMessage}`
            : partnerRedirectLink || fromPartnerOptimistic
            ? 'Вы подтвердили адрес электронной почты и партнер будет получать Ваши чеки по данной почте. Нажмите «Продолжить» чтобы вернуться в приложение партнера'
            : 'Адрес электронной почты подтвержден! Нажмите «Войти», чтобы перейти к списку Ваших чеков'}
        </AuthInfo>
        <AuthBtn>
          {partnerRedirectLink ? (
            <Button
              type="submit"
              className="login-btn"
              onClick={() => {
                window.location.href = partnerRedirectLink;
              }}
            >
              {errorMessage ? 'Повторить' : 'Продолжить'}
            </Button>
          ) : (
            <Button
              type="submit"
              className="login-btn"
              onClick={() => (extraMeta ? history.push(atob(extraMeta)) : history.push('/'))}
            >
              {errorMessage ? 'Повторить' : 'Войти'}
            </Button>
          )}
        </AuthBtn>
      </AuthContent>
    </>
  );
}
