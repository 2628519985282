import { forward, guard, merge, sample } from "effector";

import {
  fetchOutcomeFx,
  fetchMoreOutcomeFx,
  OutcomeError$,
  data$,
  tableState$,
  fetchMore,
  refreshTable,
  TableGate,
} from "./index";
import { fetchOutcome } from "../../api";

fetchOutcomeFx.use(fetchOutcome);
fetchMoreOutcomeFx.use(fetchOutcome);

data$.on(fetchOutcomeFx.doneData, (state, source) => source);

sample({
  clock: merge([TableGate.open]),
  fn: () => ({
    page: 1,
  }),
  target: fetchOutcomeFx,
});
