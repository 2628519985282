import styled from "styled-components";
import { border, color, font } from "../../../theme";

export const TableContainer = styled.div`
  position: relative;
  width: 100%;
  font-family: ${font.family.secondary};
`;

export const TableRoot = styled.div`
  display: block;
  width: 100%;
`;

export const Thead = styled.div`
  font-size: 1.6rem;
`;

export const ThGroup = styled.div`
  padding: 0 6rem 2rem;
`;

export const Th = styled.div`
  color: ${color.liteGrey};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 2rem;
`;

export const Tbody = styled.div`
  background: #fff;
  border-radius: ${border.radius};
  font-size: 1.8rem;
  overflow: hidden;
`;

export const Tr = styled.div`
  display: flex;
  padding: 3rem 0;
  flex-direction: row;
  flex-wrap: nowrap;
  word-break: break-word;
  box-shadow: inset 0px -1px 0px #f1f3f9;
`;

export const Td = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 2rem;
  color: #687591;
`;

export const TrOuter = styled.div<{
  selected?: boolean;
  isHover?: boolean;
}>`
  ${(p) => {
    if (p.isHover) return `cursor: pointer;`;
  }}

  padding: 0 6rem;
  color: ${(p) => (p.selected ? "#fff !important" : color.liteGrey)};
  background: ${(p) => (p.selected ? "#687591 !important" : "")};
  ${Tr} {
    ${(p) => {
      if (p.selected) return "box-shadow: none; color: #fff !important;";
    }};
  }
  ${Td} {
    ${(p) => {
      if (p.selected) return "color: #fff !important;";
    }};
  }
  ${(p) => {
    if (p.isHover)
      return `
    :hover {
      // background: ${color.secondary};
      // ${Tr} {
      //   color: #fff !important;
      //   box-shadow: none;
      // }
      // ${Td} {
      //   color: #fff !important;
      // }
      box-shadow: 0 20px 50px -10px rgba(0, 0, 0, 0.15);
    }
    `;
  }}
`;

export const TdCheckbox = styled.input`
  position: relative;
  padding: 4rem;
  box-shadow: none;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &:after {
    width: 2rem;
    height: 2rem;
    border: 0.2rem solid ${color.liteGrey};
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }
  &:before {
    opacity: 0;
    width: 1rem;
    height: 1rem;
    background: ${color.orange};
    z-index: 2;
    border-radius: 50%;
  }
  &:checked:after {
    border-color: #fff;
  }
  &:checked:before {
    opacity: 1;
  }
`;

export const LoadingContainer = styled.div`
  top: 0;
  background-color: ${color.white};
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoData = styled.div`
  width: 100%;
  background-color: ${color.white};
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  line-height: 1.4;
  color: ${color.border};
  /* font-family: ${font.family.primary}; */
  border-top: 1px solid rgb(235, 235, 235);
  border-radius: ${border.radius};
`;

export const LoadMore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem 0;
`;

export const LoadMoreButton = styled.button`
  font-size: 16px;
  line-height: 1.4;
  color: ${color.primary};
  font-family: ${font.family.primary};
  text-decoration: underline;
`;
