import { apiClient } from "../core/apiClient";
import { BoundResp, Partner, PartnersResponse } from "../types/partners";

export async function fetchPartners(page: number): Promise<PartnersResponse> {
  return (await apiClient.get(`/api/v1/lotteries?page=${page}&limit=21&sortBy=lotteryStatus&order=ASC&status=CURRENT&status=UPCOMING`)).data as PartnersResponse;
}

export async function fetchMyWins(page: number): Promise<PartnersResponse> {
  return (await apiClient.get(`/api/v1/lotteries/my?page=${page}&limit=21&sortBy=lotteryStatus&order=ASC`)).data as PartnersResponse;
}

export async function fetchMyHistory(page: number): Promise<PartnersResponse> {
  return (await apiClient.get(`/api/v1/lotteries?page=${page}&limit=21&sortBy=lotteryStatus&order=ASC&status=PAST`)).data as PartnersResponse;
}

export async function setLoteryRead(id: number) {
  return (await apiClient.get(`/api/v1/lotteries/markRead?lotteryId=${id}`)).data;
}

export async function confirmPartner(
  partnerCode: string,
  requestId: string | null
): Promise<BoundResp> {
  return (
    await apiClient.post(
      `/api/v1/partner/${partnerCode}/request/${requestId}/actions/confirm`,
      {
        approve: true,
        rejectReason: "",
      }
    )
  ).data as BoundResp;
}

export async function rejectPartner(
  partnerCode: string,
  requestId: string | null
): Promise<BoundResp> {
  return (
    await apiClient.post(
      `/api/v1/partner/${partnerCode}/request/${requestId}/actions/confirm`,
      {
        approve: false,
        rejectReason: "",
      }
    )
  ).data as BoundResp;
}

export async function unbindPartner(partnerCode: string): Promise<BoundResp> {
  return (
    await apiClient.post(`/api/v1/partner/${partnerCode}/actions/unbind`, {
      unbindReason: "",
    })
  ).data as BoundResp;
}
