import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import * as rdrLocales from "react-date-range/dist/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./style.css";
import { Dialog } from "../../../ui-kit/Dialog";
import { UserInfoModalHeader } from "../UserInfoModal/styled";
import { Button } from "../../../ui-kit/Button";
import {
  CloseDialogIcon,
  DialogHeaderTitle,
} from "../../../ui-kit/Dialog/styled";
import { ReactComponent as CloseIcon } from "../../../../assets/svg/ic-close.svg";
import { filters$, periodChanged } from "../../../../models/receipt";

interface Props {
  onSubmit: Function;
  onDismiss: Function;
}

export function CalendarDialog({ onSubmit, onDismiss }: Props) {
  // @ts-ignore
  const { dateFrom: startDate, dateTo: endDate } = useStore(filters$);
  const { t, i18n } = useTranslation();
  console.log('i18n', i18n)
  const [state, setState] = useState([
    {
      startDate: startDate || new Date(),
      endDate: endDate || new Date(),
      key: "selection",
    },
  ]);

  const switchInputs = (isDisabled: boolean) => {
    const elelist = document.getElementsByTagName("input");
    for (let i = 0; i < elelist.length; i++) {
      elelist[i].disabled = isDisabled;
    }
  };

  useEffect(() => {
    switchInputs(true);
    return () => {
      switchInputs(false);
    };
  }, []);

  function onChange(e: any) {
    // @ts-ignore
    if (e.selection.startDate && e.selection.endDate) {
      // @ts-ignore
      setState([e.selection]);
    }
  }

  function clearFilter() {
    periodChanged({ startDate: null, endDate: null });
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    onSubmit();
  }

  function startSearch() {
    periodChanged({ startDate: state[0].startDate, endDate: state[0].endDate });
    onSubmit();
  }

  return (
    <Dialog onDismiss={() => onDismiss()} ariaLabel="exit">
      <UserInfoModalHeader>
        <DialogHeaderTitle>{t("Поиск по дате")}</DialogHeaderTitle>
        <CloseDialogIcon source={CloseIcon} onClick={() => onDismiss()} />
      </UserInfoModalHeader>
      <DateRange
        locale={i18n.language === 'en' ? rdrLocales.enUS : rdrLocales.ru}
        editableDateInputs={false}
        onChange={onChange}
        moveRangeOnFirstSelection={false}
        ranges={state}
        rangeColors={["#4265E3"]}
      />
      <Button onClick={startSearch}>{t("Искать")}</Button>
      {startDate && endDate && (
        <Button inline onClick={clearFilter} style={{ marginTop: "2rem" }}>
          Сбросить фильтр
        </Button>
      )}
    </Dialog>
  );
}
