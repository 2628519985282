import {createEffect, createEvent, createStore} from 'effector';
import {createGate} from 'effector-react';
import {History} from 'history';
import {ReceiptResponse} from '../../types/receipt';

export const fetchLastReceiptFx = createEffect<void, ReceiptResponse>();

export const HeaderGate = createGate<{history: History}>();

export const showNewReceipts = createEvent();

export const lastReceiptData$ = createStore<{key: string | null; date: Date; hasNew: boolean}>({
  key: null,
  date: new Date(),
  hasNew: false,
});
