import React, {InputHTMLAttributes, useEffect, useRef, useState} from 'react';
import {IMaskMixin} from 'react-imask';
import {InputContainer, Input, InputIcon, InputIcons, EmailStatus} from './styled';

import {ReactComponent as EditIcon} from '../../../assets/svg/ic-edit-input.svg';
import {ReactComponent as SaveIcon} from '../../../assets/svg/ic-save-input.svg';
import {ReactComponent as resendIcon} from '../../../assets/svg/ic-resend.svg';
import {ReactComponent as deleteIcon} from '../../../assets/svg/ic-delete.svg';
import {Tooltip} from '../Tooltip';
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MaskedInput = IMaskMixin(({inputRef, ...props}: any) => <Input {...props} ref={inputRef} />);

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  onSave?: Function | null;
  needApprove?: boolean;
  onApprove?: Function;
  onDelete?: Function;
  onDeletePending?: Function;
  editable?: boolean;
  withStatus?: boolean;
  mask?: string | RegExp | DateConstructor | NumberConstructor;
}

export function InputEditable({
  value,
  onSave,
  placeholder,
  needApprove,
  onApprove,
  editable,
  onDelete,
  onDeletePending,
  type,
  withStatus,
  mask,
}: Props) {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState(value);
  const [editInProcess, setEditInProcess] = useState(false);
  const [pending, setPending] = useState(false);

  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (editInProcess && !mask) input.current?.focus();
  }, [editInProcess, mask]);

  useEffect(() => {
    if (currentValue !== value) setEditInProcess(true);
  }, [currentValue, value]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  async function approveAction() {
    if (onApprove) {
      setPending(true);
      await onApprove();
      setPending(false);
    } else {
      return null;
    }
  }

  async function deleteAction() {
    if (onDelete) {
      setPending(true);
      await onDelete();
      setPending(false);
    } else {
      return null;
    }
  }

  async function deletePendingAction() {
    if (onDeletePending) {
      setPending(true);
      try {
        await onDeletePending();
      } catch (e) {
        console.error(e);
      }
      setPending(false);
    } else {
      return null;
    }
  }

  async function saveEvent(valueId?: string) {
    if (onSave) {
      await onSave(valueId || currentValue);
      setCurrentValue('');
    }
    return null;
  }

  return (
    <InputContainer
      onSubmit={(e) => {
        e.preventDefault();
        saveEvent();
      }}
    >
      {mask && (
        <MaskedInput
          mask={mask}
          withStatus={withStatus}
          disabled={!editable}
          ref={input}
          value={currentValue}
          placeholder={placeholder}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e: any) => setCurrentValue(e.target.value)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onKeyUp={(e: any) => (e.keyCode === 13 ? saveEvent() : null)}
          type={type}
        />
      )}
      {!mask && (
        <Input
          withStatus={withStatus}
          disabled={!editable}
          ref={input}
          value={currentValue}
          placeholder={placeholder}
          onChange={(e) => setCurrentValue(e.target.value)}
          onKeyUp={(e) => (e.keyCode === 13 ? saveEvent() : null)}
          type={type}
        />
      )}
      <InputIcons>
        {currentValue && editable ? (
          <Tooltip text={t("Сохранить")}>
            <InputIcon source={SaveIcon} onClick={() => saveEvent()} needApprove={needApprove} />
          </Tooltip>
        ) : editable ? (
          <Tooltip text={t("Ввести")}>
            <InputIcon
              source={EditIcon}
              onClick={() => setEditInProcess(true)}
              needApprove={needApprove}
            />
          </Tooltip>
        ) : null}
        {needApprove ? (
          <>
            <Tooltip text={t("Повторить отправку")}>
              <InputIcon source={resendIcon} onClick={() => {
                saveEvent(value ? `${value}` : undefined);
              }} spinner={pending} />
            </Tooltip>
            <Tooltip text={t("Удалить")}>
              <InputIcon source={deleteIcon} onClick={() => deletePendingAction()} />
            </Tooltip>
          </>
        ) : editable ? null : (
          <>
            <Tooltip text={t("Удалить")}>
              <InputIcon source={deleteIcon} onClick={() => deleteAction()} />
            </Tooltip>
          </>
        )}
      </InputIcons>
      {needApprove ? (
        <EmailStatus needApprove>{t("Ожидает подтверждения")}</EmailStatus>
      ) : editable ? null : (
        <EmailStatus>{t("Подтверждена")}</EmailStatus>
      )}
    </InputContainer>
  );
}

InputEditable.defaultProps = {
  onSave: null,
  needApprove: false,
  onApprove: null,
  onDelete: null,
  onDeletePending: null,
  editable: true,
  withStatus: false,
  mask: '',
};
