import React, { useEffect, useState } from "react";
import { fetchPartners } from "api";
import { Partner } from "types/partners";
import moment from "moment";
import { ReactComponent as MoneyIcon } from "assets/svg/money.svg";
import { ReactComponent as SingleIcon } from "assets/svg/single.svg";
import { PartnerModal } from "components/pages/Partners/components/PartnerModal";
import { useTranslation } from "react-i18next";

function Lotery() {
  const { t } = useTranslation();
  const [partners, setPartners] = useState<Partner[]>([]);
  const [partnerForModal, setPartnerForModal] = useState<Partner | null>(null);

  useEffect(() => {
    fetchPartners(1).then((partners) => {
      setPartners(partners.content.splice(0, 3));
    });
  }, []);

  return (
    <div>
      <div className="text-[24px] p-12">{t("ЛОТЕРЕИ")}</div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {partners?.length > 0 &&
          partners.map((item) => (
            <LoteryItem
              key={item.id}
              item={item}
              onClick={() => {
                setPartnerForModal(item);
              }}
            />
          ))}
      </div>
      {partnerForModal && (
        <PartnerModal
          onDismiss={() => setPartnerForModal(null)}
          partner={partnerForModal}
          getParams={() => {}}
        />
      )}
    </div>
  );
}

export function LoteryItem({
  item,
  onClick,
}: {
  item: Partner;
  onClick: () => void;
}) {
  const { t } = useTranslation();
  return (
    <div
      className="mh-[140px] rounded-3xl bg-white cursor-pointer font-['Fira_sans']"
      onClick={() => onClick()}
    >
      <div className="flex">
        <div
          className="h-[140px] w-1/5 rounded-l-3xl bg-cover bg-no-repeat bg-center"
          style={{
            backgroundImage: item.isWinner
              ? "url(/images/markets/lotery-dashboard-bg.png)"
              : "url(/images/markets/lotery-default.png)",
          }}
        ></div>
        <div className="w-4/5">
          <div
            className="text-[18px] pt-6 md:pt-12 py-6 px-8 truncate"
            title={item.name}
          >
            {item.name}
          </div>
          {item.isWinner && (
            <div className="bg-green-400 text-white text-[18px] leading-[30px] px-8 w-[101%] rounded-r-md">
              {t("Вы выиграли денежный приз")}
            </div>
          )}
          {!item.isWinner && item.startDate && (
            <div className="text-slate-600 text-[14px] leading-[30px] px-8">
              {!moment(item.endDate).isSameOrBefore(new Date()) && (
                <div>
                  {t("Завершение лотереи")}{" "}
                  {moment(item.endDate).format("DD.MM.yyyy")}
                </div>
              )}
              {moment(item.endDate).isSameOrBefore(new Date()) && (
                <div>
                  {t("Лотерея завершена")}{" "}
                  {moment(item.endDate).format("DD.MM.yyyy")}
                </div>
              )}
            </div>
          )}
          <div className="flex gap-4 p-8">
            <div className="text-[14px] pr-6">
              {item.lotteryType === "REGULAR" && (
                <span>
                  <SingleIcon className="inline-block pr-2 mt-[-3px]" />
                  {t("Регулярная")}
                </span>
              )}
              {item.lotteryType === "IRREGULAR" && (
                <span>
                  <SingleIcon className="inline-block pr-2 mt-[-3px]" />
                  {t("Разовая")}
                </span>
              )}
            </div>
            <div className="text-[14px]">
              <MoneyIcon className="inline-block pr-2 mt-[-2px]" />
              {t("Денежные призы")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Lotery;
