import { apiClient } from "../core/apiClient";
import { Pageable } from "../types/pagination";
import qs from "qs";
import { AffiliateRequestParams, IAffiliate } from "../types/affiliate";

export async function fetchAffiliates(params: Partial<AffiliateRequestParams>) {
  return (
    await apiClient.get<Pageable<IAffiliate>>("/api/v1/partner/registry", {
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { indices: false });
      },
    })
  ).data;
}

export async function fetchSingleAffiliate(id: string | number) {
  return (await apiClient.get<IAffiliate>(`/api/v1/partner/${id}`)).data;
}

export async function bindUserToPartner(partnerId: string | number) {
  return (
    await apiClient.post(`/api/v1/partner/bind`, null, {
      params: { partnerId },
    })
  ).data;
}

export async function unbindUserFromPartner(partnerId: string | number) {
  return (
    await apiClient.post(`/api/v1/partner/unbind`, null, {
      params: { partnerId },
    })
  ).data;
}
