import React from "react";
import {
  ButtonLinkContainer,
  ButtonContainer,
  ButtonSpinner,
  Inner,
} from "./styled";
import { color } from "../../../theme";

export type ButtonTheme = "default" | "info" | "outline" | "simple" | "text";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  isLoading?: boolean;
  theme?: ButtonTheme;
  inline?: boolean;
  href?: string;
  target?: string;
  text?: boolean;
  actionType?: "attention" | "danger";
  size?: "medium";
  reverseColor?: string;
  icon?: boolean;
  rel?: string;
}

export const Button = ({
  isLoading = false,
  children,
  className,
  disabled,
  theme = "default",
  type = "button",
  inline = false,
  target,
  href,
  text,
  actionType,
  size,
  reverseColor = "#fff",
  icon,
  rel,
  ...otherProps
}: Props) => (
  <>
    {href ? (
      <ButtonLinkContainer
        buttonTheme={theme}
        className={className}
        inline={inline}
        disabled={disabled || isLoading}
        isLoading={isLoading}
        href={href}
        target={target}
        text={text}
        actionType={actionType}
        size={size}
        reverseColor={reverseColor}
        icon={icon}
        rel={rel}
      >
        {isLoading && (
          <ButtonSpinner
            color={
              theme === "simple" || theme === "outline"
                ? color.primary
                : color.white
            }
          />
        )}
        <Inner>{children}</Inner>
      </ButtonLinkContainer>
    ) : (
      <ButtonContainer
        buttonTheme={theme}
        className={className}
        inline={inline}
        type={type}
        disabled={disabled || isLoading}
        isLoading={isLoading}
        text={text}
        actionType={actionType}
        size={size}
        reverseColor={reverseColor}
        icon={icon}
        {...otherProps}
      >
        {isLoading && (
          <ButtonSpinner
            color={
              theme === "simple" || theme === "outline"
                ? color.primary
                : color.white
            }
          />
        )}
        <Inner>{children}</Inner>
      </ButtonContainer>
    )}
  </>
);

Button.defaultProps = {
  className: "",
  isLoading: false,
  theme: "default",
  inline: false,
  href: "",
  target: "",
  text: false,
  actionType: undefined,
  size: undefined,
  reverseColor: undefined,
  icon: undefined,
  rel: undefined,
};
