import React from 'react';
import {useLocation} from 'react-router-dom';
import {
  FooterRoot,
  FooterInner,
  FooterItems,
  FooterItem,
  FooterItemIcon,
  FooterItemSubName,
  FooterItemName,
} from './styled';
import {MaxWidthContainer} from '../../ui-kit/MaxWidthContainer';
import {ReactComponent as SupIcon} from '../../../assets/svg/ic-support.svg';
import {Icon} from '../../ui-kit/Icon';
import { useTranslation } from "react-i18next";

export function Footer() {
  const { t, i18n } = useTranslation();
  const {pathname} = useLocation();
  const isLogin = pathname === '/login';
  const footerItems = [
    {
      logo: SupIcon,
      subName: "Service",
      name: "Technical support",
      href: '/',
    },
  ];
  if (!isLogin) {
    return <FooterRoot />;
  }
  return (
    <FooterRoot>
      <MaxWidthContainer>
        <FooterInner>
          <FooterItems>
            {footerItems.map((item) => (
              <FooterItem
                key={item.name}
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FooterItemIcon>
                  <Icon source={item.logo} />
                </FooterItemIcon>
                <div>
                  <FooterItemSubName>{item.subName}</FooterItemSubName>
                  <FooterItemName>{item.name}</FooterItemName>
                </div>
              </FooterItem>
            ))}
          </FooterItems>
        </FooterInner>
      </MaxWidthContainer>
    </FooterRoot>
  );
}
