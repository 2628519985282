import { apiClient } from "../core/apiClient";
import { AppealRequestParams, IAppeal } from "../types/appeal";
import { Pageable } from "../types/pagination";
import qs from "qs";

export async function fetchAppeals(params: Partial<AppealRequestParams>) {
  return (
    await apiClient.get<Pageable<IAppeal>>("/api/v1/appeal/registry", {
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { indices: false });
      },
    })
  ).data;
}

export async function fetchSingleAppeal(id: string | number) {
  return (await apiClient.get<IAppeal>(`/api/v1/appeal/${id}`)).data;
}
