import React, {ReactNode} from 'react';
import {Root, TooltipText} from './styled';

interface Props {
  text: string;
  children: ReactNode;
  minWidth?: number;
}

export function Tooltip({children, text, minWidth = 0}: Props) {
  return (
    <Root
      onMouseEnter={(e) => e.currentTarget.classList.add('hover')}
      onMouseLeave={(e) => e.currentTarget.classList.remove('hover')}
    >
      <TooltipText minWidth={minWidth}>{text}</TooltipText>
      {children}
    </Root>
  );
}

Tooltip.defaultProps = {
  minWidth: 0,
};
