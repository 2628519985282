import * as React from "react";
import { InputHTMLAttributes, ReactNode, useState } from "react";
import { IMaskMixin } from "react-imask";

import {
  InputContainer,
  NativeInput,
  IconContainer,
  DropdownContainer,
  DropdownLayoutContainer,
} from "./styled";
import { Label } from "../Label";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MaskedInput = IMaskMixin(({ inputRef, ...props }: any) => (
  <NativeInput {...props} ref={inputRef} />
));

export interface SelectType<T = string | string[] | undefined> {
  label: string;
  value: T;
}

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  filled?: boolean;
  iconRight?: ReactNode;
  iconLeft?: ReactNode;
  disableIconEvents?: boolean;
  hasError?: boolean;
  label?: string;
  mask?: string | RegExp | DateConstructor | NumberConstructor;
  lazy?: boolean;
  unmask?: boolean;
  placeholderChar?: string;
  values?: SelectType[];
  onSelectItem: (select: SelectType) => void;
  onAccept?(value: string, mask: object): void;
  // other possible masked props: https://github.com/uNmAnNeR/imaskjs/blob/master/packages/react-imask/src/mixin.js
}

export function Select(props: Props) {
  const {
    value,
    values,
    onSelectItem,
    className,
    label,
    disabled = false,
    iconLeft,
    hasError = false,
    disableIconEvents = true,
    mask,
    ...otherProps
  } = props;

  const InputElem = mask ? MaskedInput : NativeInput;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const selectHandler = (e: React.MouseEvent, item: SelectType) => {
    e.stopPropagation();
    setIsDropdownOpen(false);
    onSelectItem(item);
  };

  return (
    <InputContainer
      className={className}
      onClick={() => setIsDropdownOpen((isOpen) => !isOpen)}
    >
      <Label text={label}>
        {iconLeft && (
          <IconContainer
            disableIconEvents={disableIconEvents}
            disabled={disabled}
            position="left"
          >
            {iconLeft}
          </IconContainer>
        )}
        <InputElem
          className="pointer-events-none bg-gray-300"
          bg="#F5F9FB"
          mask={mask}
          hasLeftIcon={!!iconLeft}
          hasError={hasError}
          disabled={disabled}
          readOnly={true}
          value={value}
          {...otherProps}
        />
        {values?.length && isDropdownOpen && (
          <DropdownLayoutContainer
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              setIsDropdownOpen((isOpen) => !isOpen);
            }}
          />
        )}
        {values?.length && isDropdownOpen && (
          <DropdownContainer>
            {values.map((v) => (
              <span key={v.label} onClick={(e) => selectHandler(e, v)}>
                {v.label}
              </span>
            ))}
          </DropdownContainer>
        )}
        <IconContainer
          disableIconEvents={disableIconEvents}
          disabled={disabled}
          position="right"
        >
          <ChevronDownIcon className="h-[40px] w-[40px] text-gray-400" />
        </IconContainer>
      </Label>
    </InputContainer>
  );
}

Select.defaultProps = {
  filled: false,
  iconRight: null,
  iconLeft: null,
  disableIconEvents: false,
  hasError: false,
  label: "",
  mask: "",
  lazy: false,
  unmask: false,
  placeholderChar: "",
  onAccept: () => null,
};
