import React from 'react';
import {InfoBlockContainer} from './styled';
import InfoIcon from '../../../assets/svg/ic-info.svg';

export function InfoBlock({text}: {text: string}) {
  return (
    <InfoBlockContainer>
      <img src={InfoIcon} alt="Информация" />
      <span>{text}</span>
    </InfoBlockContainer>
  );
}
