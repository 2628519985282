import { apiClient } from "../core/apiClient";
import { IncomeResponse, IncomeRequest } from "../types/income";

export async function fetchIncome(req: IncomeRequest): Promise<IncomeResponse> {
  try {
    const resp = (
      await apiClient.get("/api/v1/profile/payments/income", { params: req })
    ).data as IncomeResponse;
    return resp;
  } catch (error) {
    if (error) {
      throw error;
    }
    return { items: [], total: 0, lastPage: true };
  }
}
