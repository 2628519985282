import React from "react";
import { useStore, useGate } from "effector-react";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";
import { Table } from "../../../../ui-kit/Table";
import { DateCell } from "./Cells/DateCell";
import {
  fetchMore,
  receiptSelected,
  receiptForModalSelected,
  orderByChanged,
  TableGate,
  tableState$,
  filters$,
  receiptsSelected$,
} from "../../../../../models/receipt";
import { TotalSumCell } from "./Cells/TotalSumCell";
import { TableList } from "../TableList";
import { StyledTable, SortIcon } from "./styled";
import { ReceiptDetailsDialog } from "./ReceiptDetails";
import { Receipt, ReceiptSorting } from "../../../../../types/receipt";
import { color } from "../../../../../theme";
import { TableSelectionControls } from "./TableSelectionControls";
import { PartnerIconCell } from "./Cells/PartnerIconCell";
import { SellerCell } from "./Cells/SellerCell";
import { Tooltip } from "../../../../ui-kit/Tooltip";
import { formatNumber } from "utils/number";
import { formatDate } from "utils/date";
import {
  SellerPublicName,
  SellerCellContainer,
} from "components/pages/Receipt/components/ReceiptTable/Cells/SellerCell/styled";

interface StatusSelectorType {
  label: string;
  value: ReceiptSorting;
}

export function ReceiptTable() {
  useGate(TableGate);
  const { t } = useTranslation();
  const { orderBy } = useStore(filters$);
  const { data, lastPage, loading, isReceiptDetailsDialog } =
    useStore(tableState$);

  const selectedReceipts = useStore(receiptsSelected$);

  const columns = [
    {
      Header: t("Дата"),
      accessor: "dateTime",
      minWidth: 150,
      width: 130,
      Cell: ({ value }: any) => {
        return (
          <span>
            {value
              ? formatDate(new Date(value as string), "dd.MM.yyyy HH:mm")
              : "-"}
          </span>
        );
      },
    },
    // {
    //   Header: "",
    //   accessor: "brandImage",
    //   minWidth: 40,
    //   width: 40,
    //   Cell: (row: any) => {
    //     let value = "";
    //     if (row?.row?.original?.inn === "00006663000486")
    //       value = "/images/markets/logo_dom.png";
    //     if (row?.row?.original?.inn === "12345678912435")
    //       value = "/images/markets/logo_vet.jpeg";
    //
    //     return <PartnerIconCell value={value} />;
    //   },
    // },
    {
      Header: t("Продавец"),
      minWidth: 240,
      accessor: (d: Receipt) => d,
      Cell: (row: any) => {
        let src = "";
        if (row?.row?.original?.inn === "00006663000486")
          src = "/images/markets/logo_dom.png";
        if (row?.row?.original?.inn === "12345678912435")
          src = "/images/markets/logo_vet.jpeg";
        return (
          <div className="flex gap-5 items-center">
            <PartnerIconCell value={src} />
            <SellerCellContainer>
              {row.value?.orgName && (
                <SellerPublicName>{row.value?.orgName}</SellerPublicName>
              )}
            </SellerCellContainer>
          </div>
        );
      },
    },
    {
      Header: t("ИНН"),
      minWidth: 240,
      accessor: "inn",
      width: 120,
    },
    {
      Header: t("Стоимость"),
      accessor: "totalSum",
      minWidth: 120,
      width: 120,
      Cell: ({ value }: any) => {
        return (
          <span>{value ? (value / 100).toLocaleString("ru-RU") : "0"}</span>
        );
      },
    },
    {
      Header: t("Кэшбэк"),
      accessor: "",
      minWidth: 120,
      width: 120,
      Cell: (row: any) => {
        return (
          <span>
            {row.row?.original?.fn &&
              String(row.row?.original?.fn).split("").pop()}
          </span>
        );
      },
    },
  ];

  function onThClick(col: Column<Receipt>) {
    if (col.id === "dateTime") {
      if (orderBy === ReceiptSorting.CREATED_DATE_ASC) {
        orderByChanged(ReceiptSorting.CREATED_DATE_DESC);
      } else {
        orderByChanged(ReceiptSorting.CREATED_DATE_ASC);
      }
    }
  }

  function selectAll() {
    data.forEach((receipt) => {
      receiptSelected({ key: receipt.id, select: true });
    });
  }

  return (
    <>
      {selectedReceipts.length ? (
        <TableSelectionControls selectAll={selectAll} />
      ) : null}
      <StyledTable>
        <Table
          data={data}
          columns={columns}
          loading={loading}
          hasMore={!lastPage && data.length > 0}
          onLoadMore={() => fetchMore()}
          // onThClick={(column) => onThClick(column)}
          onClickRow={(row) => receiptForModalSelected(row.original.id)}
          // onSelect={(row) => receiptSelected({ key: row.original.id })}
          selectedRows={selectedReceipts}
        />
      </StyledTable>
      <TableList selectedRows={selectedReceipts} />

      {isReceiptDetailsDialog && <ReceiptDetailsDialog />}
    </>
  );
}
