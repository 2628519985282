import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PartnersList } from "./styled";
import {
  fetchPartners,
  fetchMyWins,
  fetchMyHistory,
  setLoteryRead,
} from "../../../api";
import { Partner as PartnerTS } from "../../../types/partners";
import { SpinnerContainer } from "../Receipt/components/ReceiptTable/ReceiptDetails/styled";
import { Spinner } from "../../ui-kit/Spinner";
import { NoData } from "../Receipt/components/TableList/styled";
import { PartnerModal } from "./components/PartnerModal";
import { Partner } from "./components/Partner";
import { LoteryItem } from "components/pages/Dashboard/components/Lotery";
import { updateCounts } from "models/settings/profile";
import { Button } from "../../ui-kit/Button";
import { useTranslation } from "react-i18next";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface Props {
  win?: boolean;
  history?: boolean;
}

export function Partners({ win, history }: Props) {
  const { t } = useTranslation();
  const [partners, setPartners] = useState<PartnerTS[]>([]);
  const [pending, setPending] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [partnerForModal, setPartnerForModal] = useState<PartnerTS | null>(
    null
  );
  const [page, setPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<boolean>(true)

  function getParams(page: number) {
    setPending(true);
    if (win) {
      fetchMyWins(page)
        .then((data) => {
          setPartners(partners.concat(data.content));
          setLastPage(data.lastPage);
          setPending(false);
        })
        .catch((e) => {
          console.log(e);
          setPending(false);
        });
    } else if (history) {
      fetchMyHistory(page)
        .then((data) => {
          setPartners(partners.concat(data.content));
          setLastPage(data.lastPage);
          setPending(false);
        })
        .catch((e) => {
          console.log(e);
          setPending(false);
        });
    } else {
      fetchPartners(page)
        .then((data) => {
          setPartners(partners.concat(data.content));
          setLastPage(data.lastPage);
          setPending(false);
        })
        .catch(() => {
          setPending(false);
        });
    }
  }

  useEffect(() => {
    getParams(1);
  }, [win]);

  useEffect(() => {
    if (page > 1) {
      getParams(page);
    }
  }, [page]);

  useEffect(() => {
    partners?.forEach((element) => {
      if (!element.isReadThatWon && element.isWinner) {
        setLoteryRead(element.id).then((res) => {
          updateCounts();
        });
      }
    });
  }, [partners]);

  function onDismiss() {
    setShowModal(false);
  }

  return (
    <PartnersList>
      {showModal && partnerForModal && (
        <PartnerModal
          onDismiss={onDismiss}
          partner={partnerForModal}
          getParams={() => {}}
          // onResultRedirectSuccessLink={onResultRedirectSuccessLink}
          // onResultRedirectDenyLink={onResultRedirectDenyLink}
          // onResultRedirect={onResultRedirect}
        />
      )}
      {pending && page === 1 ? (
        <NoData>
          <SpinnerContainer>
            <Spinner radius="20px" />
          </SpinnerContainer>
        </NoData>
      ) : !partners?.length ? (
        <NoData>{t("Список розыгрышей пуст")}</NoData>
      ) : (
        <div className="w-full">
          <div className="w-full grid grid-cols-3 gap-8 pb-8">
            {partners.map((partner: PartnerTS) => (
              <LoteryItem
                key={`${partner.id}`}
                item={partner}
                onClick={() => {
                  setShowModal(true);
                  setPartnerForModal(partner);
                }}
              />
            ))}
          </div>
          {!lastPage && 
            <div className="flex justify-center">
              {pending ?
                  <SpinnerContainer className="my-32">
                    <Spinner radius="20px" />
                  </SpinnerContainer> :
                <Button style={{ margin: "3rem 0 5rem 0" }} onClick={() => setPage(page + 1)}>
                  {t("Показать еще")}
                </Button>
              }
            </div>
          }
        </div>
      )}
    </PartnersList>
  );
}

Partners.defaultProps = { win: false };
