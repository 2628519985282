import React from "react";
import { useGate, useStore } from "effector-react";
import QRCodeSvg from "qrcode.react";
// import html2canvas from 'html2canvas';
import { toJpeg } from "html-to-image";
import {
  CloseDialogIcon,
  ConfirmDialogContent,
  DialogHeader,
  ReceiptRow,
  ReceiptTotalRow,
  ReceiptColumn,
  ReceiptInfoContainer,
  ReceiptColumnContainer,
  ReceiptContainer,
  Label,
  Value,
  SpinnerContainer,
  TableHeader,
  Subject,
  Total,
  Sum,
  Table,
  Index,
  SubjectValue,
  DownloadCheckIcon,
  DialogHeaderTitle,
  DialogHeaderSubTitle,
  CheckContent,
  CheckContentItem,
  QRCodeContainer,
} from "./styled";
import { ReactComponent as CloseIcon } from "../../../../../../assets/svg/ic-close.svg";
import { ReactComponent as DownloadIcon } from "../../../../../../assets/svg/ic-download.svg";
import {
  fetchReceiptDetailsFx,
  receiptDetails$,
  receiptDetailsDialogClose,
  ReceiptDetailsGate,
} from "../../../../../../models/receipt";
import { formatNumber } from "../../../../../../utils/number";
import { Spinner } from "../../../../../ui-kit/Spinner";
import {
  FISCAL_DOC_FORMAT_VER,
  OPERATION_TYPE,
} from "../../../../../../constants/receipt";
import { formatDate } from "../../../../../../utils/date";
import { Dialog } from "../../../../../ui-kit/Dialog";
import { useTranslation } from "react-i18next";

declare global {
  interface Window {
    base64Image: {
      postMessage: Function;
    };
  }
}

function formatValue(value: string | number | undefined | null, asIs = true) {
  if (value === undefined || value === null) {
    return "--";
  }
  return typeof value === "number" && !asIs ? formatNumber(value) : value;
}

export function ReceiptDetailsDialog() {
  const { t } = useTranslation();
  const receiptDetails = useStore(receiptDetails$);
  const loading = useStore(fetchReceiptDetailsFx.pending);
  useGate(ReceiptDetailsGate);

  async function downloadReceipt() {
    const receiptContainer = document.getElementById("receipt-container");
    if (receiptContainer) {
      const a = document.createElement("a");
      const imgData = await toJpeg(receiptContainer, {
        pixelRatio: 1,
        filter: (node) => {
          if (node.id === "download-icon" || node.id === "close-icon") {
            return false;
          }
          return true;
        },
      });
      // const imgData = canvas.toDataURL('image/jpeg');
      if (window.base64Image) window.base64Image.postMessage(imgData);
      else {
        a.href = imgData.replace("image/jpeg", "image/octet-stream");
        a.download = "receipt.jpg";
        a.click();
      }
    }
  }

  function taxationType() {
    switch (receiptDetails?.sno) {
      case "1":
        return "ОСН";
      case "2":
        return "УСН Доход";
      case "4":
        return "УСН Доход - Расход";
      case "8":
        return "ЕНВД";
      case "16":
        return "ЕСХН";
      case "32":
        return "Патент";
      default:
        return "ОСН";
    }
  }

  return (
    <Dialog
      onDismiss={receiptDetailsDialogClose}
      ariaLabel="receipt-details-modal"
      receipt
    >
      <ReceiptContainer id="receipt-container">
        <DialogHeader>
          <DownloadCheckIcon
            id="download-icon"
            source={DownloadIcon}
            onClick={() => receiptDetails && downloadReceipt()}
          />
          <DialogHeaderTitle style={{ margin: "0 auto" }}>
            {t("КАССОВЫЙ ЧЕК")}
          </DialogHeaderTitle>
          <CloseDialogIcon
            id="close-icon"
            source={CloseIcon}
            onClick={() => receiptDetailsDialogClose()}
          />
        </DialogHeader>
        <DialogHeaderSubTitle>
          {receiptDetails?.operationType &&
            t(OPERATION_TYPE[parseInt(receiptDetails.operationType, 10)])}
        </DialogHeaderSubTitle>
        <ConfirmDialogContent>
          {loading ? (
            <SpinnerContainer>
              <Spinner radius="20px" />
            </SpinnerContainer>
          ) : receiptDetails ? (
            <>
              {!!receiptDetails?.items?.length && (
                <Table>
                  <TableHeader>
                    <Subject>{t("Предмет расчета")}</Subject>
                    <Total>{t("Количество")}</Total>
                    <Sum>{t("Сумма")}</Sum>
                  </TableHeader>
                  <CheckContent>
                    {receiptDetails.items.map((item, index) => (
                      <CheckContentItem key={index}>
                        <Subject>
                          <Index>{index + 1}.</Index>
                          <SubjectValue>
                            <div>{formatValue(item.goodName)}</div>
                          </SubjectValue>
                        </Subject>
                        <Total>{formatValue(item.goodQuantity)}</Total>
                        <Sum>{formatNumber(item.goodPrice, 2, true)}</Sum>
                      </CheckContentItem>
                    ))}
                  </CheckContent>
                </Table>
              )}

              <ReceiptInfoContainer>
                <ReceiptTotalRow>
                  <div>{t("Итог")}:</div>
                  <div>
                    {formatNumber(receiptDetails?.totalSum || "", 2, true)}
                  </div>
                </ReceiptTotalRow>
                <ReceiptRow>
                  <Label>{t("Безналичные")}</Label>
                  <div>
                    {receiptDetails?.paymentType === "CARD"
                      ? formatNumber(receiptDetails?.totalSum || "", 2, true)
                      : "0,00"}
                  </div>
                </ReceiptRow>
                <ReceiptRow>
                  <Label>{t("Наличные")}</Label>
                  <div>
                    {receiptDetails?.paymentType === "CASH"
                      ? formatNumber(receiptDetails?.totalSum || "", 2, true)
                      : "0,00"}
                  </div>
                </ReceiptRow>
                {/* <ReceiptRow>
                  <Label>Предоплата (аванс)</Label>
                  <div>{formatValue(receiptDetails?.prepaidSum, false)}</div>
                </ReceiptRow> */}
              </ReceiptInfoContainer>
              <ReceiptColumnContainer>
                <ReceiptColumn>
                  <ReceiptRow>
                    <Label>{t("ИНН")}</Label>
                    <div>{formatValue(receiptDetails?.inn)}</div>
                  </ReceiptRow>
                  <ReceiptRow>
                    <Label>{t("№ смены")}</Label>
                    <div>{formatValue(receiptDetails?.shiftNumber)}</div>
                  </ReceiptRow>
                </ReceiptColumn>
                <ReceiptColumn>
                  <ReceiptRow>
                    <Label>{t("№ АВТ")}</Label>
                    <div>{formatValue(receiptDetails?.abtNumber)}</div>
                  </ReceiptRow>
                  <ReceiptRow>
                    <Label>№</Label>
                    <div>{formatValue(receiptDetails?.receiptNumber)}</div>
                  </ReceiptRow>
                </ReceiptColumn>
              </ReceiptColumnContainer>
              <ReceiptInfoContainer>
                <ReceiptRow>
                  <Label>{t("Дата/Время")}</Label>
                  <div>
                    {receiptDetails?.dateTime
                      ? formatDate(
                          new Date(receiptDetails.dateTime),
                          "dd.MM.yyyy HH:mm"
                        )
                      : "--"}
                  </div>
                </ReceiptRow>
                <ReceiptRow>
                  <Label>{t("ККМ №")}:</Label>
                  <div>{formatValue(receiptDetails?.fdNumber)}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <Label>{t("УПФД")}:</Label>
                  <Value>
                    {receiptDetails?.ffdVersion
                      ? FISCAL_DOC_FORMAT_VER[
                          parseInt(receiptDetails?.ffdVersion, 10)
                        ]
                      : "--"}
                  </Value>
                </ReceiptRow>
                <ReceiptRow>
                  <Label>{t("РНМ")}:</Label>
                  <div>--</div>
                </ReceiptRow>
                {/* <ReceiptRow>
                  <Label>РН ККТ:</Label>
                  <div>{formatValue(receiptDetails?.kktRegId)}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <Label>ФП:</Label>
                  <div>{formatValue(receiptDetails?.fiscalSign)}</div>
                </ReceiptRow> */}
                <ReceiptRow>
                  <Label>{t("Кассир")}:</Label>
                  <Value>{formatValue(receiptDetails?.cashierName)}</Value>
                </ReceiptRow>
                <ReceiptRow>
                  <Label>{t("Место расчетов")}:</Label>
                  <div>{formatValue(receiptDetails?.orgName)}</div>
                </ReceiptRow>
                <ReceiptRow>
                  <Label>{t("Адрес расчетов")}:</Label>
                  <Value>
                    {formatValue(receiptDetails?.tradePointAddress)}
                  </Value>
                </ReceiptRow>
              </ReceiptInfoContainer>
              <QRCodeContainer>
                <QRCodeSvg
                  value={`t=${receiptDetails?.dateTime?.replace(
                    /[-:]/gim,
                    ""
                  )}&s=${receiptDetails?.totalSum}&fn=${receiptDetails?.fn}&i=${
                    receiptDetails?.ffdVersion
                  }&fp=${formatValue(receiptDetails?.fp)}&n=${
                    receiptDetails?.operationType
                  }`}
                />
              </QRCodeContainer>
            </>
          ) : (
            <p style={{ fontSize: "1.8rem", color: "red" }}>
              Ошибка при получении данных чека
            </p>
          )}
        </ConfirmDialogContent>
      </ReceiptContainer>
    </Dialog>
  );
}
