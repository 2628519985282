import styled, {keyframes} from 'styled-components';

const rotateKeyframe = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.span<{
  radius?: string;
  color?: string;
}>`
  display: block;
  width: ${(p) => p.radius || '2em'};
  height: ${(p) => p.radius || '2em'};
  font-size: 1rem;
  border: 0.3em solid ${(p) => p.color || '#f96400'};
  border-left-color: transparent;
  border-radius: 50%;
  animation: ${rotateKeyframe} 0.9s ease infinite;
`;
