import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useStore, useGate } from "effector-react";
import moment from "moment";
import { GlobalStyle, ToastContainerRoot } from "./styled";
import { PrivateRoute } from "../PrivateRoute";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { Content } from "../Content";
import { LoginPage } from "../../pages/LoginPage";
import { Receipt } from "../../pages/Receipt";
import { VerifyEmail } from "../../pages/VerifyEmail";
import { LeftSideBar } from "../LeftSideBar";
import { Partners } from "../../pages/Partners";
import { Account } from "../../pages/Account";
import { PrivateQR } from "../../pages/PrivateQR";
import { QrSuccess } from "../../pages/QrSuccess";
import { ReceiptsHistoryDialog } from "./ReceiptsHistoryDialog";
import { refreshTable, tableState$ } from "../../../models/receipt";
import { setReceiptExpirationDate } from "../../../api";
import { ReceiptExpirationDateErrorAdditionalInfoItem } from "../../../types/receipt";
import { Onboarding } from "../../Onboarding";
import { Learning } from "../../Learning";
import { Markets } from "../../pages/Markets";
import { ContentNavBar } from "../../pages/Partners/components/ContentNavBar";
import { Income } from "../../pages/Payments/Income/Income";
import { Outcome } from "../../pages/Payments/Outcome/Outcome";
import { Dashboard } from "../../pages/Dashboard/Dashboard";
import {
  TableGate as OutcomeTableGate,
  tableState$ as outcomeTableState$,
} from "../../../models/outcome";
import { CountGate, updateCounts } from "models/settings/profile";
import { useTranslation } from "react-i18next";
import { NotificationsTab } from "../../pages/Notifications/notifications";
import { AppealsTab } from "../../pages/Notifications/appeals";
import { QrPage } from "../../pages/Qr";
import { Affiliates } from "../../pages/Affiliates";

declare global {
  interface Window {
    gtag: Function;
    ym: Function;
  }
}

export function App() {
  const { t } = useTranslation();
  const AFFILIATE_ITEMS = [
    {
      name: t("Все партнеры"),
      to: "/partners",
    },
    {
      name: t("Подключенные партнеры"),
      to: "/partners/connected",
    },
    {
      name: t("Неподключенные партнеры"),
      to: "/partners/not-connected",
    },
  ];

  const NOTIFICATION_ITEMS = [
    {
      name: t("Уведомления"),
      to: "/notifications",
    },
    {
      name: t("Обращения"),
      to: "/notifications/appeals",
    },
  ];

  const NAV_ITEMS = [
    {
      name: t("Я выиграл приз"),
      to: "/lotery/win",
    },
    {
      name: t("Все розыгрыши"),
      to: "/lotery",
    },
    {
      name: t("История"),
      to: "/lotery/history",
    },
  ];

  const PAYMENTS_ITEMS = [
    {
      name: t("Начисления"),
      to: "/payments",
    },
    {
      name: t("Выводы"),
      to: "/payments/outcome",
    },
  ];

  const PAYMENTS_ITEMS_SHORT = [
    {
      name: t("Начисления"),
      to: "/payments",
    },
    {
      name: t("Выводы"),
      to: "/payments/outcome",
      disabled: true,
    },
  ];
  const { pathname } = useLocation();
  const isLogin = pathname === "/login";
  const isQr = pathname === "/qr";
  const isVerify = new RegExp("/verify").test(pathname);
  const { error } = useStore(tableState$);
  const { data } = useStore(outcomeTableState$);
  const [pending, setPending] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(
    !localStorage.getItem("onboardingShown")
  );
  const [showLearning, setShowLearning] = useState(
    !localStorage.getItem("learningShown")
  );

  function onOnboardingShown() {
    setShowOnboarding(false);
  }

  function onLearningShown() {
    setShowLearning(false);
  }

  async function historyOnSubmit({
    showPeriod,
    identifierParams,
  }: {
    showPeriod: "all" | "fromNow";
    identifierParams: ReceiptExpirationDateErrorAdditionalInfoItem;
  }) {
    setPending(true);
    const receiptExpirationDate =
      showPeriod === "fromNow" ? moment().format("YYYY-MM-DD") : null;
    await setReceiptExpirationDate({
      identifierLogin: identifierParams.login,
      identifierType: identifierParams.type.replace(/\s/g, ""),
      receiptExpirationDate,
    });
    refreshTable();
    setPending(false);
  }

  useGate(CountGate);
  useEffect(() => {
    updateCounts();
    const timer = setInterval(updateCounts, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <GlobalStyle isLogin={isLogin || isVerify || isQr} />
      {error && error.additionalInfo && (
        <ReceiptsHistoryDialog
          identifier={JSON.parse(Object.values(error.additionalInfo)[0])}
          onSubmit={historyOnSubmit}
          onDismiss={() => null}
          pending={pending}
        />
      )}
      {!isQr && <Header />}
      <Content>
        <Switch>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route exact path="/qr">
            <QrPage />
          </Route>
          <Route exact path="/verify">
            <VerifyEmail />
          </Route>
          <Route path="/">
            <ToastContainerRoot
              position="bottom-center"
              autoClose={5000}
              hideProgressBar
              closeOnClick
            />
            <LeftSideBar />
            <Switch>
              <PrivateRoute exact path="/receipts">
                {/* {!isLogin && !isVerify && showOnboarding && (
                  <Onboarding onShown={onOnboardingShown} />
                )}
                {!isLogin && !isVerify && !showOnboarding && showLearning && (
                  <Learning onShown={onLearningShown} />
                )} */}
                <Receipt />
              </PrivateRoute>
              <Route path="/lotery">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div className="pt-24">
                    <ContentNavBar items={NAV_ITEMS} />
                  </div>
                  <Switch>
                    <PrivateRoute exact path="/lotery">
                      <Partners />
                    </PrivateRoute>
                    <Route exact path="/lotery/win">
                      <Partners win={true} />
                    </Route>
                    <Route exact path="/lotery/history">
                      <Partners history={true} />
                    </Route>
                  </Switch>
                </div>
              </Route>
              <Route path="/payments">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {/*@ts-ignore*/}
                  <OutcomeTableGate />
                  <ContentNavBar
                    items={
                      data.items?.length > 0
                        ? PAYMENTS_ITEMS
                        : PAYMENTS_ITEMS_SHORT
                    }
                  />
                  <Switch>
                    <PrivateRoute exact path="/payments">
                      <Income />
                    </PrivateRoute>
                    <Route exact path="/payments/outcome">
                      <Outcome />
                    </Route>
                  </Switch>
                </div>
              </Route>
              <Route path="/appeals">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    paddingTop: "10rem",
                  }}
                >
                  {/*<div className="pt-24">*/}
                  {/*  <ContentNavBar items={NOTIFICATION_ITEMS} />*/}
                  {/*</div>*/}
                  {/*<Switch>*/}
                  {/*  <PrivateRoute exact path="/notifications">*/}
                  {/*    <NotificationsTab />*/}
                  {/*  </PrivateRoute>*/}
                  <Route exact path="/appeals">
                    <AppealsTab />
                  </Route>
                  {/*</Switch>*/}
                </div>
              </Route>
              <Route path="/partners">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div className="pt-24">
                    <ContentNavBar items={AFFILIATE_ITEMS} />
                  </div>
                  <Switch>
                    <PrivateRoute exact path="/partners">
                      <Affiliates userBind={undefined} key="all" />
                    </PrivateRoute>
                    <Route exact path="/partners/connected">
                      <Affiliates userBind={true} key="true" />
                    </Route>
                    <Route exact path="/partners/not-connected">
                      <Affiliates userBind={false} key="false" />
                    </Route>
                  </Switch>
                </div>
              </Route>
              <PrivateRoute path="/account">
                <Account />
              </PrivateRoute>
              <PrivateRoute path="/">
                <Dashboard />
              </PrivateRoute>
              <PrivateRoute exact path="/private-qr">
                <PrivateQR />
              </PrivateRoute>
              <PrivateRoute exact path="/qr-success">
                <QrSuccess />
              </PrivateRoute>
              <PrivateRoute path="*">
                <Redirect to="/" />
              </PrivateRoute>
            </Switch>
          </Route>
        </Switch>
      </Content>
      {(isLogin || isVerify) && <Footer />}
    </>
  );
}
