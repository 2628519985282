import React, {useState} from 'react';
import {useStore} from 'effector-react';
import {
  TableSelectionControlsStyled,
  TableSelectionControlsInner,
  TableSelectionControlsInnerStub,
} from './styled';
import {Button} from '../../../../../ui-kit/Button';
import {deleteReceipt} from '../../../../../../api';
import {
  clearSelectedReceipts,
  receiptsSelected$,
  refreshTable,
} from '../../../../../../models/receipt';
import {DeleteDialog} from './DeleteDialog';
import { useTranslation } from "react-i18next";

export function TableSelectionControls({selectAll}: {selectAll: Function}) {
  const { t } = useTranslation();
  const receiptsKeys = useStore(receiptsSelected$);
  const [showDelete, setShowDelete] = useState(false);
  const [pending, setPending] = useState(false);

  async function deleteReceiptAction() {
    setPending(true);
    await deleteReceipt(receiptsKeys);
    clearSelectedReceipts();
    refreshTable();
    setPending(false);
  }

  return (
    <TableSelectionControlsStyled>
      {showDelete && (
        <DeleteDialog
          onDismiss={() => setShowDelete(false)}
          onSubmit={deleteReceiptAction}
          pending={pending}
        />
      )}
      <TableSelectionControlsInner>
        <TableSelectionControlsInnerStub />
        <Button
          theme="simple"
          size="medium"
          style={{marginRight: 'auto'}}
          onClick={() => setShowDelete(true)}
        >
          {t("Удалить")}
        </Button>
        <Button theme="simple" size="medium" onClick={() => selectAll()}>
          Выбрать все
        </Button>
        <Button
          theme="outline"
          size="medium"
          color="#F1F3F9"
          reverseColor="#687591"
          onClick={() => clearSelectedReceipts()}
        >
          Отменить выделение
        </Button>
      </TableSelectionControlsInner>
    </TableSelectionControlsStyled>
  );
}
