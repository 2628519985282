import React from 'react';
import {Dialog} from '../../../ui-kit/Dialog';
import {
  CloseDialogIcon,
  DialogHeaderTitle,
  DialogMessage,
  DialogButtonsRow,
} from '../../../ui-kit/Dialog/styled';
import {ReactComponent as CloseIcon} from '../../../../assets/svg/ic-close.svg';
import {UserInfoModalHeader} from '../UserInfoModal/styled';
import {Button} from '../../../ui-kit/Button';
import {useTranslation} from "react-i18next";

interface Props {
  onSubmit: Function;
  onDismiss: Function;
}

export function ExitDialog({onSubmit, onDismiss}: Props) {
  const { t } = useTranslation();
  return (
    <Dialog onDismiss={() => onDismiss()} ariaLabel="exit">
      <UserInfoModalHeader>
        <DialogHeaderTitle>{t("Выход из профиля")}</DialogHeaderTitle>
        <CloseDialogIcon source={CloseIcon} onClick={() => onDismiss()} />
      </UserInfoModalHeader>
      <DialogMessage>{t("Вы действительно хотите выйти из профиля?")}</DialogMessage>
      <DialogButtonsRow>
        <Button onClick={() => onSubmit()} actionType="attention">
          {t("Выйти")}
        </Button>
        <Button theme={'outline'} onClick={() => onDismiss()}>
          {t("Отмена")}
        </Button>
      </DialogButtonsRow>
    </Dialog>
  );
}
