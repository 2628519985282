import {combine, createEvent, createStore} from 'effector';

export type Category = 'all' | 'available' | 'bound';
type Categories = {[key in Category]: Category};
export const CATEGORY: Categories = {
  all: 'all',
  available: 'available',
  bound: 'bound',
};

export const categoryChanged = createEvent<Category>();
export const searchTermsChanged = createEvent<string>();

export const category$ = createStore<Category>(CATEGORY.all);
export const searchTerms$ = createStore<string>('');

export const partnersState$ = combine<{
  category: Category;
  searchTerms: string;
}>({
  category: category$,
  searchTerms: searchTerms$,
});
