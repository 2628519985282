import React, { FormEvent, useEffect, useState } from "react";
import { useStore } from "effector-react";
import { Button } from "../../../ui-kit/Button";
import {
  CodeFromBack,
  CodeFormInputsGroup,
  CodeFormInput,
  CodeFromFooter,
  CodeFromFooterInfo,
} from "./styled";
import {
  authPhone$,
  authPhoneSelected,
  backToPhoneForm,
  challengeTokenTime$,
  codeSent,
  loadSmsChallengeTokenDataFx,
  loginError$,
  loginFx,
  userIsSlowpoke$,
} from "../../../../models/auth";
import { formatPhone } from "../../../../utils/string";
import { Hint } from "../../../ui-kit/Hint";
import { useTranslation } from "react-i18next";

export function CodeForm() {
  const { t } = useTranslation();
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [saveCredentials] = useState(true);
  const phone = useStore(authPhone$) || "";
  const isSlowpoke = useStore(userIsSlowpoke$);
  const smsChallengeTokenDataPending = useStore(
    loadSmsChallengeTokenDataFx.pending
  );
  const loginPending = useStore(loginFx.pending);
  const error = useStore(loginError$);
  const leftTime = useStore(challengeTokenTime$);

  useEffect(() => {
    const code = document.getElementById("code") as HTMLInputElement | null;
    code?.value && setCode(code?.value.split(""));
  }, []);

  function reSendAction() {
    setCode(["", "", "", "", "", ""]);
    authPhoneSelected(phone);
  }

  async function onSubmit(event: FormEvent) {
    event.preventDefault();
    try {
      await codeSent({ code: code.join(""), saveCredentials });
    } catch (e) {
      console.error(e);
    }
  }
  function onPaste(e: React.ClipboardEvent<HTMLInputElement>) {
    const pasteText = e.clipboardData?.getData("Text").split("");
    if (pasteText.length === 6) {
      setCode(pasteText);
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <Hint text={error}>
        <CodeFormInputsGroup>
          {code &&
            code.map((elem, index) => (
              <CodeFormInput
                type="tel"
                filled={!!elem}
                maxLength={1}
                key={index}
                value={elem}
                onKeyDown={(e) => {
                  if (e.which === 8 && !code[index]) {
                    const prevElem = e.currentTarget
                      .previousElementSibling as HTMLInputElement;
                    prevElem?.focus();
                  }
                }}
                onPaste={onPaste}
                onChange={(e) => {
                  const { value } = e.target;
                  if (!!value && !/[0-9]/.test(value)) return;
                  setCode(Object.assign([], code, { [index]: value }));
                  if (value) {
                    const nextInput = e.target
                      .nextElementSibling as HTMLInputElement;
                    nextInput?.focus();
                  }
                }}
              />
            ))}
          <Button
            type="submit"
            disabled={!code.every((el) => el)}
            isLoading={smsChallengeTokenDataPending || loginPending}
            className="btn-code"
          >
            {"Continue"}
          </Button>
        </CodeFormInputsGroup>
      </Hint>
      <CodeFromFooter>
        <CodeFromBack onClick={() => backToPhoneForm()}>
          &lt; {"Back"}
        </CodeFromBack>
        <CodeFromFooterInfo>
          {isSlowpoke ? (
            <CodeFromBack onClick={reSendAction}>
              {"Request again"}
            </CodeFromBack>
          ) : (
            <>
              Код отправлен на <span>{formatPhone(phone)}</span>. Запросить
              повторно можно через <span>{leftTime}</span> сек.
            </>
          )}
        </CodeFromFooterInfo>
      </CodeFromFooter>
    </form>
  );
}
