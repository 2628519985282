import * as React from "react";
import { InputHTMLAttributes, ReactNode } from "react";
import { IMaskMixin } from "react-imask";

import { InputContainer, NativeInput, IconContainer } from "./styled";
import { Label } from "../Label";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MaskedInput = IMaskMixin(({ inputRef, ...props }: any) => (
  <NativeInput {...props} ref={inputRef} />
));

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  filled?: boolean;
  iconRight?: ReactNode;
  iconLeft?: ReactNode;
  disableIconEvents?: boolean;
  hasError?: boolean;
  label?: string;
  mask?: string | RegExp | DateConstructor | NumberConstructor;
  lazy?: boolean;
  unmask?: boolean;
  placeholderChar?: string;
  onAccept?(value: string, mask: object): void;
  // other possible masked props: https://github.com/uNmAnNeR/imaskjs/blob/master/packages/react-imask/src/mixin.js
}

export function Input(props: Props) {
  const {
    className,
    label,
    disabled = false,
    iconRight,
    iconLeft,
    hasError = false,
    disableIconEvents = false,
    mask,
    ...otherProps
  } = props;

  const InputElem = mask ? MaskedInput : NativeInput;

  return (
    <InputContainer className={className}>
      <Label text={label}>
        {iconLeft && (
          <IconContainer
            disableIconEvents={disableIconEvents}
            disabled={disabled}
            position="left"
          >
            {iconLeft}
          </IconContainer>
        )}
        <InputElem
          mask={mask}
          hasLeftIcon={!!iconLeft}
          hasRightIcon={!!iconRight}
          hasError={hasError}
          disabled={disabled}
          {...otherProps}
        />
        {iconRight && (
          <IconContainer
            disableIconEvents={disableIconEvents}
            disabled={disabled}
            position="right"
          >
            {iconRight}
          </IconContainer>
        )}
      </Label>
    </InputContainer>
  );
}

Input.defaultProps = {
  filled: false,
  iconRight: null,
  iconLeft: null,
  disableIconEvents: false,
  hasError: false,
  label: "",
  mask: "",
  lazy: false,
  unmask: false,
  placeholderChar: "",
  onAccept: () => null,
};
