import React from "react";
import {
  StyledContentNavBar,
  ContentNavBarItem,
  ContentNavBarDivider,
  ContentNavBarItemDisabled,
} from "./styled";
import { TableGate as OutcomeTableGate } from "../../../../../models/outcome";

export function ContentNavBar({
  items,
}: {
  items: {
    name: string;
    to: string;
    disabled?: boolean;
  }[];
}) {
  return (
    <>
      {/*@ts-ignore*/}
      <OutcomeTableGate />
      <StyledContentNavBar>
        {items.map((item, index) => (
          <>
            {!item.disabled && (
              <ContentNavBarItem to={item.to} exact>
                {item.name}
              </ContentNavBarItem>
            )}
            {item.disabled && (
              <ContentNavBarItemDisabled>{item.name}</ContentNavBarItemDisabled>
            )}
            {items.length - 1 !== index && <ContentNavBarDivider />}
          </>
        ))}
      </StyledContentNavBar>
    </>
  );
}
