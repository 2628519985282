import React, {useEffect, useState} from 'react';
import QrReader from 'react-qr-reader';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {
  PageContainer,
  PageTitle,
  SuccessContainer,
  SuccessContainerTitle,
  SuccessContainerErrorTitle,
  ScannerTarget,
  SpinnerOuter,
} from './styled';
import {Button} from '../../ui-kit/Button';
import {Spinner} from '../../ui-kit/Spinner';
import {scanReceipt} from '../../../api/receipt';
import {ReceiptScanData} from '../../../types/receipt';
import Target from '../../../assets/images/scan-field-bg.png';

export function PrivateQR() {
  const [showScanner, setShowScanner] = useState(true);
  const [decodeResult, setDecodeResult] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pending, setPending] = useState(false);

  function decode(result: string | null): void {
    if (result && !pending) setDecodeResult(result);
  }

  useEffect(() => {
    if (decodeResult && !pending) {
      setPending(true);
      const formedResult: ReceiptScanData = {
        createdDate: '',
        fiscalDocumentNumber: '',
        fiscalDriveNumber: '',
        fiscalSign: '',
        operationType: 0,
        scanDate: '',
        totalSum: '0',
      };
      decodeResult.split('&').forEach((param) => {
        const keyValArr = param.split('=');
        switch (keyValArr[0]) {
          case 't':
            formedResult.createdDate = moment(keyValArr[1]).toISOString(true);
            formedResult.createdDate = formedResult.createdDate.slice(
              0,
              formedResult.createdDate.indexOf('+'),
            );
            break;
          case 'fn':
            [, formedResult.fiscalDriveNumber] = keyValArr;
            break;
          case 'i':
            [, formedResult.fiscalDocumentNumber] = keyValArr;
            break;
          case 'fp':
            [, formedResult.fiscalSign] = keyValArr;
            break;
          case 'n':
            formedResult.operationType = Number(keyValArr[1]);
            break;
          case 's':
            formedResult.totalSum = Number(keyValArr[1]).toFixed(2);
            break;
          default:
            return false;
        }
      });
      formedResult.scanDate = moment().toISOString();
      if (
        !formedResult.fiscalDocumentNumber ||
        !formedResult.fiscalDriveNumber ||
        !formedResult.totalSum
      ) {
        setPending(false);
        setShowScanner(false);
        setShowSuccess(false);
        setShowError(true);
        setErrorMessage(
          'Данный QR код не соответствует 54 ФЗ О ККТ. Если на чеке есть другие QR коды - просьба попробовать отсканировать их',
        );
      } else {
        scanReceipt(formedResult)
          .then(() => {
            setPending(false);
            setShowScanner(false);
            setShowError(false);
            setShowSuccess(true);
            setErrorMessage('');
          })
          .catch((e) => {
            setPending(false);
            setShowScanner(false);
            setShowSuccess(false);
            setShowError(true);
            setErrorMessage(e?.message);
            console.error(e);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodeResult]);

  useEffect(() => {
    setDecodeResult('');
  }, [showScanner]);

  return (
    <PageContainer>
      {pending && (
        <SpinnerOuter>
          <Spinner radius="4em" />
        </SpinnerOuter>
      )}
      {showScanner && (
        <div>
          <QrReader
            delay={300}
            onScan={decode}
            onError={() => null}
            showViewFinder={false}
            facingMode="environment"
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              zIndex: 5,
              objectFit: 'cover',
              background:
                'radial-gradient(54.31% 86.89% at 102.64% 139.56%,rgba(255,255,255,0.1) 0%,rgba(255,255,255,0) 100%),#f1f3f9',
            }}
          />
          <ScannerTarget src={Target} />
          <PageTitle>
            Наведите камеру
            <br />
            на QR-код
          </PageTitle>
          {/* <PageFooter> */}
          {/*  <PageFooterClose */}
          {/*    onClick={() => { */}
          {/*      setShowScanner(false); */}
          {/*      setShowError(false); */}
          {/*      setShowSuccess(false); */}
          {/*    }} */}
          {/*  /> */}
          {/* </PageFooter> */}
        </div>
      )}
      {showSuccess && (
        <SuccessContainer>
          <SuccessContainerTitle>QR-код успешно отсканирован.</SuccessContainerTitle>
          <p style={{fontSize: '2rem', textAlign: 'center', padding: '0 1em'}}>
            Внимание! Для появления чека в разделе &quot;Чеки&quot; может понадобится до 24 часов
          </p>
          <Button style={{marginBottom: '3rem'}} onClick={() => setShowScanner(true)}>
            Сканировать новый чек
          </Button>
          <Link to="/" style={{textDecoration: 'none'}}>
            <Button inline>К списку чеков</Button>
          </Link>
        </SuccessContainer>
      )}
      {showError && (
        <SuccessContainer>
          <SuccessContainerErrorTitle>Ошибка сканирования QR-кода</SuccessContainerErrorTitle>
          <p style={{fontSize: '2rem', textAlign: 'center', padding: '0 1em'}}>{errorMessage}</p>
          <Button style={{marginBottom: '3rem'}} onClick={() => setShowScanner(true)}>
            Попробовать еще раз
          </Button>
          <Link to="/" style={{textDecoration: 'none'}}>
            <Button inline>К списку чеков</Button>
          </Link>
        </SuccessContainer>
      )}
    </PageContainer>
  );
}
