import React from "react";
import { HeaderToast } from "./styled";
import { ReactComponent as AttentionIcon } from "assets/svg/attention-white.svg";

export function BlockedToast() {
  return (
    <HeaderToast>
      <span>
        <AttentionIcon className="inline mr-2 mt-[-3px]"/>
        Доступ заблокирован, режим просмотра. Если Вы не нарушали правила
        сервиса, пожалуйста, напишите в поддержку
      </span>
    </HeaderToast>
  );
}
