import styled from 'styled-components';
import {breakpoint} from '../../../theme';

export const PartnersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 2rem);
  align-content: flex-start;
  margin: 0 -1rem;
  @media (min-width: ${breakpoint.tablet}) {
    width: calc(100% + 4.3rem);
    margin: 0 -2.15rem;
  }
  @media (min-width: ${breakpoint.desktop}) {
    width: calc(100% + 2.3rem);
    margin: 0 -1.15rem;
  }
`;
