import { apiClient } from "../core/apiClient";

export async function fetchLocale(lang: string, callback: () => void): Promise<any> {
    try {
        await apiClient.put(`/api/v1/profile?locale=${lang}`);
        callback()
    } catch (error) {
        if (error) {
            throw error;
        }
    }
}