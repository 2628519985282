import {API_URL} from './constants/api';

interface Config {
  baseURL: string;
}

const configs = {
  production: {
    baseURL: window.location.origin,
  },
  development: {
    baseURL: API_URL,
  },
};

export const config: Config = configs[process.env.NODE_ENV as 'production' | 'development'];
