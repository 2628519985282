import styled from "styled-components";
import { breakpoint, color, font } from "../../../../../theme";
import lotteryImg from "../../../../../assets/images/lottery_img.png";

export const Link = styled.a`
  color: ${color.link};
  font-family: ${font.family.secondary};
  font-weight: 400;
  font-size: 2rem;
  text-decoration: none;
  outline: none;
  :hover {
    text-decoration: underline;
  }
`;

export const Body = styled.div`
  color: ${color.grey};
  font-size: 2rem;
  margin: 1.5rem 0;
  font-family: ${font.family.secondary};
  flex: 1;
  min-height: 0;
  min-width: 0;
  position: relative;
  :after,
  :before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 2rem;
    width: 100%;
  }
  /* :after {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 74%
    );
    bottom: 0;
    top: auto;
  }
  :before {
    top: 0;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 74%
    );
  } */
`;

export const BodyTitle = styled.div`
  margin-top: 3rem;
  font-size: 4rem;
  color: ${color.grey};
  font-family: ${font.family.primary};
`;

export const PartnerLogo = styled.img<{
  loaded: boolean;
}>`
  height: 4rem;
  opacity: ${(p) => (p.loaded ? "1" : "0")};
  visibility: ${(p) => (p.loaded ? "visible" : "hidden")};
`;

export const PartnerFullImage = styled.img<{
  loaded: boolean;
}>`
  background: #eee;
  display: block;
  width: calc(100% + 8rem);
  margin: ${(p) => (p.loaded ? "3rem" : 0)} -4rem 0;
  opacity: ${(p) => (p.loaded ? "1" : "0")};
  visibility: ${(p) => (p.loaded ? "visible" : "hidden")};
  height: ${(p) => (p.loaded ? "auto" : "0")};
  @media (min-width: ${breakpoint.desktop}) {
    width: calc(100% + 12rem);
    margin: ${(p) => (p.loaded ? "3rem" : 0)} -6rem 0;
  }
`;

export const Tabs = styled.div`
  display: flex;
  margin-top: 3rem;
`;

export const Tab = styled.div`
  cursor: pointer;
  padding: 0.5em 0 0.45em;
  text-align: center;
  font-size: 2rem;
  flex-grow: 1;
  color: ${color.grey};
  border: 0.2rem solid ${color.liteGrey};
  border-bottom-left-radius: 0.8em;
  border-top-left-radius: 0.8em;

  :last-child {
    border-radius: 0 0.8em 0.8em 0;
  }
  &.active {
    background: ${color.link};
    color: #fff;
    border-color: ${color.link};
  }
`;

export const LotteryImg = styled.div`
  background: url(${lotteryImg}) center center no-repeat;
  width: 131%;
  height: 200px;
  background-size: cover;
  margin-left: -50px;
  margin-right: -50px;
  margin-top: 20px;
`;
