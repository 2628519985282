import styled from 'styled-components';
import {border, color} from '../../../theme';

export const InfoBlockContainer = styled.div`
  border: 0.1rem solid ${color.orange};
  border-radius: ${border.radius};
  font-size: 1.5rem;
  color: ${color.orange};
  padding: 1em;
  display: flex;
  align-items: flex-start;
  margin: 1em 0;
  img {
    margin-right: 1em;
  }
`;
