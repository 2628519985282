import React, { useEffect, useState } from "react";
import { useGate, useStore } from "effector-react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { Dialog } from "../../../ui-kit/Dialog";
import {
  CloseDialogIcon,
  DialogHeaderTitle,
} from "../../../ui-kit/Dialog/styled";
import { logout, sessionUser$ } from "../../../../models/auth";
import {
  UserInfoModalHeader,
  UserInfoModalPhone,
  ModalFooter,
  ModalFooterLink,
  ModalFooterLinkIcon,
  ModalFooterLinkTtl,
} from "./styled";
import { ReactComponent as CloseIcon } from "../../../../assets/svg/ic-close.svg";
import { ReactComponent as SupIcon } from "../../../../assets/svg/ic-support.svg";
import { formatPhone } from "../../../../utils/string";
import { InputEditable } from "../../../ui-kit/InputEditable";
import { Icon } from "../../../ui-kit/Icon";
import { Button } from "../../../ui-kit/Button";
import {
  AddEmailGate,
  attachedEmail$,
  attachEmail,
  attachEmailFx,
  detachEmailDialogOpen,
  fetchIdentifiersFx,
  ProfileGate,
  profileState$,
  counts$,
} from "../../../../models/settings/profile";
import { SpinnerContainer } from "../../../pages/Receipt/components/ReceiptTable/ReceiptDetails/styled";
import { Spinner } from "../../../ui-kit/Spinner";
import { Identifier } from "../../../../types/profile";
import { DeleteEmailDialog } from "../DeleteEmailDialog";
import { ExitDialog } from "../ExitDialog";
import { BlockUser, deletePendingEmail } from "../../../../api";
import { BlockAccountDialog } from "../BlockAccountDialog";
import { ReceiptsHistoryDialog } from "../../App/ReceiptsHistoryDialog";
import { refreshTable } from "../../../../models/receipt";
import { PhoneDialog } from "../PhoneDialog";
import { ReactComponent as AttentionIcon } from "assets/svg/attention.svg";
import { useTranslation } from "react-i18next";

interface Props {
  onDismiss: Function;
}

export function UserInfoModal({ onDismiss }: Props) {
  const { t } = useTranslation();
  const user = useStore(sessionUser$);
  const counts = useStore(counts$);
  const attachedEmail = useStore(attachedEmail$);
  useGate(AddEmailGate);
  useGate(ProfileGate);
  const { emails, phones, loading, isDetachEmailDialogOpen } =
    useStore(profileState$);
  const [isExitModalShow, setIsExitModalShow] = useState(false);
  const [isBlockAccountDialogShow, setIsBlockAccountDialogShow] =
    useState(false);
  const [firsLoad, setFirsLoad] = useState(true);
  const [emailForHistory, setEmailForHistory] = useState("");
  const [phoneForHistory, setPhoneForHistory] = useState("");
  const pending = useStore(attachEmailFx.pending);

  useEffect(setFirstLoadHandle, []);

  function setEmailWithValidate(e: string) {
    const re = /\S+@\S+\.\S+/;
    if (re.test(e)) {
      setEmailForHistory(e);
    } else {
      toast.error("Введите корректную электронную почту.");
    }
  }

  function setPhoneWithValidate(e: string) {
    if (e.replace(/\D/g, "").length === 11) {
      setPhoneForHistory(e);
    } else {
      toast.error("Введите корректный номер телефона.");
    }
  }

  function setFirstLoadHandle() {
    setTimeout(() => {
      if (!loading) setFirsLoad(false);
      else setFirstLoadHandle();
    }, 500);
  }

  function saveUserParams(e: {
    showPeriod: "all" | "fromNow";
    identifierParams: { type: "MAIL"; login: string };
  }) {
    if (e.showPeriod === "fromNow") moment().format("YYYY-MM-DD");
    attachEmail({
      email: e.identifierParams.login,
      receiptExpirationDate:
        e.showPeriod === "fromNow" ? moment().format("YYYY-MM-DD") : null,
      extraMeta: null,
    });
  }

  function logoutAction() {
    logout(false);
    onDismiss();
  }

  function onApprove({ login }: Identifier) {
    try {
      attachEmailFx({
        email: login,
        receiptExpirationDate: null,
        extraMeta: null,
      }).then(() => {
        toast.success(`Письмо с подтверждением выслано на почту ${login}`);
      });
    } catch (e) {
      toast.error("Ошибка при отправке сообщения");
    }
  }

  function onDelete(email: Identifier) {
    detachEmailDialogOpen({
      identifierLogin: email.login,
      identifierId: email.id,
      identifierType: email.type,
    });
  }

  function onDeletePhone(phone: Identifier) {
    detachEmailDialogOpen({
      identifierLogin: phone.login,
      identifierId: phone.id,
      identifierType: phone.type,
    });
  }

  async function onDeletePending(email: Identifier) {
    await deletePendingEmail({
      identifierLogin: email.login,
      identifierId: email.id,
      identifierType: email.type,
    });
    await fetchIdentifiersFx();
  }

  async function BlockAction() {
    try {
      await BlockUser();
      logout(false);
    } catch (e) {
      console.error("BlockAction fault");
    }
  }

  useEffect(() => {
    if (attachedEmail) {
      const re = /\S+@\S+\.\S+/;
      if (re.test(attachedEmail.email)) {
        attachEmailFx({
          email: attachedEmail.email.trim(),
          receiptExpirationDate: attachedEmail.receiptExpirationDate,
          extraMeta: null,
        }).then(() => {
          toast.success(
            `Письмо с подтверждением выслано на почту ${attachedEmail.email}`
          );
          attachEmail(null);
        });
        refreshTable();
        setEmailForHistory("");
      } else {
        toast.error("Введите корректную электронную почту.");
      }
    }
  }, [attachedEmail]);

  return isDetachEmailDialogOpen ? (
    <DeleteEmailDialog />
  ) : isExitModalShow ? (
    <ExitDialog
      onSubmit={logoutAction}
      onDismiss={() => setIsExitModalShow(false)}
    />
  ) : isBlockAccountDialogShow ? (
    <BlockAccountDialog
      onSubmit={BlockAction}
      onDismiss={() => setIsBlockAccountDialogShow(false)}
    />
  ) : emailForHistory ? (
    <ReceiptsHistoryDialog
      onSubmit={saveUserParams}
      onDismiss={() => setEmailForHistory("")}
      identifier={{
        type: "MAIL",
        login: emailForHistory,
      }}
      pending={pending}
    />
  ) : phoneForHistory ? (
    <PhoneDialog
      onDismiss={() => {
        setPhoneForHistory("");
      }}
      phone={phoneForHistory}
    />
  ) : (
    <Dialog onDismiss={onDismiss} ariaLabel="user-info-modal">
      {loading && firsLoad ? (
        <SpinnerContainer>
          <Spinner radius="20px" />
        </SpinnerContainer>
      ) : (
        <>
          <UserInfoModalHeader>
            <DialogHeaderTitle>{t("Профиль")}</DialogHeaderTitle>
            <CloseDialogIcon source={CloseIcon} onClick={() => onDismiss()} />
          </UserInfoModalHeader>
          {counts?.isBlocked && (
            <div className="text-red-600 pt-[30px] text-[16px] leading-[20px]">
              <AttentionIcon className="mb-4" />
              Доступ заблокирован, режим просмотра. Если Вы не нарушали правила
              сервиса, пожалуйста, напишите в поддержку
            </div>
          )}
          <UserInfoModalPhone>
            {user && formatPhone(phones?.[0].login)}
          </UserInfoModalPhone>
          {emails.map((email, i) => (
            <InputEditable
              withStatus
              editable={false}
              value={email.login}
              key={i}
              needApprove={!email.confirmed}
              onApprove={() => onApprove(email)}
              onDelete={() => onDelete(email)}
              onSave={(e: string) => setEmailWithValidate(e)}
              onDeletePending={() => onDeletePending(email)}
            />
          ))}
          {phones.slice(1, phones.length).map((phone, i) => (
            <InputEditable
              withStatus
              editable={false}
              value={formatPhone(phone.login)}
              key={i}
              needApprove={!phone.confirmed}
              onApprove={() => onApprove(phone)}
              onDelete={() => onDeletePhone(phone)}
              onSave={(e: string) => setPhoneWithValidate(e)}
              onDeletePending={() => onDeletePending(phone)}
            />
          ))}
          {!counts?.isBlocked && (
            <>
              <InputEditable
                onSave={(e: string) => setPhoneWithValidate(e)}
                value={""}
                placeholder={t("Добавьте номер телефона")}
                type="phone"
                mask="+{7} (000) 000 00 00"
              />
              <InputEditable
                onSave={(e: string) => setEmailWithValidate(e)}
                value={""}
                placeholder={t("Добавьте email адрес")}
                type="email"
              />
            </>
          )}
          <div>
            <div
              style={{
                fontWeight: 400,
                fontSize: 12,
                lineHeight: "14px",
                marginTop: 20,
              }}
            >
              {t("Личный счет")}
            </div>
            <div>
              <Link
                style={{
                  fontWeight: 700,
                  fontSize: 32,
                  lineHeight: "38px",
                  color: "#00b956",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                onClick={() => {
                  onDismiss();
                }}
                to="/payments"
              >
                {counts?.balance
                  ? Math.floor(counts.balance / 100).toLocaleString("ru-RU", {
                      maximumFractionDigits: 0,
                    })
                  : "0"}{" "}
              </Link>
            </div>
          </div>

          <ModalFooter>
            <ModalFooterLink
              href="https://www.nalog.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ModalFooterLinkIcon>
                <Icon source={SupIcon} />
              </ModalFooterLinkIcon>
              <ModalFooterLinkTtl>
                <span>{t("Служба")}</span>
                <span>{t("Технической поддержки")} </span>
              </ModalFooterLinkTtl>
            </ModalFooterLink>
            <ModalFooterLink href="phone:88002222222" rel="noopener noreferrer">
              <ModalFooterLinkIcon>
                <Icon source={SupIcon} />
              </ModalFooterLinkIcon>
              <ModalFooterLinkTtl>
                <span>{t("Контакт центр")}</span>
                <span>8 (800) 222-22-22</span>
              </ModalFooterLinkTtl>
            </ModalFooterLink>
            <Button
              style={{ marginTop: "4rem" }}
              onClick={() => setIsExitModalShow(true)}
            >
              {t("Выйти из профиля")}
            </Button>
            <Button
              style={{ marginTop: "2rem" }}
              onClick={() => setIsBlockAccountDialogShow(true)}
              theme={"text"}
            >
              {t("Заблокировать профиль")}
            </Button>
          </ModalFooter>
        </>
      )}
    </Dialog>
  );
}
