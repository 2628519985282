import React from "react";
import Balance from "components/pages/Dashboard/components/Balance";
import History from "components/pages/Dashboard/components/History";
import Lotery from "components/pages/Dashboard/components/Lotery";
import { Receipt } from "../Receipt";
import { useTranslation } from "react-i18next";

function Dashboard() {
  const { t } = useTranslation();

  return (
    <div className="w-full pb-8">
      <div className="w-full md:flex gap-8">
        <div className="w-full md:w-1/3 mh-[420px] pb-8 md:pb-0">
          <Balance />
        </div>
        <div className="w-full py-10 md:w-2/3 mh-[420px] rounded-3xl bg-white">
          <div className="text-[24px] pl-[6rem] mb-[3rem]">
            {t("Последние чеки")}
          </div>
          <Receipt />
        </div>
      </div>
      {/*<Lotery />*/}
    </div>
  );
}

export { Dashboard };
