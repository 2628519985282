import styled from 'styled-components';
import {color, font} from '../../../theme';
import closeIcon from '../../../assets/svg/ic-close-cam.svg';

export const PageContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  section {
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 5;
  }
`;

export const PageTitle = styled.div`
  position: absolute;
  top: 10rem;
  left: 4rem;
  color: #fff;
  font-size: 4.6rem;
  font-family: ${font.family.secondary};
  font-weight: 500;
  z-index: 6;
`;

export const PageFooter = styled.div`
  position: absolute;
  bottom: 12rem;
  left: 0;
  width: 100%;
  z-index: 6;
`;

export const PageFooterClose = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  margin: 0 auto 2rem;
  background: url(${closeIcon}) center center no-repeat, #731982;
  background-size: 2.5rem auto;
`;

export const PageInfo = styled.p`
  font-size: 2rem;
`;

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SuccessContainerTitle = styled.h2`
  width: 100%;
  font-size: 6rem;
  text-align: center;
  font-weight: 500;
  color: ${color.grey};
  margin-bottom: 0.5em;
`;

export const SuccessContainerErrorTitle = styled(SuccessContainerTitle)`
  color: ${color.danger};
`;

export const ScannerTarget = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 6;
`;

export const SpinnerOuter = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`;
