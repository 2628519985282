import React from 'react';
import {Dialog} from '../../../ui-kit/Dialog';
import {
  CloseDialogIcon,
  DialogHeaderTitle,
  DialogMessage,
  DialogButtonsRow,
} from '../../../ui-kit/Dialog/styled';
import {ReactComponent as CloseIcon} from '../../../../assets/svg/ic-close.svg';
import {UserInfoModalHeader} from '../UserInfoModal/styled';
import {Button} from '../../../ui-kit/Button';
import { useTranslation } from "react-i18next";

interface Props {
  onSubmit: Function;
  onDismiss: Function;
}

export function BlockAccountDialog({onSubmit, onDismiss}: Props) {
  const { t } = useTranslation();
  return (
    <Dialog onDismiss={() => onDismiss()} ariaLabel="exit" center>
      <UserInfoModalHeader>
        <DialogHeaderTitle>{t("Блокировка профиля")}</DialogHeaderTitle>
        <CloseDialogIcon source={CloseIcon} onClick={() => onDismiss()} />
      </UserInfoModalHeader>
      <DialogMessage>
        {t("Вы собираетесь заблокировать свой профиль. При блокировке от него будут отвязаны все дополнительно привязанные телефоны и email-адреса.")}
        <br />
        <br />
        {t("В случае последующей разблокировки, в ЛК будут присутствовать только новые данные по чекам (начиная с момента разблокировки). Разблокировка производится только через обращение в Службу технической поддержки.")}
      </DialogMessage>
      <DialogButtonsRow>
        <Button onClick={() => onSubmit()} actionType="attention">
          {t("Заблокировать")}
        </Button>
        <Button onClick={() => onDismiss()} theme="outline">
          {t("Отмена")}
        </Button>
      </DialogButtonsRow>
    </Dialog>
  );
}
