import {
  combine,
  createEffect,
  createEvent,
  createStore,
  merge,
} from "effector";
import { createGate } from "effector-react";
import { DateRange } from "../../components/ui-kit/DateRangePicker";
import {
  Receipt,
  ReceiptDetailsResponse,
  ReceiptRequest,
  ReceiptResponse,
  ReceiptSorting,
} from "../../types/receipt";
import { RestError } from "../../core/errors";

export const FiltersGate = createGate();
export const TableGate = createGate();
export const ReceiptDetailsGate = createGate();

export const fetchReceiptsFx = createEffect<
  ReceiptRequest,
  ReceiptResponse,
  RestError
>();
export const fetchMoreReceiptsFx = createEffect<
  ReceiptRequest,
  ReceiptResponse,
  RestError
>();
export const fetchReceiptDetailsFx = createEffect<
  string,
  ReceiptDetailsResponse
>();

export const periodChanged = createEvent<DateRange>();
export const innChanged = createEvent<string>();
export const queryChanged = createEvent<string>();
export const debouncedInnChanged = createEvent<string>();
export const debouncedQueryChanged = createEvent<string>();
export const orderByChanged = createEvent<ReceiptSorting>();
export const fetchMore = createEvent();
export const refreshTable = createEvent();
export const receiptSelected = createEvent<{ key: string; select?: boolean }>();
export const clearSelectedReceipts = createEvent();
export const receiptForModalSelected = createEvent<string>();
export const receiptDetailsDialogOpen = createEvent();
export const receiptDetailsDialogClose = createEvent();
export const filtersChanged = merge([
  periodChanged,
  debouncedInnChanged,
  debouncedQueryChanged,
  orderByChanged,
]);
export const tableStateChanged = merge([orderByChanged, filtersChanged]);

export const dateFrom$ = createStore<Date | null>(null);
export const dateTo$ = createStore<Date | null>(null);
export const inn$ = createStore<string | null>(null);
export const query$ = createStore<string | null>("");
export const orderBy$ = createStore<ReceiptSorting>(
  ReceiptSorting.CREATED_DATE_DESC
);
export const data$ = createStore<ReceiptResponse>({
  items: [],
  brands: [],
  lastPage: true,
});
export const isReceiptDetailsDialog$ = createStore<boolean>(false);
export const receiptDetails$ = createStore<ReceiptDetailsResponse | null>(null);
export const receiptsSelected$ = createStore<string[]>([]);
export const receiptsExpirationDateError$ = createStore<RestError | null>(null);

export const filters$ = combine<{
  inn: string | null;
  query: string | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  orderBy: ReceiptSorting;
  data: ReceiptResponse;
}>({
  inn: inn$,
  query: query$,
  dateFrom: dateFrom$,
  dateTo: dateTo$,
  orderBy: orderBy$,
  data: data$,
});

export const tableState$ = combine<{
  data: Receipt[];
  loading: boolean;
  lastPage: boolean;
  isReceiptDetailsDialog: boolean;
  error: RestError | null;
}>({
  data: data$.map((d) => d.items),
  loading: fetchReceiptsFx.pending,
  lastPage: data$.map((d) => d.lastPage),
  isReceiptDetailsDialog: isReceiptDetailsDialog$,
  error: receiptsExpirationDateError$,
});
