import React, { FormEvent, useState, useEffect } from "react";
import { useStore } from "effector-react";
import { Input } from "../../../ui-kit/Input";
import { Button } from "../../../ui-kit/Button";
import {
  ButtonContainer,
  InputWithButton,
  RulesInfo,
  StoresIconsContainer,
} from "./styled";
import {
  authPhoneSelected,
  loadSmsChallengeTokenDataFx,
  smsChallengeTokenError$,
  authCapchaSelected,
  authCapcha$,
} from "../../../../models/auth";
import { Hint } from "../../../ui-kit/Hint";
import { Checkbox } from "../../../ui-kit/Checkbox";
import IosIcon from "../../../../assets/svg/ic-ios-store.svg";
import AndIcon from "../../../../assets/svg/ic-and-store.svg";
import { isMobile } from "../../../../utils/isMobile";
import { useTranslation } from "react-i18next";

interface Props {
  phone: string | null;
}

export function PhoneForm({ phone: initialPhone }: Props) {
  const { t } = useTranslation();
  const [phone, setPhone] = useState(initialPhone || "");
  const [accepted, setAccepted] = useState(true);
  // const authCapcha = useStore(authCapcha$);
  const pending = useStore(loadSmsChallengeTokenDataFx.pending);
  const error = useStore(smsChallengeTokenError$);
  const [errorAccepted, setErrorAccepted] = useState<null | string>(null);

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    if (!accepted) {
      setErrorAccepted(
        "Для отпраки СМС кода необходимо согласиться с условиями обработки данных"
      );
      return;
    }
    // if (!authCapcha) return;
    authPhoneSelected(phone);
  }

  return (
    <form onSubmit={onSubmit}>
      <InputWithButton>
        <Hint text={error || errorAccepted}>
          <Input
            type="tel"
            value={phone}
            mask="+{7} (000) 000 00 00"
            placeholderChar="✕"
            unmask={true}
            lazy={false}
            onAccept={setPhone}
            disabled={pending}
            hasError={error !== null}
          />
        </Hint>
        <ButtonContainer>
          <Button
            type="submit"
            disabled={phone.length < 11 || !accepted}
            isLoading={pending}
            className="login-btn"
          >
            {"Send code"}
          </Button>
        </ButtonContainer>
      </InputWithButton>
      <div>
        {/* <Checkbox
          checked={accepted}
          label="Я согласен"
          onChange={() => {
            setAccepted(!accepted);
            setErrorAccepted(null);
          }}
          style={{margin: '5rem 0 3rem'}}
        /> */}
        {/* <RulesInfo>
          Для использования сервиса в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О
          персональных данных» требуется получение Вашего согласия на обработку персональных данных
        </RulesInfo> */}
      </div>
      {isMobile() && (
        <StoresIconsContainer>
          <a
            href="https://apps.apple.com/ru/app/%D0%BC%D0%BE%D0%B8-%D1%87%D0%B5%D0%BA%D0%B8-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id1550441308"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={IosIcon} alt="ios-store-icon" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.electro_tickets.app&hl=ru&gl=ru"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={AndIcon} alt="android-store-icon" />
          </a>
        </StoresIconsContainer>
      )}
    </form>
  );
}
