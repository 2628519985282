/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "../../ui-kit/Button";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { SpinnerContainer } from "../Receipt/components/ReceiptTable/ReceiptDetails/styled";
import { Spinner } from "../../ui-kit/Spinner";
import { NoData } from "../Receipt/components/TableList/styled";
import { AffiliateModal } from "./AffiliateModal";
import { AffiliateRequestParams, IAffiliate } from "../../../types/affiliate";
import {
  bindUserToPartner,
  fetchAffiliates,
  fetchSingleAffiliate,
  unbindUserFromPartner,
} from "../../../api/affiliates";

type AffiliatesProps = {
  userBind: boolean | undefined;
};

export function Affiliates({ userBind }: AffiliatesProps) {
  const { t } = useTranslation();
  const [affiliates, setAffiliates] = useState<IAffiliate[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [pending, setPending] = useState(true);
  const [requestParams, setRequestParams] = useState<
    Partial<AffiliateRequestParams>
  >({ page: 1, userBind });
  const [affiliate, setAffiliate] = useState<IAffiliate | null>(null);
  const [showModal, setShowModal] = useState(false);

  async function getList(resetList = false) {
    try {
      setPending(true);
      const response = await fetchAffiliates(requestParams);

      setAffiliates(
        resetList ? response.data : affiliates.concat(response.data)
      );
      setHasMore(response.hasNext);
      setPending(false);
    } catch (e) {
      console.error(e);
    }
  }

  async function getAffiliate(id: string | number) {
    try {
      setPending(true);
      const response = await fetchSingleAffiliate(id);
      setAffiliate(response);
      setPending(false);
    } catch (e) {
      console.error(e);
    }
  }

  async function onChangeBind() {
    if (!affiliate) return;
    setPending(true);
    try {
      affiliate.userBind
        ? await unbindUserFromPartner(affiliate.id)
        : await bindUserToPartner(affiliate.id);

      await getAffiliate(affiliate.id);
    } catch (e) {
      console.error(e);
    } finally {
      setPending(false);
    }
  }

  function onChangeRequestParams<
    T extends keyof typeof requestParams,
    K extends typeof requestParams[T]
  >({ param, value }: { param: T; value: K | K[] }) {
    if (!value)
      return setRequestParams((params) => {
        delete params[param];
        return { ...params };
      });
    setRequestParams((params) => ({
      ...params,
      [param]: value,
    }));
  }

  async function onItemClick(id: string | number) {
    try {
      await getAffiliate(id);
      setShowModal(true);
    } catch (e) {
      console.error(e);
    }
  }

  const showMore = () => {
    onChangeRequestParams({
      param: "page",
      value: (requestParams.page || 0) + 1,
    });
    getList();
  };

  const handleLanguageChanged = useCallback(() => {
    getList();
  }, []);

  useEffect(() => {
    getList(true);
  }, [requestParams.page, requestParams.userBind]);

  useEffect(() => {
    i18n.on("languageChanged", handleLanguageChanged);

    return () => {
      i18n.off("languageChanged", handleLanguageChanged);
    };
  }, [handleLanguageChanged]);

  return (
    <div className="flex gap-[16px] flex-wrap">
      {affiliates.map((_affiliate, i) => (
        <AffiliateItem
          key={_affiliate.id}
          _affiliate={_affiliate}
          pending={pending}
          onItemClick={onItemClick}
        />
      ))}
      {hasMore && (
        <Button style={{ margin: "3rem 0 5rem 0" }} onClick={showMore}>
          {t("Показать еще")}
        </Button>
      )}
      <SpinnerContainer className="w-full">
        {pending && <Spinner radius="20px" />}
      </SpinnerContainer>
      {affiliates.length === 0 && !pending && (
        <NoData>{t("Список партнеров пуст")}</NoData>
      )}
      {showModal && affiliate && (
        <AffiliateModal
          affiliate={affiliate}
          pending={pending}
          onChangeBind={async () => {
            await onChangeBind();
            await getList(true);
          }}
          onDismiss={() => setShowModal(false)}
        />
      )}
    </div>
  );
}

type AffiliateItemProps = {
  _affiliate: IAffiliate;
  pending: boolean;
  onItemClick: Function;
};
const AffiliateItem: React.FC<AffiliateItemProps> = ({
  _affiliate,
  pending,
  onItemClick,
}) => {
  return (
    <div
      className={`w-[192px] h-[192px] flex flex-col items-center justify-center gap-[40px] bg-white rounded-3xl px-[24px] py-[40px] cursor-pointer ${
        pending && "pointer-events-none"
      }`}
      onClick={() => onItemClick(_affiliate.id)}
    >
      <img src={`data:image/png;base64, ${_affiliate.titlePhoto}`} alt="" />
      <span className="font-['Fira_Sans'] text-[12px] font-medium text-[#687591] text-center">
        {_affiliate?.titleText}
      </span>
    </div>
  );
};
