import React, { useState } from "react";
import { Dialog } from "../../../ui-kit/Dialog";
import { ReactComponent as CloseIcon } from "../../../../assets/svg/ic-close.svg";
import {
  CloseDialogIcon,
  DialogHeaderTitle,
} from "../../../ui-kit/Dialog/styled";
import { Header } from "./styled";
import { useTranslation } from "react-i18next";
import { Select, SelectType } from "../../../ui-kit/Select";
import { Input } from "../../../ui-kit/Input";
import { Button } from "../../../ui-kit/Button";

interface Props {
  onSubmit: Function;
  onDismiss: Function;
}

export function NewAppealModal({ onSubmit, onDismiss }: Props) {
  const { t } = useTranslation();
  const [url, setUrl] = useState("");
  const [violationType, setViolationType] = useState<SelectType>({
    label: t("Невыдача чека"),
    value: "Failure to issue cash register receipt",
  });

  const violationTypes = React.useMemo(() => {
    return [
      {
        label: t("Невыдача чека"),
        value: "Failure to issue cash register receipt",
      },
    ];
  }, [t]);

  return (
    <Dialog onDismiss={onDismiss} ariaLabel="notification-modal">
      <Header>
        <DialogHeaderTitle>{t("Новое обращение")}</DialogHeaderTitle>
        <CloseDialogIcon source={CloseIcon} onClick={() => onDismiss()} />
      </Header>
      <div className="flex flex-col gap-10">
        <Select
          placeholder={t("Тип нарушения")}
          value={violationType?.label}
          values={violationTypes}
          onSelectItem={(item) => setViolationType(item)}
        />
        <Input
          value={url}
          onChange={(u) => setUrl(u.target.value)}
          placeholder={t("Веб-сайт")}
        />
        <Button
          type="submit"
          style={{ width: "inherit" }}
          onClick={() =>
            onSubmit({
              text: violationType.value,
              url,
              acceptType: "URL",
            })
          }
        >
          {t("Отправить")}
        </Button>
      </div>
    </Dialog>
  );
}
