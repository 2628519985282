import { add, differenceInDays, format, isBefore, parseISO } from "date-fns";

export function formatDate(date: Date, dateFormat = "dd.MM.yyyy") {
  return format(date, dateFormat);
}

export function addDaysToDate(date: string, days: number) {
  const originalDate = parseISO(date);
  return add(originalDate, { days });
}

export function daysDifferenceWithToday(date: string, days: number) {
  const newDate = addDaysToDate(date, days);
  return differenceInDays(newDate, new Date());
}

export function isBeforeDate(targetDate: string) {
  const compareDate = parseISO(targetDate);
  return isBefore(compareDate, new Date());
}
