import { forward, guard, merge, sample } from "effector";

import {
  fetchIncomeFx,
  fetchMoreIncomeFx,
  IncomeError$,
  data$,
  tableState$,
  fetchMore,
  refreshTable,
  TableGate,
} from "./index";
import { fetchIncome } from "../../api";

fetchIncomeFx.use(fetchIncome);
fetchMoreIncomeFx.use(fetchIncome);

data$
  .on(fetchIncomeFx.doneData, (state, source) => source)
  .on(fetchMoreIncomeFx.doneData, (state, source) => ({
    items: [...state.items, ...source.items],
    lastPage: source.lastPage,
    total: source.total,
  }));

sample({
  clock: merge([TableGate.open]),
  fn: () => ({
    page: 1,
  }),
  target: fetchIncomeFx,
});

sample({
  clock: merge([fetchMore]),
  source: data$,
  fn: (data) => ({
    page: data.items.length / 10 + 1,
  }),
  target: fetchMoreIncomeFx,
});
