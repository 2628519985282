import React from "react";
import { useStore, useGate } from "effector-react";
import { formatDate } from "../../../../utils/date";
import { Table } from "../../../ui-kit/Table";
import { tableState$, fetchMore, TableGate } from "../../../../models/income";
import { PartnerIconCell } from "../../Receipt/components/ReceiptTable/Cells/PartnerIconCell";
import { useTranslation } from "react-i18next";

function Income() {
  const { t } = useTranslation();
  useGate(TableGate);
  const { data, lastPage, loading } = useStore(tableState$);
  const columns = [
    {
      Header: t("Дата"),
      accessor: "date",
      minWidth: 130,
      width: 130,
      Cell: ({ value }: { value: string }) => (
        <span style={{ color: "#687591" }}>
          {formatDate(new Date(value as string), "dd.MM.yyyy HH:mm")}
        </span>
      ),
    },
    {
      accessor: "brandImage",
      minWidth: 40,
      width: 40,
      Cell: (row: any) => {
        let value = "none";
        if (row?.row?.original?.tin === "00006663000486")
          value = "/images/markets/logo_dom.png";
        if (row?.row?.original?.tin === "12345678912435")
          value = "/images/markets/logo_vet.jpeg";
        if (row?.row?.original?.tin === "")
          value = "/images/markets/lotery-default.png";

        return <PartnerIconCell value={value} />;
      },
    },
    {
      Header: t("Продавец"),
      accessor: "seller",
      minWidth: 240,
      width: 240,
    },
    {
      Header: t("ИНН продавца"),
      minWidth: 100,
      accessor: "tin",
      Cell: ({ value }: { value: string }) => (
        <span style={{ color: "#687591" }}>{value || ""}</span>
      ),
    },
    {
      Header: t("Стоимость"),
      accessor: "receiptSum",
      minWidth: 120,
      width: 120,
      Cell: ({ value }: { value: number }) => (
        <span>
          {value
            ? Math.floor(value / 100).toLocaleString("ru-RU", {
                maximumFractionDigits: 0,
              })
            : "-"}
        </span>
      ),
    },
    {
      Header: t("Вычет"),
      accessor: "amount",
      minWidth: 120,
      width: 120,
      Cell: ({ value }: { value: number }) => (
        <span>
          {value
            ? Math.floor(value / 100).toLocaleString("ru-RU", {
                maximumFractionDigits: 0,
              })
            : "0.00"}
        </span>
      ),
    },
  ];
  return (
    <div style={{ marginBottom: 30 }}>
      <Table
        data={data}
        //@ts-ignore
        columns={columns}
        loading={loading}
        hasMore={!lastPage && data.length > 0 && !loading}
        onLoadMore={() => fetchMore()}
      />
    </div>
  );
}

export { Income };
