import '../polyfills/nanoid-polyfill.js';
import {nanoid} from 'nanoid';

export const deviceInfo = createAppMetaInfo();

function createAppMetaInfo() {
  let sourceDeviceId = localStorage.getItem('sourceDeviceId');

  if (!sourceDeviceId) {
    sourceDeviceId = nanoid();
    localStorage.setItem('sourceDeviceId', sourceDeviceId);
  }

  return {
    sourceDeviceId,
    sourceType: 'WEB',
    appVersion: '1.0.0',
    metaDetails: {
      userAgent: navigator.userAgent,
    },
  };
}
