import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../../../../assets/svg/ic-close.svg";
import { Dialog } from "../../../../ui-kit/Dialog";
import {
  UserInfoModalContent,
  UserInfoModalHeader,
} from "../../../../common/Header/UserInfoModal/styled";
import {
  CloseDialogIcon,
  DialogHeaderTitle,
} from "../../../../ui-kit/Dialog/styled";
import { Button } from "../../../../ui-kit/Button";
import { $appealsFilterStore, $appealsFilterStoreApi } from "../../index";
import { useStore } from "effector-react";
import { Select, SelectType } from "../../../../ui-kit/Select";
import { AppealStatusTypeMapping } from "../../../../../types/appeal";

interface Props {
  onSubmit: Function;
  onDismiss: Function;
}

export function AppealsFilters({ onSubmit, onDismiss }: Props) {
  // @ts-ignore
  const { t } = useTranslation();

  const { filterStatus: status } = useStore($appealsFilterStore);
  const { setStatus } = $appealsFilterStoreApi;

  function onChange(item: SelectType) {
    setStatus(item);
  }

  function clearFilters() {
    onSubmit("status", undefined);
    setStatus(undefined);
  }

  function startSearch() {
    onSubmit("status", status?.value);
  }

  const statuses = React.useMemo(() => {
    const values = Object.keys(AppealStatusTypeMapping).map(
      (key): SelectType => ({
        label: t(key),
        value:
          key !== "CLOSED" ? key : ["CLOSED", "SENT_PENALTY", "SENT_WARNING"],
      })
    );

    values.unshift({ label: t("Все"), value: undefined });

    return values;
  }, [t]);

  return (
    <Dialog
      onDismiss={() => onDismiss()}
      ariaLabel="exit"
      style={{ overflow: "visible" }}
    >
      <UserInfoModalHeader>
        <DialogHeaderTitle>{t("Фильтры")}</DialogHeaderTitle>
        <CloseDialogIcon source={CloseIcon} onClick={() => onDismiss()} />
      </UserInfoModalHeader>
      <UserInfoModalContent>
        <Select
          placeholder={t("Статус")}
          value={status?.label}
          values={statuses}
          onSelectItem={(item) => onChange(item)}
        />
      </UserInfoModalContent>
      <Button onClick={startSearch}>{t("Искать")}</Button>
      {
        <Button inline onClick={clearFilters} style={{ marginTop: "2rem" }}>
          {t("Сбросить")}
        </Button>
      }
    </Dialog>
  );
}
