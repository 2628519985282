export const OPERATION_TYPE: Record<number, string> = {
  1: 'Приход',
  2: 'Возврат прихода',
  3: 'Расход',
  4: 'Возврат расхода',
};

export const PAYMENT_TYPE: Record<number, string> = {
  1: 'ПРЕДОПЛАТА 100%',
  2: 'ПРЕДОПЛАТА',
  3: 'АВАНС',
  4: 'ПОЛНЫЙ РАСЧЕТ',
  5: 'ЧАСТИЧНЫЙ РАСЧЕТ И КРЕДИТ',
  6: 'ПЕРЕДАЧА В КРЕДИТ',
  7: 'ОПЛАТА КРЕДИТА',
};

export const FISCAL_DOC_FORMAT_VER: Record<number, string> = {
  1: '1.0',
  2: '1.05',
  3: '1.1',
};
