import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import {toast} from 'react-toastify';
import {config} from '../config';
import {TokenStorage} from './tokenStorage';
import {
  fetchAccessToken,
  LOGIN_ROUTE,
  REFRESH_TOKEN_ROUTE,
  SMS_CHALLENGE_TOKEN_ROUTE,
} from '../api';
import {AuthResponse} from '../types';
import {RestError, restError} from './errors';

const AUTH_TOKEN_NAME = 'Authorization';
let REFRESH_TOKEN_PROMISE: null | Promise<AuthResponse> = null;

export const apiClient = axios.create({baseURL: config.baseURL});

async function onErrorInterceptor(e: AxiosError): Promise<RestError> {
  const error = await restError(e);
  const {code, status} = error;
  const {url} = error.config;

  console.log({...error});

  if (
    status === 401 &&
    (code === 'Not Authenticated' ||
      code === 'authentication.failed' ||
      code === 'authentication.failed.expired.token')
  ) {
    try {
      if (!REFRESH_TOKEN_PROMISE) {
        TokenStorage.accessToken = null;
        REFRESH_TOKEN_PROMISE = fetchAccessToken(TokenStorage.refreshToken);
      }
      if (url === REFRESH_TOKEN_ROUTE || url === LOGIN_ROUTE) {
        REFRESH_TOKEN_PROMISE = null;
      } else {
        const authData = await REFRESH_TOKEN_PROMISE;
        TokenStorage.storeTokens(authData);
        REFRESH_TOKEN_PROMISE = null;

        return apiClient.request(error.config);
      }
    } catch (refreshTokenError) {
      REFRESH_TOKEN_PROMISE = null;
    }
  } else if (code === 'ECONNABORTED') {
    toast.error('Соединение прервано, пожалуйста обновите страницу', {
      autoClose: 20000,
      toastId: 'econnaborted',
      onClick: () => window.location.reload(),
    });
  } else if (status === 500) {
    toast.error('Ошибка при подключении. Повторите запрос или обновите страницу.', {
      autoClose: 20000,
      toastId: 'server-error',
    });
  }

  throw error;
}

async function onRequestInterceptor(clientConfig: AxiosRequestConfig) {
  const {url} = clientConfig;
  const {accessToken, accessTokenExpiresIn} = TokenStorage;

  const expirationDate = accessTokenExpiresIn ? new Date(accessTokenExpiresIn) : null;
  const now = new Date();

  if (url === SMS_CHALLENGE_TOKEN_ROUTE || url === REFRESH_TOKEN_ROUTE || url === LOGIN_ROUTE) {
    clientConfig.headers[AUTH_TOKEN_NAME] = undefined;
    return clientConfig;
  }

  if (expirationDate && expirationDate < now) {
    if (!REFRESH_TOKEN_PROMISE) {
      TokenStorage.accessToken = null;
      REFRESH_TOKEN_PROMISE = fetchAccessToken(TokenStorage.refreshToken);
    }
    try {
      const authData = await REFRESH_TOKEN_PROMISE;
      REFRESH_TOKEN_PROMISE = null;
      TokenStorage.storeTokens(authData);
      clientConfig.headers[AUTH_TOKEN_NAME] = `Bearer ${authData.accessToken}`;
    } catch (e) {
      REFRESH_TOKEN_PROMISE = null;
    }
    return clientConfig;
  }

  if (accessToken) {
    clientConfig.headers[AUTH_TOKEN_NAME] = `Bearer ${accessToken}`;
  }

  return clientConfig;
}

apiClient.interceptors.response.use(undefined, onErrorInterceptor);
apiClient.interceptors.request.use(onRequestInterceptor);
