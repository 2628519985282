import React from 'react';
import {useGate, useStore} from 'effector-react';
import {ReactComponent as CloseIcon} from '../../../../assets/svg/ic-close.svg';
import {
  DeleteEmailGate,
  detachedEmail$,
  detachEmailDialogClose,
  detachEmailFx,
  detachPhoneFx,
} from '../../../../models/settings/profile';
import {DetachedEmail} from '../../../../types/profile';
import {Dialog} from '../../../ui-kit/Dialog';
import {UserInfoModalHeader} from '../UserInfoModal/styled';
import {DialogHeaderTitle, CloseDialogIcon, DialogMessage} from '../../../ui-kit/Dialog/styled';
import {Button} from '../../../ui-kit/Button';
import { useTranslation } from "react-i18next";

export function DeleteEmailDialog() {
  const { t } = useTranslation();
  const detachedEmail = useStore(detachedEmail$);
  const loading = useStore(detachEmailFx.pending);
  const loadingPhone = useStore(detachPhoneFx.pending);
  useGate(DeleteEmailGate);
  return (
    <Dialog ariaLabel="email" onDismiss={() => detachEmailDialogClose()}>
      <UserInfoModalHeader>
        <DialogHeaderTitle>
          Удаление {detachedEmail?.identifierType === 'SMS' ? 'номера телефона' : 'E-mail'}
        </DialogHeaderTitle>
        <CloseDialogIcon source={CloseIcon} onClick={() => detachEmailDialogClose()} />
      </UserInfoModalHeader>
      <DialogMessage>
        Вы действительно хотите удалить{' '}
        {detachedEmail?.identifierType === 'SMS' ? 'номер телефона' : 'электронную почту'}{' '}
        {detachedEmail?.identifierLogin}?
      </DialogMessage>
      {detachedEmail?.identifierType !== 'SMS' && (
        <Button
          isLoading={loading}
          onClick={() => detachEmailFx(detachedEmail as DetachedEmail)}
          style={{marginBottom: '2rem'}}
        >
          {t("Удалить")}
        </Button>
      )}
      {detachedEmail?.identifierType === 'SMS' && (
        <Button
          isLoading={loadingPhone}
          onClick={() => detachPhoneFx(detachedEmail as DetachedEmail)}
          style={{marginBottom: '2rem'}}
        >
          {t("Удалить")}
        </Button>
      )}
      <Button theme={'outline'} onClick={() => detachEmailDialogClose()}>
        {t("Отмена")}
      </Button>
    </Dialog>
  );
}
