import React from "react";
import { Dialog } from "../../../ui-kit/Dialog";
import { ReactComponent as CloseIcon } from "../../../../assets/svg/ic-close.svg";
import {
  CloseDialogIcon,
  DialogHeaderTitle,
} from "../../../ui-kit/Dialog/styled";
import { Header, Message } from "./styled";
import { formatDate } from "../../../../utils/date";
import { IAppeal } from "../../../../types/appeal";
import { useTranslation } from "react-i18next";
import { BadgeContainer } from "../../../common/Content/styled";
import { convertBase64ImageToSrcFormat } from "../../../../utils/common";

interface Props {
  appeal: IAppeal;
  onDismiss: Function;
}

export function AppealModal({ appeal, onDismiss }: Props) {
  const {
    t,
    i18n: { exists },
  } = useTranslation();

  return (
    <Dialog onDismiss={onDismiss} ariaLabel="notification-modal">
      <Header>
        <DialogHeaderTitle>{t("Обращение")}</DialogHeaderTitle>
        <CloseDialogIcon source={CloseIcon} onClick={() => onDismiss()} />
      </Header>
      <BadgeContainer style={{ width: "fit-content" }} type={appeal.status}>
        {t(appeal.status)}
      </BadgeContainer>
      {appeal.address && (
        <Message fontSize="1.6rem">
          <span className="font-bold">{t("Адрес")}:</span> {appeal.address}
        </Message>
      )}
      {appeal?.photos?.slice(0, 1).map((photo, i) => (
        <img
          className="pb-8"
          src={convertBase64ImageToSrcFormat(photo)}
          alt=""
          key={i}
        />
      ))}
      {appeal.sellerName && (
        <Message fontSize="1.6rem">
          <span className="font-bold">{t("Наименование")}: </span>
          {appeal.sellerName}
        </Message>
      )}
      {appeal.tin && (
        <Message fontSize="1.6rem">
          <span className="font-bold">{t("ИИН")}: </span>
          {appeal.tin}
        </Message>
      )}
      {appeal.url && (
        <Message fontSize="1.6rem">
          <span className="font-bold">{t("URL")}: </span>
          {appeal.url}
        </Message>
      )}
      {appeal.createdDate && (
        <Message fontSize="1.6rem">
          <span className="font-bold">{t("Дата/Время")}: </span>
          {formatDate(new Date(appeal.createdDate), "dd.MM.yyyy HH:mm")}
        </Message>
      )}
      {appeal.appealText && (
        <Message fontSize="1.6rem">
          <span className="font-bold">{t("Текст обращения")}: </span>
          {exists(appeal.appealText) ? t(appeal.appealText) : appeal.appealText}
        </Message>
      )}
    </Dialog>
  );
}
