import React from "react";
import {
  Column,
  Row,
  useExpanded,
  useFlexLayout,
  useSortBy,
  useTable,
} from "react-table";
import { useTranslation } from "react-i18next";
import {
  LoadingContainer,
  LoadMore,
  NoData,
  TableContainer,
  TableRoot,
  Tbody,
  Td,
  Th,
  Thead,
  ThGroup,
  Tr,
  TrOuter,
} from "./styled";
import { Spinner } from "../Spinner";
import { Button } from "../Button";
import { Checkbox } from "../Checkbox";

interface Props<T extends object> {
  //TODO invalid typification
  columns: Column<T>[];
  data: T[];
  loading?: boolean;
  hasMore?: boolean;
  onLoadMore?(): void;
  onClickRow?(element: Row<T>): void;
  onSelect?(element: Row<T>): void;
  onThClick?(element: Column<T>): void;
  selectedRows?: string[];
}

export function Table<T extends object>({
  columns,
  data = [],
  loading,
  hasMore,
  onLoadMore,
  onClickRow,
  onSelect,
  onThClick,
  selectedRows = [],
}: Props<T>) {
  const { t } = useTranslation();
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
      },
      useFlexLayout,
      useSortBy,
      useExpanded
    );

  return (
    <TableContainer>
      <TableRoot {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <ThGroup {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps()}
                  onClick={() => onThClick && onThClick(column)}
                  key={column.id}
                >
                  {column.render("Header")}
                </Th>
              ))}
              {onSelect && (
                <Th style={{ minWidth: "50px", padding: "1rem 2rem" }}>
                  <Checkbox
                    style={{ opacity: 0, visibility: "hidden" }}
                    round
                    onClick={(e) => e.stopPropagation()}
                    checked={false}
                    onChange={() => null}
                  />
                </Th>
              )}
            </ThGroup>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            // @ts-ignore
            const { id } = row.original;
            return (
              <TrOuter
                isHover={!!onClickRow}
                selected={selectedRows.includes(id)}
                key={id}
              >
                <Tr
                  {...row.getRowProps()}
                  onClick={() => onClickRow && onClickRow(row)}
                >
                  {row.cells.map((cell, rowIndex) => (
                    <Td {...cell.getCellProps()} key={`${id}_${rowIndex}`}>
                      {cell.render("Cell")}
                    </Td>
                  ))}
                  {onSelect && (
                    <Td
                      style={{ minWidth: "50px", padding: "1rem 2rem" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onSelect(row);
                      }}
                    >
                      <Checkbox
                        round
                        onClick={(e) => e.stopPropagation()}
                        checked={selectedRows.includes(id)}
                      />
                    </Td>
                  )}
                </Tr>
              </TrOuter>
            );
          })}
        </Tbody>
        {hasMore && (
          <LoadMore>
            <Button onClick={onLoadMore} className="btn-code">
              {t("Показать еще")}
            </Button>
          </LoadMore>
        )}
        {!data.length && <NoData>{t("Нет данных")}</NoData>}
      </TableRoot>

      {loading && (
        <LoadingContainer>
          <Spinner radius="20px" />
        </LoadingContainer>
      )}
    </TableContainer>
  );
}

Table.defaultProps = {
  loading: false,
  hasMore: false,
  onLoadMore: null,
  onClickRow: null,
  onThClick: null,
  onSelect: null,
  selectedRows: [],
};
