import React, { useState } from "react";
import { Dialog } from "../../../ui-kit/Dialog";
import { ReactComponent as CloseIcon } from "../../../../assets/svg/ic-close-black.svg";
import {
  CloseDialogIcon,
  DialogHeaderTitle,
} from "../../../ui-kit/Dialog/styled";
import { Header, Link, Message, Tabs, Tab } from "./styled";
import { useTranslation } from "react-i18next";
import { IAffiliate } from "../../../../types/affiliate";
import { convertBase64ImageToSrcFormat } from "../../../../utils/common";
import { Button } from "../../../ui-kit/Button";

interface Props {
  affiliate: IAffiliate;
  onDismiss: Function;
  onChangeBind: Function;
  pending: boolean;
}

export function AffiliateModal({
  affiliate,
  onDismiss,
  onChangeBind,
  pending,
}: Props) {
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const tabs = [t("О партнере"), t("Права доступа")];

  const InfoTab = () => (
    <>
      <Message style={{ marginTop: "2rem", fontSize: "4rem" }}>
        {affiliate.titleText}
      </Message>
      <Message fontSize="1.6rem">{affiliate.infoText}</Message>
      {affiliate.url && (
        <Link href={`https://${affiliate.url}`} target="_blank">
          {affiliate.url}
        </Link>
      )}
    </>
  );

  const AccessRightsTab = () => (
    <>
      <Message style={{ marginTop: "2rem", fontSize: "4rem" }}>
        {t("Партнер запрашивает")}
      </Message>
      <Message fontSize="1.6rem">- {t("Право получать все мои чеки")}</Message>
      <Message fontSize="1.6rem">- {t("Право отправлять уведомления")}</Message>
    </>
  );

  return (
    <Dialog
      onDismiss={onDismiss}
      ariaLabel="notification-modal"
      style={{ padding: "4rem 0" }}
    >
      <Header>
        <DialogHeaderTitle>{affiliate.name || t("Партнер")}</DialogHeaderTitle>
        <CloseDialogIcon source={CloseIcon} onClick={() => onDismiss()} />
      </Header>
      <img src={convertBase64ImageToSrcFormat(affiliate.infoPhoto)} alt="" />
      <Tabs>
        {tabs.map((label, i) => (
          <Tab
            className={activeTabIndex === i ? "active" : ""}
            onClick={() => setActiveTabIndex(i)}
            key={i}
          >
            {label}
          </Tab>
        ))}
      </Tabs>
      {activeTabIndex === 0 && <InfoTab />}
      {activeTabIndex === 1 && <AccessRightsTab />}
      <div style={{ padding: "0 4rem", width: "100%", marginTop: "2.4rem" }}>
        <Button
          type="submit"
          isLoading={pending}
          actionType={affiliate.userBind && "attention"}
          style={{ width: "inherit" }}
          onClick={() => onChangeBind()}
        >
          {affiliate.userBind ? t("Отключить") : t("Подключить")}
        </Button>
      </div>
    </Dialog>
  );
}
