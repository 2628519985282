import styled from 'styled-components';
import {breakpoint, color, font} from '../../../../theme';

export const ButtonContainer = styled.div`
  display: block;
`;

export const InputWithButton = styled.div`
  position: relative;
  margin-bottom: 2rem;
  input {
    padding: 1.3em 1em;
    border: none;
    background: #F5F9FB;
    @media (min-width: ${breakpoint.tablet}) {
      padding-right: 10em;
    }
  }
  .login-btn {
    margin-top: 2rem;
    width: 100%;
    @media (min-width: ${breakpoint.tablet}) {
      position: absolute;
      right: 2px;
      top: 2px;
      height: calc(100% - 4px);
      width: auto;
      margin-top: 0;
    }
  }
`;

export const RulesInfo = styled.p`
  font-size: 1.8rem;
  color: ${color.liteGrey};
  margin: 0;
  font-family: ${font.family.secondary};
  @media (min-width: ${breakpoint.phone}) {
    font-size: 1.4rem;
  }
`;

export const StoresIconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 7rem 0 -10rem;
  a {
    width: 43%;
    &:last-child {
      width: 50%;
    }
  }
  img {
    display: block;
    width: 100%;
  }
`;
