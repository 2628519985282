import styled from 'styled-components';
import {border} from '../../../../../../../theme';

export const PartnerIcon = styled.img`
  display: block;
  object-fit: contain;
  width: 5rem;
  height: 5rem;
  border-radius: ${border.radius};
`;
