import { useTranslation } from "react-i18next";
import React from "react";
import { useQuery } from "../../../hooks/useQuery";
import { Button } from "../../ui-kit/Button";
import { ButtonContainer } from "../LoginPage/PhoneForm/styled";
import { QrContent, QrDetails, QrHeader } from "./styled";
import { Redirect } from "react-router-dom";

export function QrPage() {
  const query = useQuery();

  const tin = query.get("tin");
  const address = query.get("address");
  const name = query.get("name");

  const redirect = !(tin && address && name);

  if (redirect) {
    // return <Redirect to={prevLocation && loginRedirectFromPrevPath ? prevLocation : '/'} />;
    return <Redirect to="/" />;
  }

  const data = [
    { label: "TIN", value: tin },
    {
      label: "Address",
      value: address,
    },
    { label: "Name", value: name },
  ];

  return (
    <>
      <QrContent>
        <QrHeader>
          Information about
          <br /> the point of sale
        </QrHeader>
        <QrDetails>
          {data?.map((row) => (
            <div key={row.label}>
              <h1>{row.label}</h1>
              <div>{row.value}</div>
            </div>
          ))}
        </QrDetails>
        <ButtonContainer>
          <Button
            type="submit"
            href={`app.motivation://appeal.qr?route=appeal_reason_screen&appeal_type=QR&tin=${tin}&name=${name}&address=${address}`}
          >
            Send request
          </Button>
        </ButtonContainer>
      </QrContent>
    </>
  );
}
