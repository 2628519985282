import React, {useEffect} from 'react';
import {ContentContainer} from './styled';
import {ReceiptTable} from './components/ReceiptTable';
import {fetchLastReceiptFx} from '../../../models/header';

export function Receipt() {
  useEffect(() => {
    const timer = setInterval(fetchLastReceiptFx, 30000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <ContentContainer>
      <ReceiptTable />
    </ContentContainer>
  );
}
