import React, { useEffect, useMemo, useState } from "react";
import { useStore } from "effector-react";
import { Redirect, useLocation } from "react-router-dom";
import { Location } from "history";
import {
  authPhone$,
  authPhoneSelected,
  clientFromPartner,
  isAuth$,
  loginRedirectFromPrevPath$,
  showCodeForm$,
} from "../../../models/auth";
import { AuthContent, AuthTitle } from "./styled";
import { PhoneForm } from "./PhoneForm";
import { CodeForm } from "./CodeForm";
import { Background } from "../../common/App/styled";
import { profileState$ } from "../../../models/settings/profile";
import { EmailForm } from "./EmailForm";
import { useQuery } from "../../../hooks/useQuery";
import { useTranslation } from "react-i18next";

export function LoginPage() {
  const { t } = useTranslation();
  const isAuth = useStore(isAuth$);
  const showCodeForm = useStore(showCodeForm$);
  const phone = useStore(authPhone$);
  const loginRedirectFromPrevPath = useStore(loginRedirectFromPrevPath$);
  const { emails } = useStore(profileState$);

  const [emailSkipped, setEmailSkipped] = useState(false);

  const location = useLocation<{ from: Location }>();
  const prevLocation = location?.state?.from;
  const query = useQuery();

  const partnerCode = query.get("partnerCode");
  const queryPhone = query.get("phone");
  const email = query.get("email");

  useEffect(() => {
    // if (sessionStorage.getItem('skipEmail')) {
    if (true) {
      clientFromPartner();
      setEmailSkipped(true);
    }
  }, []);

  useEffect(() => {
    if (partnerCode && queryPhone) {
      authPhoneSelected(queryPhone);
      clientFromPartner();
    }
  }, [partnerCode, queryPhone]);

  const isEmailBounded = useMemo(
    () => emails.find((_email) => _email.confirmed),
    [emails]
  );

  if (isAuth && (isEmailBounded || emailSkipped)) {
    // return <Redirect to={prevLocation && loginRedirectFromPrevPath ? prevLocation : '/'} />;
    return <Redirect to="/" />;
  }

  return (
    <>
      <Background />
      <AuthContent>
        <AuthTitle>{"Sign in with your phone number"}</AuthTitle>
        {isAuth && !isEmailBounded ? (
          <EmailForm queryEmail={email} />
        ) : showCodeForm ? (
          <CodeForm />
        ) : (
          <PhoneForm phone={phone} />
        )}
      </AuthContent>
    </>
  );
}
