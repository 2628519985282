import React, { useMemo } from "react";
import moment from "moment";
import { formatDate } from "utils/date";
import { useStore, useGate } from "effector-react";
import { Table } from "../../../ui-kit/Table";
import { tableState$, fetchMore, TableGate } from "models/outcome";
import { useTranslation } from "react-i18next";

function Outcome() {
  const { t } = useTranslation();
  const { data, loading } = useStore(tableState$);
  useGate(TableGate);
  const columns = useMemo(
    () => [
      {
        Header: t("Дата"),
        accessor: "createdAt",
        minWidth: 130,
        width: 130,
        Cell: ({ value }: { value: string }) => (
          <span>{formatDate(moment(value).toDate(), "dd.MM.yyyy HH:mm")}</span>
        ),
      },
      {
        Header: t("Тип вывода"),
        accessor: "payoutType",
        minWidth: 240,
        width: 240,
        Cell: ({ value }: { value: string }) => {
          switch (value) {
            case "CARD":
              return t("На банковскую карту");
            case "PHONE":
              return t("На счет мобильного телефона");
            case "BANK_ACCOUNT":
              return t("На банковский счет");
            default:
              return "Неизвестный тип вывода";
          }
        },
      },
      {
        Header: t("Сумма"),
        accessor: "amount",
        minWidth: 120,
        width: 120,
        Cell: ({ value }: { value: number }) => (
          <strong>
            {value ? Math.floor(value / 100).toLocaleString("ru-RU") : "0"}
          </strong>
        ),
      },
    ],
    [data]
  );

  return (
    <div style={{ marginBottom: 30 }}>
      <Table
        data={data?.items}
        //@ts-ignore
        columns={columns}
        loading={loading}
        onLoadMore={() => fetchMore()}
      />
    </div>
  );
}

export { Outcome };
