import styled from "styled-components";
import { breakpoint, font } from "../../../theme";
import { Icon } from "../Icon";

export const InputContainer = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoint.desktop}) {
    width: 100%;
    max-width: 100%;
  }
`;

export const Input = styled.input<{ withStatus?: boolean }>`
  box-shadow: none;
  font-size: 2rem;
  font-family: ${font.family.secondary};
  font-weight: 400;
  color: #00b956;
  padding: ${(p) =>
    p.withStatus ? "1em 2.6em 1.8em 0" : "1.5em 1em 1.5em 1em"};
  background: ${(p) => (p.withStatus ? "#fff" : "#F5F9FB")};
  border-bottom: ${(p) => (p.withStatus ? "1px solid #F5F9FB" : "none")};
  border-radius: ${(p) => (p.withStatus ? "none" : "16px")};
  margin-top: ${(p) => (p.withStatus ? "none" : "1em")};
  width: ${(p) => (p.withStatus ? "100%" : "90%")};
  outline: none;
  line-height: 1em;
  text-overflow: ellipsis;
  ::placeholder {
    color: #687591;
    font-family: ${font.family.secondary};
    font-weight: 400;
  }
  :disabled {
    background: transparent;
  }
  :focus {
    border-bottom-color: #00b956;
  }
`;

export const InputIcons = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 3rem;
`;

export const InputIcon = styled(Icon)<{ needApprove?: boolean }>`
  position: relative;
  width: 3rem;
  cursor: pointer;
  margin-left: 1rem;
  @media (min-width: ${breakpoint.phone}) {
    width: 2rem;
  }
  &:after {
    content: "";
    display: block;
    width: 150%;
    height: 150%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const EmailStatus = styled.span<{ needApprove?: boolean }>`
  position: absolute;
  left: 0;
  bottom: 0.4rem;
  background: ${(p) => (p.needApprove ? "#731982" : "#7ac54b")};
  color: #fff;
  padding: 0.4rem 1rem;
  border-radius: 1rem;
  font-size: 1.6rem;
  @media (min-width: ${breakpoint.phone}) {
    font-size: 1.2rem;
    bottom: 0.8rem;
  }
`;
