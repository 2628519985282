import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { border, breakpoint, color, font } from "../../../theme";
import TgIcon from "../../../assets/svg/ic-telegram.svg";

export const LeftSideBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  z-index: 10;
  box-shadow: 0px -10px 40px rgba(0, 28, 175, 0.1);
  background: #fff;
  padding: 2rem 0;
  justify-content: center;
  @media (max-width: ${breakpoint.desktop}) {
    min-height: auto !important;
  }
  @media (min-width: ${breakpoint.desktop}) {
    display: flex;
    width: 7.6%;
    box-shadow: none;
    position: initial;
    background: transparent;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }
`;

export const LeftSideBarItem = styled(NavLink)<{
  $logo: string;
  $logoActive: string;
  $mobile: boolean;
  $index: number;
}>`
  display: block;
  width: 7.8rem;
  height: 7.8rem;
  margin: 0 1rem;
  position: relative;
  border-radius: ${border.radius};
  cursor: pointer;
  background: url(${(p) => p.$logo}) center center no-repeat, #ffffff;
  /* background-size: 30% auto; */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @media (min-width: ${breakpoint.tablet}) {
    width: 5.8rem;
    height: 5.8rem;
    margin: 0 2rem 0 0;
  }
  @media (min-width: ${breakpoint.desktop}) {
    display: ${(p) => (p.$mobile ? "none" : "block")};
    margin-bottom: 4rem;
    position: sticky;
    left: 0;
    top: ${(p) => {
      if (p.$index === 5) {
        return 3 * 8.8 + 6;
      }
      return p.$index * 8.8 + 6;
    }}rem;
  }
  &.active {
    background: url(${(p) => p.$logoActive}) center center no-repeat,
      ${color.secondary};
    /* background-size: 30% auto; */
    box-shadow: -5px 15px 30px -5px rgba(66, 101, 227, 0.5);
    img {
      fill: #fff;
    }
  }
  &:hover div {
    @media (min-width: ${breakpoint.desktop}) {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const LeftSideBarNotificationMark = styled.div`
  display: block;
  position: absolute;
  padding: 0.2em 0.4em;
  color: #01a0f5;
  left: 4rem;
  top: 1rem;
  border-radius: 1rem;
  background: #fff;
  border: 1px solid #01a0f5;
  font-size: 1.4rem;
  min-width: 1.4em;
  text-align: center;
  @media (min-width: ${breakpoint.desktop}) {
    left: 3rem;
    top: 0.6rem;
  }
`;

export const LeftSideBarItemName = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  display: block;
  left: calc(100% + 1.3em);
  top: 50%;
  transform: translateY(-50%);
  background: rgba(68, 81, 109, 0.7);
  color: #fff;
  font-size: 1.5rem;
  font-family: ${font.family.secondary};
  padding: 1.3em 1.3em 1.2em;
  border-radius: ${border.radius};
  z-index: 10;
  backdrop-filter: blur(4px);
  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 100%;
    top: calc(50% - 0.6rem);
    width: 0;
    height: 0;
    border: 0.6rem solid transparent;
    border-right-width: 0.8rem;
    border-right-color: rgba(68, 81, 109, 0.7);
  }
`;

export const LeftSideBarSocial = styled.a<{ type: "telegram" | "vk" }>`
  margin-top: auto;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background: url(${(p) => p.type === "telegram" && TgIcon}) center center
      no-repeat,
    #34abe3;
  background-size: 40% auto;
  position: sticky;
  bottom: 6rem;
  left: 0;
  display: none;
  visibility: hidden;
  @media (min-width: ${breakpoint.desktop}) {
    display: block;
  }
  &:hover div {
    @media (min-width: ${breakpoint.desktop}) {
      opacity: 1;
      visibility: visible;
    }
  }
`;
