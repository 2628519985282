import styled from 'styled-components';
import {color} from '../../../../../theme';
import {Icon} from '../../../../ui-kit/Icon';

export const StyledTable = styled.div`
  display: none;

  @media (min-width: 1055px) {
    display: block;
  }
`;

export const SortIcon = styled.div<{
  rotate?: string;
}>`
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  border: 0.6rem solid transparent;
  border-top-color: ${color.orange};
  margin: ${(p) => (p.rotate === 'true' ? '-0.5rem' : '0')} 0.5rem
    ${(p) => (p.rotate === 'true' ? '0' : '-0.5rem')} 0;
  transform: rotate(${(p) => (p.rotate === 'true' ? '180deg' : '0deg')});
`;

export const ArrowDown = styled(Icon)`
  width: 1.4rem;
`;

export const ExpandButton = styled.div<{
  rotate?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(${(p) => (p.rotate ? '180deg' : '0deg')});
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -1rem;
    top: -1rem;
    width: calc(100% + 2rem);
    height: calc(100% + 2rem);
  }
`;
