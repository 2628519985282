import styled from "styled-components";
import { breakpoint } from "../../../theme";
import {
  AppealStatusType,
  AppealStatusTypeMapping,
} from "../../../types/appeal";

export const MainContainer = styled.div<{ isLogin: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: ${(p) => p.isLogin && "center"};
  justify-content: ${(p) => p.isLogin && "center"};
  margin-bottom: 15rem;
  @media (min-width: ${breakpoint.desktop}) {
    margin-bottom: 0;
  }
`;

export const BadgeContainer = styled.div<{
  type: AppealStatusType;
}>`
  background: ${({ type }) => AppealStatusTypeMapping[type]};
  border-radius: 100px;
  min-width: 120px;
  padding: 6px 8px;

  color: #fff;
  font-family: Fira Sans, sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  
  display: flex;
  align-items: center;
  justify-content: center;
  }
`;
