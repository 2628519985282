import React, { useEffect, useMemo } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { RouteProps } from "react-router";
import { useStore } from "effector-react";
import { isAuth$, loadSessionUserFx, skipEmail$ } from "../../../models/auth";
import { TokenStorage } from "../../../core/tokenStorage";
import { profileState$ } from "../../../models/settings/profile";

export function PrivateRoute({ children, ...rest }: RouteProps) {
  const isAuth = useStore(isAuth$);
  const { emails } = useStore(profileState$);
  const skipEmail = useStore(skipEmail$);
  const { search } = useLocation();

  const isEmailBounded = useMemo(
    () => emails.find((email) => email.confirmed),
    [emails]
  );

  useEffect(() => {
    const params = new URLSearchParams(search);
    const accessToken = params.get("token");
    const refresh = params.get("refresh");
    const source = params.get("source");
    if (accessToken && refresh && source) {
      TokenStorage.storeTokens({ accessToken, refreshToken: refresh }, true);
      localStorage.setItem("sourceDeviceId", source);
      loadSessionUserFx();
    }
  }, [search]);

  return (
    <Route
      {...rest}
      //TODO invalid typification
      //@ts-ignore
      render={({ location }) =>
        // isAuth && (isEmailBounded || skipEmail) ? (
        isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
              search: location.search,
            }}
          />
        )
      }
    />
  );
}
