import styled, {keyframes} from 'styled-components';
import {color} from '../../../theme';

export const Root = styled.div`
  position: relative;
`;

const showKeyframe = keyframes`
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const HintText = styled.span<{isError: boolean}>`
  font-size: 1.8rem;
  animation: ${showKeyframe} 0.3s;
  position: absolute;
  right: 1rem;
  bottom: calc(100% + 0.4rem);
  text-align: right;

  ${(p) => p.isError && `color: ${color.danger}`};
`;
