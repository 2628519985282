import styled from 'styled-components';

interface TooltipTextProps {
  minWidth: number;
}

export const TooltipText = styled.span`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  bottom: calc(100% + 1rem);
  background: rgba(66, 101, 227, 1);
  font-size: 1.8rem;
  color: #fff;
  padding: 0.2em 1em;
  border-radius: 0.2em;
  transform: translate(-50%, 0);
  ${({minWidth}: TooltipTextProps) => (minWidth ? `min-width: ${minWidth}px;` : '')}
  &:after {
    content: '';
    position: absolute;
    right: calc(50% - 0.5em);
    top: 100%;
    width: 0;
    height: 0;
    border: 0.5rem solid transparent;
    border-top-color: rgba(66, 101, 227, 1);
  }
`;

export const Root = styled.div`
  position: relative;
  &.hover ${TooltipText} {
    transition: 0.3s ease-in-out 1s;
    opacity: 1;
    visibility: visible;
  }
`;
