import {AxiosError, AxiosRequestConfig} from 'axios';
import {ReceiptExpirationDateErrorAdditionalInfo} from '../types/receipt';

export interface RestError extends Error {
  additionalInfo?: ReceiptExpirationDateErrorAdditionalInfo;
  code?: string;
  status?: number;
  error?: string;
  config: AxiosRequestConfig;
}

export async function restError(err: AxiosError): Promise<RestError> {
  if (!err.response) {
    return err;
  }

  const {status, data, config} = err.response;

  const {message, code, additionalInfo} = data;

  return {
    ...(new Error(message) as RestError),
    message,
    name: 'RestError',
    status,
    code,
    config,
    additionalInfo,
  };
}
