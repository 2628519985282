import React from "react";
import { useStore } from "effector-react";
import {
  fetchMore,
  receiptForModalSelected,
  receiptSelected,
  tableState$,
} from "../../../../../models/receipt";
import {
  List,
  ListItem,
  ListRowSeller,
  ListRowSellerName,
  ListRowDate,
  LoadingContainer,
  NoData,
  ListRowFooter,
} from "./styled";
import { formatNumber } from "../../../../../utils/number";
import { formatDate } from "../../../../../utils/date";
import { Spinner } from "../../../../ui-kit/Spinner";
import { Button } from "../../../../ui-kit/Button";
import { Checkbox } from "../../../../ui-kit/Checkbox";
import { PartnerIconCell } from "../ReceiptTable/Cells/PartnerIconCell";
import { useTranslation } from "react-i18next";

export function TableList({ selectedRows }: { selectedRows: string[] }) {
  const { t } = useTranslation();
  const { data, lastPage, loading } = useStore(tableState$);

  function onSelect(key: string) {
    receiptSelected({ key });
  }

  return (
    <List>
      {data.map((item) => (
        <ListItem
          key={item.id}
          onClick={() => receiptForModalSelected(item.id)}
          selected={selectedRows.includes(item.id)}
        >
          <ListRowDate>
            <div>
              {formatDate(
                new Date(item.dateTime as string),
                "dd.MM.yyyy HH:mm"
              )}
            </div>
            <Checkbox
              round
              onClick={(e) => e.stopPropagation()}
              checked={selectedRows.includes(item.id)}
              onChange={() => onSelect(item.id)}
            />
          </ListRowDate>
          <ListRowSeller>
            <PartnerIconCell value={item.orgName} />
            <ListRowSellerName>
              {item.orgName && <div>{item.orgName}</div>}
              <div>
                {item.orgName === " "
                  ? "Продавец не определен"
                  : item.orgName
                  ? item.orgName
                  : "Продавец не определен"}
              </div>
            </ListRowSellerName>
          </ListRowSeller>
          <ListRowFooter>
            <div>{formatNumber(item.totalSum, 2)} &#8381;</div>
            <div>ИНН {item.orgName}</div>
          </ListRowFooter>
        </ListItem>
      ))}

      {lastPage && (
        <Button style={{ margin: "0 auto" }} onClick={() => fetchMore()}>
          Загрузить еще
        </Button>
      )}
      {!data.length && <NoData>{t("Нет данных")}</NoData>}

      {loading && (
        <LoadingContainer>
          <Spinner radius="20px" />
        </LoadingContainer>
      )}
    </List>
  );
}
