import { format } from "date-fns";
import { forward, guard, merge, sample } from "effector";
import { debounce } from "debounce";
import {
  dateFrom$,
  dateTo$,
  inn$,
  query$,
  orderBy$,
  periodChanged,
  FiltersGate,
  innChanged,
  queryChanged,
  orderByChanged,
  filters$,
  fetchReceiptsFx,
  TableGate,
  tableStateChanged,
  data$,
  debouncedInnChanged,
  debouncedQueryChanged,
  fetchMoreReceiptsFx,
  fetchMore,
  receiptSelected,
  receiptsSelected$,
  clearSelectedReceipts,
  receiptForModalSelected,
  fetchReceiptDetailsFx,
  receiptDetailsDialogOpen,
  isReceiptDetailsDialog$,
  receiptDetailsDialogClose,
  receiptDetails$,
  ReceiptDetailsGate,
  refreshTable,
  receiptsExpirationDateError$,
} from "./index";
import { fetchReceiptDetails, fetchReceipts } from "../../api";
import { showNewReceipts } from "../header";

fetchReceiptsFx.use(fetchReceipts);
fetchMoreReceiptsFx.use(fetchReceipts);
fetchReceiptDetailsFx.use(fetchReceiptDetails);

const showNewReceiptsWhenPageOpen = guard({
  source: showNewReceipts,
  filter: FiltersGate.status,
});

dateFrom$
  .on(periodChanged, (state, { startDate }) => startDate)
  .reset(FiltersGate.close, TableGate.close, showNewReceiptsWhenPageOpen);
dateTo$
  .on(periodChanged, (state, { endDate }) => endDate)
  .reset(FiltersGate.close, TableGate.close, showNewReceiptsWhenPageOpen);
inn$
  .on(innChanged, (state, inn) => inn)
  .reset(FiltersGate.close, showNewReceiptsWhenPageOpen);
query$
  .on(queryChanged, (state, query) => query)
  .reset(FiltersGate.close, showNewReceiptsWhenPageOpen);
orderBy$
  .on(orderByChanged, (state, orderBy) => orderBy)
  .reset(FiltersGate.close, showNewReceiptsWhenPageOpen);

data$
  .on(fetchReceiptsFx.doneData, (state, source) => ({
    items: source.items || [],
    lastPage: source.lastPage,
  }))
  .on(fetchMoreReceiptsFx.doneData, (state, source) => ({
    items: [...state.items, ...source.items],
    lastPage: source.lastPage,
  }));

innChanged.watch(debounce(debouncedInnChanged, 500));
queryChanged.watch(debounce(debouncedQueryChanged, 500));

isReceiptDetailsDialog$
  .on(receiptDetailsDialogOpen, () => true)
  .reset([receiptDetailsDialogClose, ReceiptDetailsGate.close]);
receiptDetails$
  .on(fetchReceiptDetailsFx.doneData, (state, receiptDetails) => receiptDetails)
  .reset([receiptDetailsDialogClose, ReceiptDetailsGate.close]);

receiptsExpirationDateError$
  .on(
    [fetchReceiptsFx.failData, fetchMoreReceiptsFx.failData],
    (state, error) => error
  )
  .reset([fetchReceiptsFx, fetchMoreReceiptsFx]);

receiptsSelected$
  .on(receiptSelected, (state, { key, select }) => {
    const elemIndex = state.indexOf(key);
    if (select !== undefined) {
      if (select) {
        if (elemIndex + 1) return [...state];
        return [...state, key];
      }
      if (!(elemIndex + 1)) return [...state];
      return [...state.slice(0, elemIndex), ...state.slice(elemIndex)];
    }
    return elemIndex + 1
      ? [...state.slice(0, elemIndex), ...state.slice(elemIndex + 1)]
      : [...state, key];
  })
  .reset([clearSelectedReceipts]);

sample({
  clock: merge([
    TableGate.open,
    tableStateChanged,
    showNewReceiptsWhenPageOpen,
  ]),
  source: filters$,
  fn: ({ dateFrom, dateTo, inn, orderBy, query }) => ({
    limit: 10,
    offset: 0,
    dateFrom: dateFrom ? format(dateFrom, "yyyy-MM-dd") : null,
    dateTo: dateTo ? format(dateTo, "yyyy-MM-dd") : null,
    orderBy,
    inn,
    kktOwner: query,
  }),
  target: fetchReceiptsFx,
});

sample({
  clock: merge([refreshTable]),
  source: filters$,
  fn: ({ dateFrom, dateTo, inn, data, orderBy, query }) => ({
    limit: data.items ? data.items.length : 10,
    offset: 0,
    dateFrom: dateFrom ? format(dateFrom, "yyyy-MM-dd") : null,
    dateTo: dateTo ? format(dateTo, "yyyy-MM-dd") : null,
    orderBy,
    inn,
    kktOwner: query,
  }),
  target: fetchReceiptsFx,
});

sample({
  clock: merge([fetchMore]),
  source: filters$,
  fn: ({ dateFrom, dateTo, inn, data, orderBy, query }) => ({
    limit: 10,
    offset: data.items.length,
    dateFrom: dateFrom ? format(dateFrom, "yyyy-MM-dd") : null,
    dateTo: dateTo ? format(dateTo, "yyyy-MM-dd") : null,
    orderBy,
    inn,
    kktOwner: query,
  }),
  target: fetchMoreReceiptsFx,
});

forward({
  from: receiptForModalSelected,
  to: fetchReceiptDetailsFx,
});

forward({
  from: receiptForModalSelected,
  to: receiptDetailsDialogOpen,
});
