import React, {ReactNode} from 'react';
import {useLocation} from 'react-router-dom';
import {MainContainer} from './styled';
import {MaxWidthContainer} from '../../ui-kit/MaxWidthContainer';

interface Props {
  children: ReactNode;
}

export function Content({children}: Props) {
  const {pathname} = useLocation();
  const isLogin = pathname === '/login';

  return (
    <MainContainer isLogin={isLogin}>
      <MaxWidthContainer>{children}</MaxWidthContainer>
    </MainContainer>
  );
}
