import styled, {createGlobalStyle} from 'styled-components';
import {ToastContainer} from 'react-toastify';
import {breakpoint, color, font} from '../../../theme';
import background from '../../../assets/images/login-bg.png';
import 'react-toastify/dist/ReactToastify.css';

export const GlobalStyle = createGlobalStyle<{isLogin?: boolean}>`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: calc(100vw / 52);
    font-family: ${font.family.primary};

    @media (min-width: ${breakpoint.tablet}) {
      font-size: calc(100vw / 100);
    }
    @media (min-width: ${breakpoint.desktop}) {
      font-size: 8px;
    }
    @media (min-width: ${breakpoint.hd}) {
      font-size: calc(100vw / 179);
    }
  }

  body {
    margin: 0;
    background: radial-gradient(54.31% 86.89% at 102.64% 139.56%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%), #f1f3f9;
  }

  #root {
    width: 100%;
    min-width: 320px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: ${(p) => (p.isLogin ? '6rem 6rem 0' : '5rem 2rem 0')};
    @media (min-width: ${breakpoint.tablet}) {
      margin: 0 auto;
      max-width: ${(p) => (p.isLogin ? '80%' : '94.1%')};
      padding: ${(p) => (p.isLogin ? '8.5rem 0 0' : '3rem 0 0')};
    }
  }
  a {
    color: ${color.link};
    outline: none;
  }
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
  background-color: transparent;

  @media (min-width: ${breakpoint.tablet}) {
    background: url(${background}) center right no-repeat,
      radial-gradient(
          42.79% 30.67% at 75.5% 53.78%,
          rgba(0, 160, 277, 0.3) 0%,
          rgba(255, 255, 255, 0) 100%
        )
        center center no-repeat,
      #fff;
    background-size: 50% auto, auto;
  }
  @media (min-width: ${breakpoint.desktop}) {
    background: url(${background}) center right no-repeat,
      radial-gradient(
        42.79% 70.67% at 67.5% 53.78%,
        rgba(0, 160, 277, 0.3) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #fff;
    background-size: 50% auto, auto;
  }
`;

export const ToastContainerRoot = styled(ToastContainer)`
  &.Toastify__toast-container {
    width: 40rem;
    font-size: 1.6rem;
    @media screen and (max-width: ${breakpoint.phone}) {
      width: 100vw;
    }
  }
  .Toastify {
    &__toast {
      border-radius: 3px;
      padding: 1em;
      font-size: 1em;
    }
    &__toast--success {
      background: #7ac54b;
    }
    &__toast-body {
      display: flex;
      justify-content: center;
    }
  }
`;
