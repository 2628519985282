import React, {FormEvent, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {useLocation, Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import {Location} from 'history';
import {Input} from '../../../ui-kit/Input';
import {Hint} from '../../../ui-kit/Hint';
import {ButtonContainer, InputWithButton} from '../PhoneForm/styled';
import {Button} from '../../../ui-kit/Button';
import {attachEmailData} from '../../../../api';
import {CodeFromFooter, CodeFromBack, CodeFromSkip} from '../CodeForm/styled';
import {ToastContainerRoot} from '../../../common/App/styled';
import {AuthBtn, AuthInfo} from '../styled';
import {loginRedirectFromPrevPath$, logout, skipEmail$} from '../../../../models/auth';
import { useTranslation } from "react-i18next";

export function EmailForm({queryEmail}: {queryEmail: string | null}) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [requestPending, setRequestPending] = useState(false);

  const loginRedirectFromPrevPath = useStore(loginRedirectFromPrevPath$);
  const skipEmail = useStore(skipEmail$);
  const location = useLocation<{from: Location}>();
  const prevLocation = location?.state?.from;

  const {search} = useLocation();

  useEffect(() => {
    setError('');
  }, [email]);

  useEffect(() => {
    if (queryEmail) {
      setEmail(queryEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryEmail]);

  async function onSubmit(e?: FormEvent) {
    if (e) e.preventDefault();
    const currentEmail = email?.trim() || queryEmail?.trim() || '';
    setError('');
    const re = /\S+@\S+\.\S+/;
    if (re.test(currentEmail)) {
      try {
        setRequestPending(true);
        await attachEmailData({
          email: currentEmail,
          receiptExpirationDate: null,
          extraMeta: `/partners${search}`,
        });
        toast.success(`Письмо с подтверждением выслано на почту ${currentEmail}`);
        setEmailSent(true);
      } catch (err) {
        setError(err?.message ? err?.message : 'Ошибка при отправке сообщения');
      }
    } else {
      setError('Введите корректную электронную почту.');
    }
    setRequestPending(false);
  }

  return (
    <>
      <AuthInfo>
        {emailSent
          ? 'Чтобы войти в сервис, пожалуйста, подтвердите указанный Вами e-mail. Для этого откройте письмо в почтовом клиенте и перейдите по ссылке, указанной в нем'
          : 'Укажите и подтвердите адрес электронной почты. Это позволит Вам видеть больше своих чеков в сервисе и в дальнейшем получать за них бонусы от партнеров'}
      </AuthInfo>
      <form onSubmit={onSubmit}>
        <ToastContainerRoot
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          closeOnClick
        />
        {!emailSent && (
          <>
            <InputWithButton>
              <Hint text={error}>
                <Input
                  value={email}
                  placeholderChar="✕"
                  unmask={true}
                  lazy={false}
                  onChange={(e) => setEmail(e.target.value)}
                  hasError={!!error}
                />
              </Hint>
              <ButtonContainer>
                <Button
                  isLoading={requestPending}
                  type="submit"
                  disabled={!email || !!error}
                  className="login-btn"
                >
                  {"Save"}
                </Button>
              </ButtonContainer>
            </InputWithButton>

            <CodeFromFooter>
              <CodeFromBack onClick={() => logout(false)}>&lt; Назад</CodeFromBack>
              {skipEmail && (
                <CodeFromSkip to={prevLocation && loginRedirectFromPrevPath ? prevLocation : '/'}>
                  {"Skip"} &gt;
                </CodeFromSkip>
              )}
            </CodeFromFooter>
          </>
        )}
        {emailSent && skipEmail && (
          <AuthBtn>
            <Link to={prevLocation && loginRedirectFromPrevPath ? prevLocation : '/'}>
              <Button type="submit" className="login-btn">
                {"Continue"}
              </Button>
            </Link>
          </AuthBtn>
        )}
      </form>
    </>
  );
}
