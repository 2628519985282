/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import {
  NotificationsControlsContainer,
  ControlFilterIcon,
  ControlCalendarIcon,
  ControlRequestIcon,
} from "./styled";
import {
  daysDifferenceWithToday,
  formatDate,
  isBeforeDate,
} from "../../../utils/date";
import { Button } from "../../ui-kit/Button";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { SpinnerContainer } from "../Receipt/components/ReceiptTable/ReceiptDetails/styled";
import { Spinner } from "../../ui-kit/Spinner";
import { NoData } from "../Receipt/components/TableList/styled";
import { AppealRequestParams, IAppeal } from "../../../types/appeal";
import { fetchAppeals, fetchSingleAppeal } from "../../../api/appeals";
import { BadgeContainer } from "../../common/Content/styled";
import { AppealModal } from "./AppealModal";
import { AppealsFilterCalendar } from "./Filters/CalendarDialog";
import { AppealsFilters } from "./Filters/FiltersDialog";
import { NewAppealModal } from "./NewAppealModal";
import { apiClient } from "../../../core/apiClient";

export function AppealsTab() {
  const { t } = useTranslation();
  const [appeals, setAppeals] = useState<IAppeal[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [pending, setPending] = useState(true);
  const [requestParams, setRequestParams] = useState<
    Partial<AppealRequestParams>
  >({ page: 1 });
  const [appeal, setAppeal] = useState<IAppeal | null>(null);
  const [showModal, setShowModal] = useState(false);

  async function getList(resetList = false) {
    try {
      setPending(true);
      const response = await fetchAppeals(requestParams);

      setAppeals(resetList ? response.data : appeals.concat(response.data));
      setHasMore(response.hasNext);
    } catch (e) {
      console.error(e);
    } finally {
      setPending(false);
    }
  }

  async function getAppeal(id: string | number) {
    try {
      setPending(true);
      const response = await fetchSingleAppeal(id);
      setAppeal(response);
    } catch (e) {
      console.error(e);
    } finally {
      setPending(false);
    }
  }

  function onChangeRequestParams<
    T extends keyof typeof requestParams,
    K extends typeof requestParams[T]
  >({ param, value }: { param: T; value: K | K[] }) {
    if (!value)
      return setRequestParams((params) => {
        delete params[param];
        return { ...params };
      });
    setRequestParams((params) => ({
      ...params,
      [param]: value,
    }));
  }

  async function onRowClick(id: string | number) {
    try {
      await getAppeal(id);
      setShowModal(true);
    } catch (e) {
      console.error(e);
    }
  }

  const showMore = () => {
    onChangeRequestParams({
      param: "page",
      value: (requestParams.page || 0) + 1,
    });
    getList();
  };

  const handleLanguageChanged = useCallback(() => {
    getList();
  }, []);

  useEffect(() => {
    getList(true);
  }, [requestParams.date, requestParams.status]);

  useEffect(() => {
    i18n.on("languageChanged", handleLanguageChanged);

    return () => {
      i18n.off("languageChanged", handleLanguageChanged);
    };
  }, [handleLanguageChanged]);

  return (
    <>
      <NotificationsControl
        onFetch={() => getList(true)}
        onSubmit={({
          param,
          value,
        }: {
          param: keyof typeof requestParams;
          value: any;
        }) =>
          onChangeRequestParams({
            param,
            value,
          })
        }
      />
      {appeals.map((_appeal, i) => (
        <AppealsRow
          _appeal={_appeal}
          pending={pending}
          onRowClick={onRowClick}
        />
      ))}
      {hasMore && (
        <Button style={{ margin: "3rem 0 5rem 0" }} onClick={showMore}>
          {t("Показать еще")}
        </Button>
      )}
      <SpinnerContainer>
        {pending && <Spinner radius="20px" />}
      </SpinnerContainer>
      {appeals.length === 0 && !pending && (
        <NoData>{t("Список обращений пуст")}</NoData>
      )}
      {showModal && appeal && (
        <AppealModal appeal={appeal} onDismiss={() => setShowModal(false)} />
      )}
    </>
  );
}

type AppealsRowProps = {
  _appeal: IAppeal;
  pending: boolean;
  onRowClick: Function;
};
const AppealsRow: React.FC<AppealsRowProps> = ({
  _appeal,
  pending,
  onRowClick,
}) => {
  const { t } = useTranslation();
  const statusesShownAsClosed = ["SENT_PENALTY", "SENT_WARNING"];
  const status = statusesShownAsClosed.includes(_appeal.status)
    ? "CLOSED"
    : _appeal.status;
  return (
    <div
      className={`w-full border-2 border-white flex gap-[40px] bg-white rounded-3xl p-8 mb-6 cursor-pointer ${
        pending && "pointer-events-none"
      }`}
      onClick={() => onRowClick(_appeal.id)}
    >
      <BadgeContainer type={status}>{t(status)}</BadgeContainer>
      <div className="w-full md:w-2/12 text-slate-500 text-[14px] font-['Fira_sans'] flex justify-center flex-col">
        <span>
          {_appeal?.createdDate &&
            formatDate(new Date(_appeal?.createdDate), "dd.MM.yyyy HH:mm")}
        </span>
      </div>
      <div className="w-full md:w-9/12 text-slate-900 text-[14px] leading-[17px] font-['Fira_sans'] flex justify-center flex-col font-medium">
        <span>{_appeal?.appealText}</span>
      </div>
      <div
        className={`flex items-center  text-[14px] font-['Fira_sans'] whitespace-nowrap font-medium`}
        style={{
          color:
            daysDifferenceWithToday(_appeal.createdDate, 30) > 5
              ? "#00b956"
              : "#F95353",
          ...(status === "CLOSED" && { visibility: "hidden" }),
        }}
      >
        {t("Дней осталось", {
          days: isBeforeDate(_appeal.createdDate)
            ? daysDifferenceWithToday(_appeal.createdDate, 30)
            : "Просрочено",
        })}
      </div>
    </div>
  );
};

type NotificationsControlProps = {
  onSubmit: Function;
  onFetch: Function;
};
const NotificationsControl: React.FC<NotificationsControlProps> = ({
  onSubmit,
  onFetch,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showNewRequestModal, setNewRequestModal] = useState(false);

  const onSubmitRequest = async ({
    text,
    url,
    acceptType,
  }: Partial<IAppeal> & { text: string }) => {
    if (!(text && url && acceptType)) return;
    const formData = new FormData();
    formData.append("text", text);
    formData.append("url", url);
    formData.append("acceptType", acceptType);
    try {
      await apiClient.put("/api/v1/appeal/create", formData, {});
      setNewRequestModal(false);
      await onFetch();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <NotificationsControlsContainer>
      <ControlRequestIcon onClick={() => setNewRequestModal(true)} />
      <ControlFilterIcon onClick={() => setShowFilters(true)} />
      <ControlCalendarIcon onClick={() => setShowCalendar(true)} />
      {showCalendar && (
        <AppealsFilterCalendar
          onSubmit={(date: Date | undefined) => {
            onSubmit({ param: "date", value: date });
            setShowCalendar(false);
          }}
          onDismiss={() => setShowCalendar(false)}
        />
      )}
      {showFilters && (
        <AppealsFilters
          onSubmit={(param: string, value: any) => {
            onSubmit({ param, value });
            setShowFilters(false);
          }}
          onDismiss={() => setShowFilters(false)}
        />
      )}
      {showNewRequestModal && (
        <NewAppealModal
          onSubmit={(newRequest: Partial<IAppeal> & { text: string }) =>
            onSubmitRequest(newRequest)
          }
          onDismiss={() => setNewRequestModal(false)}
        />
      )}
    </NotificationsControlsContainer>
  );
};
