import { apiClient } from "../core/apiClient";
import { OutcomeRequest, OutcomeResponse } from "../types/outcome";

interface IPayoutRequest {
  operationAmount: number;
  availableAmount: number;
  payoutAccount: string;
  payoutType: string;
}

export async function fetchPayout(req: IPayoutRequest): Promise<any> {
  try {
    const resp = await apiClient.post("/api/v1/profile/payout", req);
    return resp;
  } catch (error) {
    if (error) {
      throw error;
    }
  }
}

export async function fetchOutcome(
  req: OutcomeRequest
): Promise<OutcomeResponse> {
  try {
    return (
      await apiClient.get("/api/v1/profile/transactions", { params: req })
    ).data as OutcomeResponse;
  } catch (error) {
    if (error) {
      throw error;
    }
    return {} as OutcomeResponse;
  }
}
