import styled from "styled-components";

export const HeaderToast = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #f95353;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 1.6rem;
  padding: 0.8em;
  z-index: 100;
  font-family: "Fira sans", sans-serif;
`;

export const HeaderToastClose = styled.div`
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1em;
  height: 1em;
  background: red;
`;
