import React, { FormEvent, useState, useCallback, useEffect } from "react";
import { useStore } from "effector-react";
import moment from "moment";
import { Dialog } from "../../../ui-kit/Dialog";
import {
  CloseDialogIcon,
  DialogHeaderTitle,
  DialogMessage,
  DialogButtonsRow,
  DialogCheckboxGroup,
} from "../../../ui-kit/Dialog/styled";
import { HistorySource } from "../../App/ReceiptsHistoryDialog/styled";
import {
  CodeFromBack,
  CodeFormInputsGroup,
  CodeFormInput,
  CodeFromFooter,
  CodeFromFooterInfo,
} from "../../../pages/LoginPage/CodeForm/styled";
import { ReactComponent as CloseIcon } from "../../../../assets/svg/ic-close.svg";
import { UserInfoModalHeader } from "../UserInfoModal/styled";
import { Button } from "../../../ui-kit/Button";
import { Hint } from "../../../ui-kit/Hint";
import {
  sendAdditionalPhone,
  verifyAdditionalPhone,
  addPhoneErrorMessage$,
  AddPhoneGate,
  isCodeSended$,
  verifyPhoneErrorMessage$,
  verifyAdditionalPhoneFx,
  isPhoneAdded$,
} from "../../../../models/settings/profile";
import { InfoBlock } from "../../../ui-kit/InfoBlock";
import { Checkbox } from "../../../ui-kit/Checkbox";

interface Props {
  onDismiss: Function;
  phone: string;
}

export const PhoneDialog = ({ onDismiss, phone }: Props) => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [codeSended, setCodeSended] = useState(false);
  const [historyShowPeriod, setHistoryShowPeriod] = useState<
    "all" | "fromNow" | null
  >(null);
  const [token, setToken] = useState("");
  const addPhoneErrorMessage = useStore(addPhoneErrorMessage$);
  const verifyPhoneErrorMessage = useStore(verifyPhoneErrorMessage$);
  const isCodeSended = useStore(isCodeSended$);
  const isPhoneAdded = useStore(isPhoneAdded$);
  const isCodeLoading = useStore(verifyAdditionalPhoneFx.pending);

  function capchaCallback(value: string) {
    setToken(value);
  }

  async function onSubmit(event: FormEvent) {
    event.preventDefault();
    try {
      await verifyAdditionalPhone({
        code: code.join(""),
        phone: phone.replace(/\D/g, ""),
        receiptExpirationDate:
          historyShowPeriod === "fromNow"
            ? moment().format("YYYY-MM-DD")
            : "1990-01-01",
      });
    } catch (e) {
      console.error(e);
    }
  }
  function onPaste(e: React.ClipboardEvent<HTMLInputElement>) {
    const pasteText = e.clipboardData?.getData("Text").split("");
    if (pasteText.length === 6) {
      setCode(pasteText);
    }
  }

  // const divRef = useCallback((node) => {
  //   if (node !== null) {
  //     const container = document.getElementById('capcha');
  //     // @ts-ignore
  //     if (window.smartCaptcha && container) {
  //       // @ts-ignore
  //       const widgetId = window?.smartCaptcha?.render(container, {
  //         sitekey: 'hfU4TD7fJUI7XcP5qRphKWgnIR5t9gXAxTRqdQJk',
  //         callback: capchaCallback,
  //       });
  //     }
  //   }
  // }, []);

  return (
    <>
      {/*@ts-ignore*/}
      <AddPhoneGate />
      <Dialog onDismiss={() => onDismiss()} ariaLabel="exit" center>
        <UserInfoModalHeader>
          <DialogHeaderTitle>Настройки отображения данных</DialogHeaderTitle>
          <CloseDialogIcon source={CloseIcon} onClick={() => onDismiss()} />
        </UserInfoModalHeader>
        {!isCodeSended && (
          <>
            <DialogMessage>
              В этом сервисе отображаются чеки из всех магазинов, в которых Вы
              указывали номер мобильного телефона или адрес электронной почты
              при покупке товара. Выберите варианты отображения данных
            </DialogMessage>
            <HistorySource>По номеру телефона {phone}</HistorySource>
            <InfoBlock
              text={
                "Если указанный номер телефона раньше принадлежал другому абоненту, вы можете увидеть некорректные данные"
              }
            />
            <DialogCheckboxGroup>
              <Checkbox
                round
                checked={historyShowPeriod === "all"}
                label="Отображать все чеки"
                onChange={() => setHistoryShowPeriod("all")}
              />
              <Checkbox
                round
                checked={historyShowPeriod === "fromNow"}
                label="Отображать чеки начиная с текущего момента"
                onChange={() => setHistoryShowPeriod("fromNow")}
              />
            </DialogCheckboxGroup>
            {/* <div style={{marginTop: 20}}>
              <div id="capcha" ref={divRef} />
            </div> */}
            {addPhoneErrorMessage && (
              <div style={{ color: "red", fontSize: 14 }}>
                {addPhoneErrorMessage}
              </div>
            )}
            <DialogButtonsRow>
              <Button
                onClick={() => {
                  sendAdditionalPhone({
                    phone: phone.replace(/\D/g, ""),
                    token,
                  });
                  setCodeSended(true);
                }}
                actionType="attention"
                disabled={!historyShowPeriod}
              >
                Продолжить
              </Button>
            </DialogButtonsRow>
          </>
        )}
        {isCodeSended && !isPhoneAdded && (
          <div style={{ marginTop: 40 }}>
            <DialogMessage>Введите код подтверждения СМС</DialogMessage>
            <form onSubmit={onSubmit}>
              <Hint text={verifyPhoneErrorMessage}>
                <CodeFormInputsGroup>
                  {code.map((elem, index) => (
                    <CodeFormInput
                      type="tel"
                      filled={!!elem}
                      maxLength={1}
                      key={index}
                      value={elem}
                      onKeyDown={(e) => {
                        if (e.which === 8 && !code[index]) {
                          const prevElem = e.currentTarget
                            .previousElementSibling as HTMLInputElement;
                          prevElem?.focus();
                        }
                      }}
                      onPaste={onPaste}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (!!value && !/[0-9]/.test(value)) return;
                        setCode(Object.assign([], code, { [index]: value }));
                        if (value) {
                          const nextInput = e.target
                            .nextElementSibling as HTMLInputElement;
                          nextInput?.focus();
                        }
                      }}
                    />
                  ))}
                  <Button
                    type="submit"
                    disabled={!code.every((el) => el)}
                    isLoading={isCodeLoading}
                    className="btn-code"
                  >
                    Продолжить
                  </Button>
                </CodeFormInputsGroup>
              </Hint>
              <CodeFromFooter>
                <CodeFromBack onClick={() => setToken("")}>
                  &lt; Назад
                </CodeFromBack>
                {/* <CodeFromFooterInfo>
                {isSlowpoke ? (
                  <CodeFromBack onClick={reSendAction}>Запросить повторно</CodeFromBack>
                ) : (
                  <>
                    Код отправлен на <span>{formatPhone(phone)}</span>. Запросить повторно можно
                    через <span>{leftTime}</span> сек.
                  </>
                )}
              </CodeFromFooterInfo> */}
              </CodeFromFooter>
            </form>
          </div>
        )}
        {isPhoneAdded && (
          <>
            <DialogMessage>Номер успешно добавлен</DialogMessage>
            <DialogButtonsRow>
              <Button onClick={() => onDismiss()}>Закрыть</Button>
            </DialogButtonsRow>
          </>
        )}
      </Dialog>
    </>
  );
};
