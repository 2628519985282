import {
  combine,
  createEffect,
  createEvent,
  createStore,
  merge,
} from "effector";
import { createGate } from "effector-react";
import { Outcome, OutcomeRequest, OutcomeResponse } from "../../types/outcome";
import { RestError } from "../../core/errors";

export const TableGate = createGate();

export const fetchOutcomeFx = createEffect<
  OutcomeRequest,
  OutcomeResponse,
  RestError
>();
export const fetchMoreOutcomeFx = createEffect<
  OutcomeRequest,
  OutcomeResponse,
  RestError
>();
export const OutcomeError$ = createStore<RestError | null>(null);

export const fetchMore = createEvent();
export const refreshTable = createEvent();

export const data$ = createStore<OutcomeResponse>({
  items: [],
  lastPage: true,
  total: 0,
});

export const tableState$ = combine<{
  data: OutcomeResponse;
  loading: boolean;
  error: RestError | null;
}>({
  data: data$,
  loading: fetchOutcomeFx.pending,
  error: OutcomeError$,
});
