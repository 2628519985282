import styled from 'styled-components';

export const IconContainer = styled.span<{
  block: boolean;
  noFill: boolean;
  noStroke: boolean;
}>`
  display: ${(p) => (p.block ? 'block' : 'inline-block')};
  vertical-align: top;

  svg {
    display: block;
    width: 100%;
    height: auto;

    &:not(:root) {
      overflow: hidden;
    }
  }
`;
