import styled from 'styled-components';
import {color, font} from '../../../theme';

export const LabelContainer = styled.label`
  width: 100%;
  display: block;
`;

export const LabelText = styled.span`
  display: block;
  margin-bottom: 8px;
  font-family: ${font.family.primary};
  font-size: 15px;
  color: ${color.text};
`;
