export function formatPhone(phone: string): string {
  if (!phone) {
    return "";
  }

  return phone.length === 11
    ? `+7 (${phone.slice(1, 4)}) ${phone.slice(4, 7)}-${phone.slice(
        7,
        9
      )}-${phone.slice(9, 11)}`
    : phone;
}
