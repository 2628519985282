import styled from 'styled-components';
import {breakpoint, color, font} from '../../../theme';

export const AuthContent = styled.div`
  width: 100%;
  margin-right: auto;

  @media (min-width: ${breakpoint.tablet}) {
    width: 58rem;
  }
`;

export const AuthTitle = styled.div`
  display: flex;
  margin-bottom: 1.2em;
  white-space: pre-line;
  color: ${color.text};
  font-size: 5.6rem;
  font-weight: 400;
  text-transform: uppercase;

  @media (min-width: ${breakpoint.tablet}) {
    font-size: 7.5rem;
  }
`;

export const AuthInfo = styled.p`
  margin-top: -2em;
  font-size: 2.5rem;
  color: ${color.grey};
  padding-right: 2em;
  font-family: ${font.family.secondary};
  line-height: 1.4em;
  margin-bottom: 3em;
`;

export const AuthBtn = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
