import * as React from 'react';
import {FormEvent, HTMLAttributes} from 'react';

import {CheckboxRoot, Control, Indicator, Label} from './styled';

interface Props {
  checked: boolean;
  label?: string;
  onChange?: (event: FormEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  name?: string;
  id?: string;
  round?: boolean;
}

export function Checkbox({
  round = false,
  checked,
  label,
  onChange,
  disabled = false,
  name,
  id,
  onClick,
  ...otherProps
}: HTMLAttributes<HTMLElement> & Props) {
  return (
    <CheckboxRoot disabled={disabled} {...otherProps}>
      <Control
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        name={name}
        onClick={onClick}
        id={id}
      />
      <Indicator round={round} checked={checked} disabled={disabled} />

      {label && <Label disabled={disabled}>{label}</Label>}
    </CheckboxRoot>
  );
}

Checkbox.defaultProps = {
  label: '',
  onChange: null,
  disabled: false,
  name: '',
  id: '0',
  round: false,
};
