import styled from 'styled-components';
import {border, color, font} from '../../../../../theme';

export const List = styled.div`
  position: relative;
  display: block;
  @media (min-width: 1055px) {
    display: none;
  }
`;

export const ListItem = styled.div<{
  selected?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(p) => (p.selected ? '#687591' : color.white)};
  border-radius: 2rem;
  font-family: ${font.family.secondary};
  color: ${(p) => (p.selected ? color.white : color.grey)};
  cursor: pointer;
  padding: 1.2rem 2rem;
  :not(:last-child) {
    margin-bottom: 2rem;
  }
  :hover,
  :active {
    box-shadow: 0 10px 50px rgba(0, 15, 95, 0.1), -5px 40px 70px -15px rgba(17, 45, 98, 0.15);
  }
`;

export const ListRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ListRowDate = styled(ListRow)`
  font-size: 1.5rem;
  font-weight: 400;
  color: ${color.liteGrey};
`;

export const ListRowSeller = styled(ListRow)`
  font-weight: 500;
  font-size: 1.6rem;
  margin: 1rem 0 2.2rem;
  color: ${color.liteGrey};
  justify-content: flex-start;
  align-items: center;
`;

export const ListRowSellerName = styled.div`
  margin-left: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  div {
    margin-top: 0.5em;
  }
  div:first-child {
    font-size: 2.1rem;
    font-weight: 500;
    color: ${color.grey};
    margin-top: 0;
  }
`;

export const ListRowFooter = styled(ListRow)`
  font-size: 2rem;
  font-weight: 500;
  div {
    :last-child {
      font-size: 0.9em;
      font-weight: 400;
      color: ${color.liteGrey};
    }
  }
`;

export const NoData = styled.div`
  width: 100%;
  background-color: ${color.white};
  height: 10rem;
  border-radius: ${border.radius};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  line-height: 1.4;
  color: ${color.liteGrey};
  font-family: ${font.family.secondary};
`;

export const LoadingContainer = styled.div`
  top: 0;
  background-color: ${color.white};
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
