import { AppealStatusType } from "../../../types/appeal";
import { createApi, createStore } from "effector";
import { SelectType } from "../../ui-kit/Select";

export const $appealsFilterStore = createStore<{
  filterDate: Date | undefined;
  filterStatus: SelectType<AppealStatusType> | undefined;
}>({ filterDate: undefined, filterStatus: undefined });

export const $appealsFilterStoreApi = createApi($appealsFilterStore, {
  setDate: (store, newDate) => ({ ...store, filterDate: newDate }),
  setStatus: (store, newStatus) => ({ ...store, filterStatus: newStatus }),
});
