import styled from "styled-components";
import { breakpoint, color, font } from "../../../../theme";

export const UserInfoModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-family: ${font.family.primary};
`;

export const UserInfoModalContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  gap: 2rem;
  font-family: ${font.family.primary};
`;

export const UserInfoModalPhone = styled.div`
  display: block;
  font-size: 5rem;
  text-align: left;
  margin-top: 0.4em;
  margin-bottom: 4rem;
  color: rgba(104, 117, 145, 1);
  font-family: ${font.family.primary};
  @media (min-width: ${breakpoint.phone}) {
    margin-top: 1.7em;
  }
  @media (min-width: ${breakpoint.desktop}) {
    font-size: 4rem;
  }
  @media (min-width: ${breakpoint.hd}) {
    font-size: 5rem;
  }
`;

export const UserInfoModalTitle = styled.div`
  font-size: 3rem;
  color: ${color.grey};
  margin-bottom: 0.5em;
`;

export const UserInfoModalInfo = styled.p`
  font-size: 2rem;
  color: ${color.grey};
  margin-bottom: 0.5em;
`;

export const QRCodeContainer = styled.div`
  width: 50%;
  margin: 4rem auto;

  svg {
    width: 100%;
    height: auto;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8rem;
`;

export const ModalFooterLink = styled.a`
  display: flex;
  text-align: left;
  margin-bottom: 3rem;
  text-decoration: none;
  color: inherit;
  outline: none;
  font-family: ${font.family.primary};
`;

export const ModalFooterLinkIcon = styled.div`
  background: #c0c4cc;
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 1.5rem;
  margin-right: 1.8rem;
  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 48%;
  }
`;

export const ModalFooterLinkTtl = styled.div`
  text-transform: uppercase;
  span {
    display: block;
    :first-child {
      font-size: 1.5rem;
      font-family: ${font.family.secondary};
      font-weight: 400;
    }
    :last-child {
      font-weight: 700;
      font-size: 2.25rem;
      color: ${color.link};
    }
  }
`;

export const ModalLogout = styled.div``;
