import styled from "styled-components";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import { border, breakpoint, color, font, transition } from "../../../theme";
import { Icon } from "../Icon";
import { ButtonLinkContainer, ButtonContainer } from "../Button/styled";
import { CheckboxRoot, Label } from "../Checkbox/styled";

import icoPageNext from "../../../assets/svg/pagination-next.svg";
import icoPagePrev from "../../../assets/svg/pagination-prev.svg";

export const ConfirmDialog = styled(DialogContent)<{
  receipt?: boolean | undefined;
  $center?: boolean;
  $size?: "large";
}>`
  display: inline-block;
  background: #fff;
  padding: ${(p) => (p.receipt ? "1px" : "2rem 4rem")};
  z-index: 20;
  vertical-align: middle;
  color: ${color.grey};
  border-radius: ${border.radius};
  margin: 0;
  width: 95%;
  text-align: left;
  overflow: hidden;
  @media (max-width: ${breakpoint.tablet}) {
    background: #fff !important;
  }
  @media (min-width: ${breakpoint.desktop}) {
    width: ${(p) => (p.$size === "large" ? "58%" : p.$center ? "45%" : "29%")};
    margin: 0 ${(p) => (p.$center ? "0" : "5.2rem")} 0 0;
    padding: ${(p) => (p.receipt ? "1px" : "4rem 6rem")};
  }
`;

export const ConfirmDialogInner = styled.div<{ $background?: string }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${(p) => {
    if (p.$background)
      return `background: ${p.$background} no-repeat right center;`;
  }}
  background-size: auto 80%;
`;

export const CloseDialogIcon = styled(Icon)<{ iconColor?: string }>`
  color: ${color.primary};
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
  margin-top: 0.7rem;
  svg {
    width: 100%;
    height: auto;
  }
`;

export const DialogHeaderTitle = styled.span`
  font-family: ${font.family.primary};
  font-size: 4rem;
  text-transform: uppercase;
`;

export const DialogMessage = styled.p`
  font-size: 2rem;
  margin: 3rem 0;
  font-weight: 400;
  font-family: ${font.family.secondary};
`;
export const DialogMessageLeft = styled.p<{
  index: number;
}>`
  font-size: 2.2rem;
  font-weight: 400;
  font-family: ${font.family.secondary};
  width: 100%;
  line-height: 1.4em;
  margin: 6rem 0;
  @media (min-width: ${breakpoint.tablet}) {
    width: 52%;
    margin: ${(p) =>
      p.index === 0
        ? "6rem 0 15rem"
        : p.index === 2
        ? "6rem 0 12rem"
        : p.index === 3
        ? "6rem 0 27rem"
        : "6rem 0"};
  }
`;

export const DialogButtonsRow = styled.div`
  display: flex;
  align-items: normal;
  margin-top: 2rem;
  flex-direction: column;
  @media (min-width: ${breakpoint.tablet}) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  ${ButtonLinkContainer},
  ${ButtonContainer} {
    :not(:first-child) {
      margin-top: 2rem;
      @media (min-width: ${breakpoint.tablet}) {
        margin-left: 2rem;
        margin-top: 0;
      }
    }
  }
`;

export const DialogButtonsRowCenter = styled.div`
  display: flex;
  align-items: normal;
  margin-top: 2rem;
  flex-direction: column;
  position: relative;
  @media (min-width: ${breakpoint.tablet}) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  ${ButtonLinkContainer},
  ${ButtonContainer} {
    :not(:first-child) {
      margin-top: 2rem;
      @media (min-width: ${breakpoint.tablet}) {
        margin-left: 2rem;
        margin-top: 0;
      }
    }
  }
`;

export const DialogPagination = styled(DialogButtonsRow)`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${ButtonLinkContainer},
  ${ButtonContainer} {
    margin-left: 2rem;
    margin-top: 0 !important;
  }
`;

export const DialogCheckboxGroup = styled.div`
  margin: 3rem 0 5rem;
  ${Label} {
    font-size: 2rem;
    font-weight: normal;
  }
`;

export const ConfirmDialogOverlay = styled(DialogOverlay)<{
  $center?: boolean;
}>`
  background: rgba(19, 46, 140, 0.2);
  z-index: 10;
  width: 100vw;
  height: 100%;
  text-align: center;
  padding: 2rem 0;
  font-family: ${font.family.secondary};
  @media (min-width: ${breakpoint.desktop}) {
    text-align: ${(p) => (p.$center ? "center" : "right")};
  }
  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    margin-right: 0;
    vertical-align: middle;
  }
  ${DialogButtonsRow} {
    ${(p) => {
      if (!p.$center) {
        return `
          @media (max-width: ${breakpoint.phone}) {
            flex-direction: column;
            align-items: normal;
          }
          @media (min-width: ${breakpoint.desktop}) {
            flex-direction: column;
            align-items: normal;
          }
          ${ButtonLinkContainer},
          ${ButtonContainer} {
            margin: 0;
          }
          ${ButtonLinkContainer}:not(:first-child),
          ${ButtonContainer}:not(:first-child) {
            margin-top: 2rem;
            @media screen and (max-width: ${breakpoint.desktop}) and (min-width: ${breakpoint.tablet}) {
              margin-top: 0;
              margin-left: 2rem;
            }
          }
        `;
      }
    }}
  }
  // prettier-ignore
  ${CheckboxRoot}:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const PaginationButton = styled.div`
  position: relative;
  box-shadow: none;
  display: flex;
  font-family: ${font.family.primary};
  font-size: 2.5rem;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  border-radius: ${border.radius};
  transition: all ${transition.duration};
  cursor: pointer;
  outline: none;
  text-decoration: none;

  height: 7.8rem;
  width: 7.8rem;
  border: 0.3rem solid ${color.grey};

  background-color: ${color.white};

  color: ${color.grey};

  &:hover {
    background-color: ${color.grey};
    border-color: ${color.grey};
    color: ${color.white};
  }

  :not(:first-child) {
    margin-left: 2rem;
  }
`;

export const PaginationPoints = styled.div`
  display: flex;
  align-items: center;
`;

export const PaginationPoint = styled.div<{ active: boolean }>`
  width: 1rem;
  height: 1rem;
  background: ${color.secondary};
  border-radius: 50%;
  cursor: pointer;
  margin-left: 2rem;
  position: relative;
  opacity: ${(p) => (p.active ? "1" : "0.4")};
  &:first-child {
    margin-left: 4rem;
  }
  &:last-child {
    margin-right: 2rem;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 250%;
    height: 250%;
    transform: translate(-50%, -50%);
  }
`;

export const PaginationIconLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mask-image: url(${icoPagePrev});
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 2.5rem 2rem;
  background-color: ${color.grey};
  &:hover {
    background-color: ${color.white};
  }
`;
export const PaginationIconRight = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mask-image: url(${icoPageNext});
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 2.5rem 2rem;
  background-color: ${color.grey};
  &:hover {
    background-color: ${color.white};
  }
`;
