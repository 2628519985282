import { AuthResponse } from "../types";

enum TokenStorageKeys {
  ACCESS_TOKEN = "auth.token",
  ACCESS_TOKEN_EXPIRES_IN = "auth.token.expires",
  REFRESH_TOKEN = "refresh.token",
  REFRESH_TOKEN_EXPIRES_IN = "refresh.token.expires",
  KEEP_TOKENS = "auth.token.store",
}

export class TokenStorage {
  public static get keepTokens(): boolean {
    const value = localStorage.getItem(TokenStorageKeys.KEEP_TOKENS);

    return value === "true";
  }

  private static get storage(): Storage {
    return TokenStorage.keepTokens ? localStorage : sessionStorage;
  }

  public static get accessToken(): string | null {
    return TokenStorage.storage.getItem(TokenStorageKeys.ACCESS_TOKEN);
  }

  public static set accessToken(token: string | null) {
    const { storage } = TokenStorage;
    if (token) {
      storage.setItem(TokenStorageKeys.ACCESS_TOKEN, token);
    } else {
      storage.removeItem(TokenStorageKeys.ACCESS_TOKEN);
    }
  }

  public static get refreshToken(): string | null {
    return TokenStorage.storage.getItem(TokenStorageKeys.REFRESH_TOKEN);
  }

  public static get accessTokenExpiresIn(): string | null {
    return TokenStorage.storage.getItem(
      TokenStorageKeys.ACCESS_TOKEN_EXPIRES_IN
    );
  }

  private static get refreshTokenExpiresIn(): string | null {
    return TokenStorage.storage.getItem(
      TokenStorageKeys.REFRESH_TOKEN_EXPIRES_IN
    );
  }

  public static removeTokens() {
    const { storage } = TokenStorage;
    storage.removeItem(TokenStorageKeys.ACCESS_TOKEN);
    storage.removeItem(TokenStorageKeys.REFRESH_TOKEN);
    storage.removeItem(TokenStorageKeys.ACCESS_TOKEN_EXPIRES_IN);
    storage.removeItem(TokenStorageKeys.REFRESH_TOKEN_EXPIRES_IN);
    window.location.reload();
  }

  public static storeTokens(
    auth: AuthResponse,
    saveCredentials = TokenStorage.keepTokens
  ) {
    const {
      accessToken,
      accessExpiresAt,
      refreshToken,
      refreshExpiresAt,
    } = auth;
    localStorage.setItem(TokenStorageKeys.KEEP_TOKENS, String(saveCredentials));
    const { storage } = TokenStorage;
    if (!saveCredentials) {
      TokenStorage.removeTokens();
    }

    if (accessToken) {
      storage.setItem(TokenStorageKeys.ACCESS_TOKEN, accessToken);
    }

    if (accessExpiresAt) {
      storage.setItem(
        TokenStorageKeys.ACCESS_TOKEN_EXPIRES_IN,
        accessExpiresAt
      );
    }

    if (refreshToken) {
      storage.setItem(TokenStorageKeys.REFRESH_TOKEN, refreshToken);
    }

    if (refreshExpiresAt) {
      storage.setItem(
        TokenStorageKeys.REFRESH_TOKEN_EXPIRES_IN,
        refreshExpiresAt
      );
    }
  }
}
