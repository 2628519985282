import { useGate, useStore } from "effector-react";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import moment from "moment";
import { logout, sessionUser$ } from "../../../models/auth";
import {
  AddEmailGate,
  attachedEmail$,
  attachEmail,
  attachEmailFx,
  detachEmailDialogOpen,
  fetchIdentifiersFx,
  ProfileGate,
  profileState$,
} from "../../../models/settings/profile";
import { Identifier } from "../../../types/profile";
import { SpinnerContainer } from "../Receipt/components/ReceiptTable/ReceiptDetails/styled";
import { Spinner } from "../../ui-kit/Spinner";
import {
  UserInfoModalPhone,
  UserInfoModalTitle,
  UserInfoModalInfo,
  QRCodeContainer,
} from "../../common/Header/UserInfoModal/styled";
import { formatPhone } from "../../../utils/string";
import { InputEditable } from "../../ui-kit/InputEditable";
import { AccountContainer } from "./styled";
import {
  FooterItem,
  FooterItemIcon,
  FooterItemName,
  FooterItems,
  FooterItemSubName,
} from "../../common/Footer/styled";
import { Icon } from "../../ui-kit/Icon";
import { ReactComponent as SupIcon } from "../../../assets/svg/ic-support.svg";
import { ReactComponent as TgIcon } from "../../../assets/svg/ic-telegram.svg";
import { DeleteEmailDialog } from "../../common/Header/DeleteEmailDialog";
import { BlockUser, deletePendingEmail } from "../../../api";
import { BlockAccountDialog } from "../../common/Header/BlockAccountDialog";
import { Button } from "../../ui-kit/Button";
import { ReceiptsHistoryDialog } from "../../common/App/ReceiptsHistoryDialog";
import { refreshTable } from "../../../models/receipt";
import { PhoneDialog } from "../../common/Header/PhoneDialog";
import { useTranslation } from "react-i18next";

export function Account() {
  const { t } = useTranslation();
  const user = useStore(sessionUser$);
  const attachedEmail = useStore(attachedEmail$);
  useGate(AddEmailGate);
  useGate(ProfileGate);
  const { emails, phones, loading, isDetachEmailDialogOpen } =
    useStore(profileState$);
  const [isBlockAccountDialogShow, setIsBlockAccountDialogShow] =
    useState(false);
  const [emailForHistory, setEmailForHistory] = useState("");
  const [phoneForHistory, setPhoneForHistory] = useState("");
  const [pending, setPending] = useState(false);
  const footerItems = [
    {
      logo: TgIcon,
      subName: "Чат-бот",
      name: "telegram",
      href: "https://t.me/Cheki_FNS_bot",
    },
    {
      logo: SupIcon,
      subName: t("Служба"),
      name: t("Технической поддержки"),
      href: "https://www.nalog.ru/rn77/service/service_feedback/?service=80",
    },
  ];

  async function saveUserParams(e: {
    showPeriod: "all" | "fromNow";
    identifierParams: { type: "MAIL"; login: string };
  }) {
    setPending(true);
    if (e.showPeriod === "fromNow") moment().format("YYYY-MM-DD");
    attachEmail({
      email: e.identifierParams.login,
      receiptExpirationDate:
        e.showPeriod === "fromNow" ? moment().format("YYYY-MM-DD") : null,
      extraMeta: null,
    });
    setPending(false);
  }

  function setPhoneWithValidate(e: string) {
    if (e.replace(/\D/g, "").length === 13) {
      setPhoneForHistory(e);
    } else {
      toast.error("Введите корректный номер телефона.");
    }
  }

  async function onApprove({ login }: Identifier) {
    try {
      await attachEmailFx({
        email: login,
        receiptExpirationDate: null,
        extraMeta: null,
      });
      toast.success(`Письмо с подтверждением выслано на почту ${login}`);
    } catch (e) {
      toast.error("Ошибка при отправке сообщения");
    }
  }

  async function onDelete(email: Identifier) {
    detachEmailDialogOpen({
      identifierId: email.id,
      identifierLogin: email.login,
      identifierType: email.type,
    });
  }

  async function onDeletePending(email: Identifier) {
    //TODO invalid method argument
    //@ts-ignore
    await deletePendingEmail(email.login);
    await fetchIdentifiersFx();
  }

  async function BlockAction() {
    try {
      await BlockUser();
      logout(false);
    } catch (e) {
      console.error("BlockAction fault");
    }
  }

  useEffect(() => {
    if (attachedEmail) {
      const re = /\S+@\S+\.\S+/;
      if (re.test(attachedEmail.email)) {
        attachEmailFx({
          email: attachedEmail.email.trim(),
          receiptExpirationDate: attachedEmail.receiptExpirationDate,
          extraMeta: null,
        });
        refreshTable();
        setEmailForHistory("");
      } else toast.error("Введите корректную электронную почту.");
    }
  }, [attachedEmail]);

  return (
    <AccountContainer>
      {loading ? (
        <SpinnerContainer>
          <Spinner radius="20px" />
        </SpinnerContainer>
      ) : (
        <>
          {isDetachEmailDialogOpen && <DeleteEmailDialog />}
          {isBlockAccountDialogShow && (
            <BlockAccountDialog
              onSubmit={BlockAction}
              onDismiss={() => setIsBlockAccountDialogShow(false)}
            />
          )}
          {emailForHistory && (
            <ReceiptsHistoryDialog
              onSubmit={saveUserParams}
              onDismiss={() => setEmailForHistory("")}
              identifier={{
                type: "MAIL",
                login: emailForHistory,
              }}
              pending={pending}
            />
          )}
          {phoneForHistory && (
            <PhoneDialog
              onDismiss={() => {
                setPhoneForHistory("");
              }}
              phone={phoneForHistory}
            />
          )}
          <UserInfoModalPhone>
            {user && formatPhone(user.taxpayerPerson.phone)}
          </UserInfoModalPhone>
          <UserInfoModalTitle>Ваш QR-код</UserInfoModalTitle>
          <UserInfoModalInfo>
            Предъявите его на кассе, чтобы осуществить сохранение чека в
            системе, либо подключите электронные чеки в программе лояльности
            продавца
          </UserInfoModalInfo>
          <QRCodeContainer>
            <QRCode
              renderAs="svg"
              value={user?.taxpayerPerson.phone || ""}
              bgColor="transparent"
            />
          </QRCodeContainer>

          <UserInfoModalTitle>Привязанные почтовые адреса</UserInfoModalTitle>
          {emails.map((email, i) => (
            <InputEditable
              withStatus
              editable={false}
              value={email.login}
              key={i}
              needApprove={!email.confirmed}
              onApprove={() => onApprove(email)}
              onDelete={() => onDelete(email)}
              onDeletePending={() => onDeletePending(email)}
            />
          ))}
          {phones.map((phone, i) => (
            <InputEditable
              withStatus
              editable={false}
              value={`+${phone.login}`}
              key={i}
              needApprove={!phone.confirmed}
              // onApprove={() => onApprove(phone)}
              onDelete={() => onDelete(phone)}
              // onDeletePending={() => onDeletePending(email)}
            />
          ))}
          <InputEditable
            onSave={(e: string) => setPhoneWithValidate(e)}
            value={""}
            placeholder={t("Добавьте номер телефона")}
            type="phone"
            mask="+{966} (000) 000 00 00"
          />
          <InputEditable
            onSave={setEmailForHistory}
            value={user?.taxpayerPerson.email || ""}
            placeholder={t("Добавьте email адрес")}
          />
          <FooterItems style={{ marginTop: "8rem" }}>
            {footerItems.map((item) => (
              <FooterItem
                key={item.name}
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FooterItemIcon name={item.name}>
                  <Icon source={item.logo} />
                </FooterItemIcon>
                <div>
                  <FooterItemSubName>{item.subName}</FooterItemSubName>
                  <FooterItemName>{item.name}</FooterItemName>
                </div>
              </FooterItem>
            ))}
          </FooterItems>
          <Button
            style={{
              marginTop: "4rem",
              marginBottom: "4rem",
              width: "100%",
              background: "#fff",
            }}
            onClick={() => setIsBlockAccountDialogShow(true)}
            theme={"text"}
          >
            {t("Заблокировать профиль")}
          </Button>
        </>
      )}
    </AccountContainer>
  );
}
