import React, {useState} from 'react';
import {Dialog} from '../../../ui-kit/Dialog';
import {
  DialogHeaderTitle,
  DialogMessage,
  DialogButtonsRow,
  DialogCheckboxGroup,
} from '../../../ui-kit/Dialog/styled';
import {HistorySource} from './styled';
import {UserInfoModalHeader} from '../../Header/UserInfoModal/styled';
import {Button} from '../../../ui-kit/Button';
import {InfoBlock} from '../../../ui-kit/InfoBlock';
import {Checkbox} from '../../../ui-kit/Checkbox';
import {ReceiptExpirationDateErrorAdditionalInfoItem} from '../../../../types/receipt';
import {formatPhone} from '../../../../utils/string';
import { useTranslation } from "react-i18next";

interface Props {
  onSubmit: Function;
  onDismiss: Function;
  identifier: ReceiptExpirationDateErrorAdditionalInfoItem;
  pending: boolean;
}

export function ReceiptsHistoryDialog({onSubmit, onDismiss, identifier, pending}: Props) {
  const { t } = useTranslation();
  const [historyShowPeriod, setHistoryShowPeriod] = useState<'all' | 'fromNow' | null>(null);

  return (
    <Dialog onDismiss={() => onDismiss()} ariaLabel="exit" center>
      <UserInfoModalHeader>
        <DialogHeaderTitle>{t("Настройки отображения данных")}</DialogHeaderTitle>
      </UserInfoModalHeader>
      <DialogMessage>
        {t("В этом сервисе отображаются чеки из всех магазинов, в которых Вы указывали номер мобильного телефона или адрес электронной почты при покупке товара. Выберите варианты отображения данных")}
      </DialogMessage>
      {identifier.type.replace(/\s/g, '') === 'SMS' ? (
        <HistorySource>{t("По номеру телефона")} {formatPhone(identifier.login)}</HistorySource>
      ) : (
        <HistorySource>{t("По email адресу")} {identifier.login}</HistorySource>
      )}
      <InfoBlock
        text={t("Если указанный номер телефона раньше принадлежал другому абоненту, вы можете увидеть некорректные данные")}
      />
      <DialogCheckboxGroup>
        <Checkbox
          round
          checked={historyShowPeriod === 'all'}
          label={t("Отображать все чеки")}
          onChange={() => setHistoryShowPeriod('all')}
        />
        <Checkbox
          round
          checked={historyShowPeriod === 'fromNow'}
          label={t("Отображать чеки начиная с текущего момента")}
          onChange={() => setHistoryShowPeriod('fromNow')}
        />
      </DialogCheckboxGroup>
      <DialogButtonsRow>
        <Button
          onClick={() => onSubmit({showPeriod: historyShowPeriod, identifierParams: identifier})}
          actionType="attention"
          disabled={!historyShowPeriod}
          isLoading={pending}
        >
          {t("Продолжить")}
        </Button>
      </DialogButtonsRow>
    </Dialog>
  );
}
