import * as React from 'react';
import {FunctionComponent, SVGProps} from 'react';
import {IconContainer} from './styled';
import {SpinnerContainer} from '../../pages/Receipt/components/ReceiptTable/ReceiptDetails/styled';
import {Spinner} from '../Spinner';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  source: FunctionComponent<SVGProps<SVGSVGElement>>;
  block?: boolean;
  onClick?: () => void;
  noFill?: boolean;
  noStroke?: boolean;
  title?: string;
  spinner?: boolean;
}

export function Icon({
  className,
  block = false,
  onClick,
  source: Source,
  noFill = false,
  noStroke = true,
  title,
  spinner,
  ...props
}: Props) {
  return (
    <IconContainer
      className={className}
      block={block}
      onClick={onClick}
      noFill={noFill}
      noStroke={noStroke}
      title={title}
      {...props}
    >
      {spinner ? (
        <SpinnerContainer>
          <Spinner radius="20px" />
        </SpinnerContainer>
      ) : (
        <Source />
      )}
    </IconContainer>
  );
}

Icon.defaultProps = {
  spinner: false,
  className: '',
  block: false,
  onClick: null,
  noFill: false,
  noStroke: true,
  title: '',
};
