import {
  combine,
  createEffect,
  createEvent,
  createStore,
  merge,
} from "effector";
import { createGate } from "effector-react";
import { Income, IncomeRequest, IncomeResponse } from "../../types/income";
import { RestError } from "../../core/errors";

export const TableGate = createGate();

export const fetchIncomeFx = createEffect<
  IncomeRequest,
  IncomeResponse,
  RestError
>();
export const fetchMoreIncomeFx = createEffect<
  IncomeRequest,
  IncomeResponse,
  RestError
>();
export const IncomeError$ = createStore<RestError | null>(null);

export const fetchMore = createEvent();
export const refreshTable = createEvent();

export const data$ = createStore<IncomeResponse>({
  items: [],
  total: 0,
  lastPage: true,
});

export const tableState$ = combine<{
  data: Income[];
  loading: boolean;
  lastPage: boolean;
  error: RestError | null;
}>({
  data: data$.map((d) => d.items),
  loading: fetchIncomeFx.pending,
  lastPage: data$.map((d) => d.lastPage),
  error: IncomeError$,
});
