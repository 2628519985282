import React, {ReactNode, CSSProperties} from 'react';
import {ConfirmDialogOverlay, ConfirmDialog, ConfirmDialogInner} from './styled';

interface Props {
  children: ReactNode;
  onDismiss: Function;
  ariaLabel: string;
  style?: CSSProperties | undefined;
  receipt?: boolean | undefined;
  center?: boolean;
  size?: 'large';
}

export function Dialog({children, onDismiss, ariaLabel, style, receipt, center, size}: Props) {
  return (
    <ConfirmDialogOverlay onDismiss={() => onDismiss()} $center={center}>
      <ConfirmDialog
        style={style}
        aria-label={ariaLabel}
        receipt={receipt}
        $center={center}
        $size={size}
      >
        <ConfirmDialogInner>{children}</ConfirmDialogInner>
      </ConfirmDialog>
    </ConfirmDialogOverlay>
  );
}

Dialog.defaultProps = {
  style: undefined,
  receipt: undefined,
  center: false,
  size: undefined,
};
