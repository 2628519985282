import {
  AuthResponse,
  AuthRequest,
  SessionUser,
  SmsChallengeTokenData,
  SessionUserResponse,
  AuthCodePayload,
} from "../types";
import { apiClient } from "../core/apiClient";
import { TokenStorage } from "../core/tokenStorage";
import { deviceInfo } from "../core/appMetaInfo";
import { fetchIdentifiersFx } from "../models/settings/profile";
import { loadSessionUserFx } from "../models/auth";

const AUTH_BASE_URL = "/api/v1/auth";

export const LOGIN_ROUTE = `${AUTH_BASE_URL}/sms`;
export const REFRESH_TOKEN_ROUTE = `${AUTH_BASE_URL}/refresh`;
export const SMS_CHALLENGE_TOKEN_ROUTE = `${AUTH_BASE_URL}/sms`;

export async function fetchSmsChallengeTokenData(
  params: AuthCodePayload
): Promise<SmsChallengeTokenData> {
  return (await apiClient.post(SMS_CHALLENGE_TOKEN_ROUTE, params))
    .data as SmsChallengeTokenData;
}

export async function fetchAuthInfo({
  challengeToken,
  phone,
  code,
  saveCredentials,
}: AuthRequest): Promise<AuthResponse> {
  const req = { challengeToken, phone, code, deviceInfo };
  const authResponse = (await apiClient.put(LOGIN_ROUTE, req))
    .data as AuthResponse;
  TokenStorage.storeTokens(authResponse, saveCredentials);
  await fetchIdentifiersFx();
  await loadSessionUserFx();
  return authResponse;
}

export async function fetchSessionUser(): Promise<SessionUser | null> {
  let sessionUser: SessionUser | null;
  try {
    const response = (await apiClient.get("/api/v1/profile"))
      .data as SessionUserResponse;
    // sessionUser = response.user;
    sessionUser = {
      authType: "SMS",
      taxpayerPerson: {
        address: "",
        authorityCode: "",
        email: "",
        firstName: "",
        fullName: "",
        inn: "",
        lastName: "",
        middleName: "",
        oktmo: "",
        phone: "",
        shortName: "",
        status: "",
      },
    };
    await fetchIdentifiersFx();
  } catch (e) {
    sessionUser = null;
  }
  return sessionUser;
}

export async function fetchAccessToken(
  refreshToken: string | null
): Promise<AuthResponse> {
  return (
    await apiClient.post(REFRESH_TOKEN_ROUTE, {
      refresh_token: refreshToken,
      deviceInfo,
    })
  ).data as AuthResponse;
}
