import styled from 'styled-components';
import {ButtonContainer} from '../../../../../ui-kit/Button/styled';

export const TableSelectionControlsStyled = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  background: #687591;
  z-index: 10;
`;

export const TableSelectionControlsInner = styled.div`
  width: 94.1%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  margin: 0 auto;
  // prettier-ignore
  ${ButtonContainer}:not(:nth-child(2)) {
    margin-left: 1em;
    @media (min-width: 1055px) {
      margin-left: 2em;
    }
  }
`;

export const TableSelectionControlsInnerStub = styled.div`
  width: 0;
  @media (min-width: 1055px) {
    width: 7.6%;
  }
`;
