import { apiClient } from "../core/apiClient";
import {
  ReceiptDetailsResponse,
  ReceiptExpirationDateSetData,
  ReceiptRequest,
  ReceiptResponse,
  ReceiptScanData,
} from "../types/receipt";

export async function fetchReceipts(
  req: ReceiptRequest
): Promise<ReceiptResponse> {
  let params: any = {
    page: req.offset / 20 + 1,
  };
  if (req.dateFrom) {
    params["since"] = new Date(req.dateFrom).getTime();
  }
  if (req.dateTo) {
    params["to"] =
      req.dateTo === req.dateFrom
        ? new Date(req.dateTo).getTime() + 60 * 60 * 24 * 1000
        : new Date(req.dateTo).getTime();
  }
  if (req.kktOwner) {
    params["seller"] = req.kktOwner;
  }
  try {
    const resp = (
      await apiClient.get("/api/v1/receipts", {
        params,
      })
    ).data as ReceiptResponse;
    // resp.receipts = resp.receipts.map((receipt) => {
    //   const preparedReceipt = { ...receipt };
    //   const brand = resp.brands.find(
    //     (item) => item.id === preparedReceipt.brandId
    //   );
    //   if (brand) {
    //     preparedReceipt.brand = brand.name;
    //     preparedReceipt.brandImage = brand.image;
    //   }
    //   return preparedReceipt;
    // });
    return resp;
  } catch (error) {
    if (error) {
      throw error;
    }
    return { items: [], brands: [], lastPage: true };
  }
}

export async function fetchReceiptDetails(
  key: string
): Promise<ReceiptDetailsResponse> {
  return (await apiClient.get(`/api/v1/receipts/${key}`))
    .data as ReceiptDetailsResponse;
}

export function scanReceipt(data: ReceiptScanData): Promise<void> {
  return apiClient.post("/api/v1/scan", data);
}

export function deleteReceipt(keys: Array<string>): Promise<void> {
  return apiClient.post("/api/v1/receipt/delete", { keys });
}

export function setReceiptExpirationDate(
  data: ReceiptExpirationDateSetData
): Promise<void> {
  return apiClient.post("/api/v1/identifiers/receipt_expiration_date", data);
}
