import {createEffect, createEvent, createStore} from 'effector';
import {
  AuthResponse,
  AuthRequest,
  SessionUser,
  SmsChallengeTokenData,
  AuthCodePayload,
} from '../../types';
import {RestError} from '../../core/errors';

export const loadSessionUserFx = createEffect<void, SessionUser | null>();
export const loginFx = createEffect<AuthRequest, AuthResponse>();
export const logoutFx = createEffect<void, void>();
export const loadSmsChallengeTokenDataFx = createEffect<
  AuthCodePayload,
  SmsChallengeTokenData,
  RestError
>();

export const authPhoneSelected = createEvent<string>();
export const authCapchaSelected = createEvent<string>();
export const challengeTokenExpired = createEvent<void>();
export const tickChallengeTokenTime = createEvent<void>();
export const codeSent = createEvent<{code: string; saveCredentials: boolean}>();
export const backToPhoneForm = createEvent<void>();
export const logout = createEvent<boolean>();
export const clientFromPartner = createEvent<void>();

export const sessionUser$ = createStore<SessionUser | null>(null);
export const isAuth$ = sessionUser$.map((user) => !!user);

export const authPhone$ = createStore<string | null>(null);
export const authCapcha$ = createStore<string | null>(null);

export const userIsSlowpoke$ = createStore<boolean>(false);

export const smsChallengeToken$ = createStore<string | null>(null);

export const showCodeForm$ = createStore<boolean>(false);

export const challengeTokenTime$ = createStore<number | null>(null);

export const smsChallengeTokenError$ = createStore<string | null>(null);
export const loginError$ = createStore<string | null>(null);

export const loginRedirectFromPrevPath$ = createStore<boolean>(true);

export const skipEmail$ = createStore<boolean>(false);
